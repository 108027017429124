<template>
  <transition name="fade">
    <div v-show="settingsData" id="core" @click="checkMenuStatus" :class="[currentScreen, mobile ? 'mobile' : '']" v-cloak>
      <div class="primaryFooter">
        <div class="copyright btn" @click="tcMenuBtn" data-btnid="info" data-url="https://truecast.io">Copyright ©2024 Truecast Inc. All rights reserved.</div>
      </div>
      <transition name="fade">
        <button v-if="currentScreen == 'Start'" class="primaryButton login" @click="logIn">
          LOG IN
        </button>
      </transition>
      <transition name="fade">
        <!--<keep-alive>-->
        <component :key="currentScreenKey" v-bind:is="currentScreen" :ref="currentScreen" :class="currentScreen"></component>
        <!--</keep-alive>-->
      </transition>

      <div v-if="tcMenuStatus && currentScreen != 'UserSpace' && currentScreen !='Expired'" @click="tcMenuStatus = false" class="tcPageBlocker"></div>

      <div v-if="currentScreen !='Expired'" id="tcMenuBase" :class="[tcMenuStatus ? 'active' : '', remoteStream ? 'min' : '']">
        <div class="tcMenu">
          <div class="links">
            <!--<div v-if="handshakeActive" class="btn" @click="rootMenuBtn" data-btnid="end" data-post="end">END SESSION</div>-->
            <div v-if="currentScreen == 'StartXXX' || currentScreen == 'LoginXXX'" class="btn" @click="groupMeeting">
              GROUP MEETING
            </div>
            <div v-if="currentScreen == 'CodeInputXXX' || currentScreen == 'LoginXXX'" class="btn" @click="currentScreen = 'Start'; tcMenuStatus = false;">
              DISPLAY CODE
            </div>
            <div v-if="mobile && currentScreen == 'AdminDashboard' && baseData && (baseData.record || baseData.stills) && !$refs.AdminDashboard.localStreamCamera && guestList && Object.keys(guestList).length == 0" class=" btn" @click="useCamera">CAMERA</div>
            <div v-if="currentScreen == 'UserSpace'" class="btn" @click="createShortCode">END SESSION</div>
            <div v-if="currentScreen == 'AdminDashboard'" class="btn" @click="endSession">END SESSION</div>
            <div v-if="!user && !currentScreen == 'Login' || currentScreen == 'Start' || currentScreen == 'CodeInput'" class="btn" @click="logIn">
              LOG IN
            </div>
            <div v-if="2+2==5 && currentScreen == 'Login'" class="btn" @click="resetPassword">RESET PASSWORD</div>
            <div v-if="currentScreen == 'AdminDashboard'" class="btn">HELP NOTES
              <label class="switch"><input v-model="helpNotes" type="checkbox"><span class="slider round"></span></label>
            </div>
            <div v-if="currentScreen == 'Dashboard' && (typeof userData.admin !== 'undefined') && userData.admin >= 0" class="btn" @click="currentScreen = 'AdminDashboard'; tcMenuStatus = false;">CLOSE DASHBOARD</div>
            <div v-if="currentScreen == 'AdminDashboard' && (typeof userData.admin !== 'undefined') && userData.admin >= 0" class="btn" @click="$refs.AdminDashboard.dashboard()">DASHBOARD</div>
            <div v-if="user && user.email" class="btn" @click="logOut">LOG OUT</div>
          </div>
          <div class="menuNotes">
            <span>NETWORK SPEED: </span><span v-if="currentScreen == 'AdminDashboard' && networkSpeed" :class="[networkSpeed < 3 ? 'red' : '']" style="">{{ networkSpeed == 'checking' ? 'checking...' : `${networkSpeed}MBs` }}</span>
          </div>
        </div>
      </div>
      <div v-if="currentScreen != 'Login' && currentScreen !='Expired' && currentScreen != 'Start'" id="tcMenuBtn" @click="tcMenuStatus = tcMenuStatus ? false : true" :class="[tcMenuStatus ? 'active' : '', remoteStream ? 'min' : '']">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
          <g class="closed">
            <rect y="5" class="st0" width="30" height="4" />
            <rect y="13" class="st0" width="30" height="4" />
            <rect y="21" class="st0" width="30" height="4" />
          </g>
          <g class="open">
            <rect x="0" y="13" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -6.2132 15)" class="st1" width="30" height="4" />
            <rect x="13" y="0" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -6.2132 15)" class="st1" width="4" height="30" />
          </g>
        </svg>
      </div>

      <transition name="fade">
        <div v-if="Object.keys(alertStatus).length > 0 || loading" class="tcPageBlocker"></div>
      </transition>


      <div v-show="!tcMenuStatus" class="notifications" ref="notifications"></div>
      <transition name="fade">
        <div v-if="Object.keys(alertStatus).length > 0" class="alertBox" :class="[alertStatus.message == 'Save image?' ? 'saveImage' : '']">
          <!--<div class="header" v-html="alertStatus.header"></div>-->
          <div class="message" v-html="alertStatus.message"></div>
          <div class="controls">
            <button v-if="alertStatus.cancel != 'na'" @click="alertBox('cancel')" class="primaryButton button cancel" v-html="alertStatus.secondary ? alertStatus.secondary : 'Cancel'"></button>
            <!--<button @click="alertStatus = {}; tcMenuStatus = false;" class="primaryButton button">{{ alertStatus.cancel ? alertStatus.cancel : 'Cancel' }}</button>-->
            <button v-if="alertStatus.primary" @click="alertBox('okay')" class="primaryButton button" v-html="alertStatus.primary"></button>
          </div>
        </div>
      </transition>

    </div>
  </transition>
</template>

<script>
/*
window.addEventListener('beforeunload', (event) => {
  //alert('Are you sure you want to leave this page?');
  const message = "Are you sure you want to leave this page?";
  event.returnValue = message;
  return message;
});
*/

import isMobile from 'ismobilejs';
//const userAgent = req.headers['user-agent'];
//console.log(isMobile(userAgent).any);

import { __db, __fs, __au, __as, __an } from '@/scripts/firebase';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { ref, set, onValue, get, update, onDisconnect } from 'firebase/database';
import { doc, getDoc } from 'firebase/firestore';
import { logEvent, setAnalyticsCollectionEnabled, setUserProperties } from 'firebase/analytics';

import router from './scripts/router';
import Start from './components/Start.vue';
import Expired from './components/Expired.vue';
import Login from './components/Login.vue';
import Reset from './components/Reset.vue';
import UserSpace from './components/UserSpace.vue';
import AdminDashboard from './components/AdminDashboard.vue';

//const AdminDashboard = () => import('./components/AdminDashboard.vue');
/*
function loadModule(mod) {
  import(`./components/${mod}.vue`).then((module) => {
    console.log('loading: ' + mod)
    return module;
  });
}
*/

export default {
  name: 'core',
  router,
  data() {
    return {
      alertStatus: {},
      currentScreenKey: 0,
      settingsData: null,
      mobile: isMobile().any,
      currentScreen: null,
      footerText: null,
      meetingID: null,
      tcMenuStatus: false,
      shortCode: null,
      userStatus: '',
      user: null,
      scPath: null,
      baseData: null,
      userData: null,
      template: null,
      hidden: null,
      visibilityChange: null,
      appVisible: null,
      appFocused: true,
      loggingOut: false,
      remoteStream: null,
      notificationTimers: [],
      onDisconnectRef: null,
      checkingUSID: true,
      helpNotes: false,
      guestList: null,
      loading: false,
      networkSpeed: 'checking',
    }
  },
  components: {
    Start, Expired, Login, Reset, UserSpace, AdminDashboard//: () => import('./components/AdminDashboard.vue')//, AdminDashboard
  },
  methods: {
    async alertBox(m) {
      if (m.timer) {
        console.log(m);
        let newDiv = document.createElement('div');
        newDiv.classList.add('notification');
        newDiv.classList.add('slideIn');
        let t = Math.floor(Math.random() * 9999999999999);
        newDiv.dataset[`timer${t}`] = '';
        let newContent = document.createTextNode(m.message);
        newDiv.appendChild(newContent);
        this.$refs.notifications.append(newDiv);
        this.notificationTimers[t] = setTimeout((t) => {
          clearTimeout(this.notificationTimers[t]);
          this.notificationTimers[t] = setTimeout((t) => {
            document.querySelector(`[data-timer${t}]`).remove();
            clearTimeout(this.notificationTimers[t]);
            delete this.notificationTimers[t];
          }, 500, t);
          document.querySelector(`[data-timer${t}]`).classList.add('fadeOut');
        }, 2500, t);
      } else {
        let evt = this.alertStatus.evt ? this.alertStatus.evt : null;

        switch(m) {
          case 'okay':
          case 'save':
            if (this.alertStatus.core) {
              this[this.alertStatus.fctn](evt);
            } else {
              this.$refs[this.currentScreen][this.alertStatus.fctn](evt);
            }
            this.alertStatus = {};
            this.tcMenuStatus = false;
            break;
          case 'cancel':
            if (this.alertStatus.cancel) {
              if (this.alertStatus.core) {
                this[this.alertStatus.cancel]();
              } else {
                this.$refs[this.currentScreen][this.alertStatus.cancel]();
              }
            }
            this.alertStatus = {};
            this.tcMenuStatus = false;
            console.log('CANCEL');
            break;
          default:
            this.alertStatus = m;
            break
        }
        /*
        if (m != 'okay') {
          this.alertStatus = m;
        } else {
          if (this.alertStatus.core) {
            this[this.alertStatus.fctn](this.alertStatus.evt);
          } else {
            this.$refs[this.currentScreen][this.alertStatus.fctn](this.alertStatus.evt);
          }
          this.alertStatus = {};
          this.tcMenuStatus = false;
        }
        */
      }
    },
    checkMenuStatus() {
      //if(this.tcMenuStatus === true) this.tcMenuStatus = false;
    },
    groupMeeting(e) {
      this.btnClick(e);
      this.tcMenuStatus = false;
      //signOut(__au);
      if (this.$refs.Start && this.$refs.Start.scPath) {
        set(this.$refs.Start.scPath, null);
      }
      this.currentScreen = 'CodeInput';
    },
    createShortCode(e) {
      if (e) this.btnClick(e);
      this.tcMenuStatus = false;

      update(this.myPath, {
        endedsession: true,
        ts: Math.floor(Date.now() / 1000)
      }).then(() => {
        window.location.href = document.location.origin + document.location.pathname;
      }).catch((error) => {
        console.log(error);
      });

      //set(this.myPath, null );
      //this.usid = null;
      //this.user = null;
    },
    tcMenuBtn(e) {
      this.btnClick(e);
      let ds = e.target.dataset;
      if (ds.url) window.open(ds.url, '_blank');//window.location.href = ds.url;
    },
    btnClick(me) {
      if (typeof me != 'object') return;
      //if(!me) return;
      me = me.target.dataset.btnparent ? document.querySelector(`#${me.target.dataset.btnparent}`) : me.target;
      me.classList.add('clicked');
      let ___buttonTimer = setTimeout(() => {
        clearTimeout(___buttonTimer);
        me.classList.remove('clicked');
        ___buttonTimer = setTimeout(() => {
          //this.tcMenuStatus = false;
          clearTimeout(___buttonTimer);
        }, 100);
      }, 100);
    },
    useCamera(e) {
      this.btnClick(e);
      if (this.$refs.AdminDashboard) {
        this.$refs.AdminDashboard.useCamera(e);
      }
    },
    shareCamera(e) {
      this.btnClick(e);
      if (this.$refs.UserSpace) {
        this.$refs.UserSpace.shareCamera(e);
      }
      if (this.$refs.AdminDashboard) {
        this.$refs.AdminDashboard.shareCamera(e);
      }
    },
    logIn(e) {
      this.btnClick(e);
      this.tcMenuStatus = false;
      if (this.$refs.Start && this.$refs.Start.scPath) {
        clearTimeout(this.$refs.Start.resetTimer);
        set(this.$refs.Start.scPath, null);
      }
      this.currentScreen = 'Login';
    },
    setDisconnect() {
      this.onDisconnectRef = onDisconnect(this.myPath);
      this.onDisconnectRef.update({connected: false, visible: false});
      console.log('this.onDisconnectRef', this.onDisconnectRef)
    },
    async endSession(e) {
      if (e) this.btnClick(e);
      //console.log(this.user.photoURL, this.user.displayName)
      if (this.onDisconnectRef) {
        this.onDisconnectRef.cancel();
      }
      let results = await this.removeSession();
      if (results && results.ended) {
        //console.log(this.user.photoURL, this.user.displayName)
        window.location.href = window.location = document.location.origin + document.location.pathname;
      } else {
        location.reload();
      }
      //window.location.href = window.location = document.location.origin + document.location.pathname;
      return;
    },
    async removeSession() {
      await fetch(`${this.__pt}end`, {
        method: 'POST',
        body: JSON.stringify({ uid: this.user.uid, ep: 'end' }),
        headers: { 'Content-Type': 'application/json' },
      }).catch((error) => {
        console.log(error)
      });
      /*
      const response = await fetch(`${this.__pt}end`, {
        method: 'POST',
        body: JSON.stringify({uid: this.user.uid, ep: 'end'}),
        headers: {'Content-Type': 'application/json'},
      });
      return await response.json();
      */
    },
    async logOut(e) {
      if (e) this.btnClick(e);
      this.alertBox({
        message: '<br>Are you sure you want to logout?',
        header: '',
        primary: 'Yes',
        fctn: 'logOut2',
        evt: e,
        core: true
      });
    },
    async logOut2() {
      this.loggingOut = true;
      let results = await this.removeSession();
      if (this.onDisconnectRef) {
        this.onDisconnectRef.cancel();
      }

      if (results && results.ended) {
        signOut(__au).then(() => {
          console.log('signed out')
          location.reload();
          //window.location.href = window.location = document.location.origin + document.location.pathname;
        }).catch((error) => {
          console.log(error);
        });
      } else {
        signOut(__au).then(() => {
          console.log('signed out')
          location.reload();
          //window.location.href = window.location = document.location.origin + document.location.pathname;
        }).catch((error) => {
          console.log(error);
        });
      }
    },
    async logOut3() {
      signOut(__au).then(() => {
        console.log('signed out')
        location.reload();
      }).catch((error) => {
        console.log(error);
      });
    },
    clearURL() {
      const url = new URL(window.location.href);
      url.search = '';
      window.history.replaceState(null, '', url.toString());
    },
    async checkUSID() {
      const queryString = window.location.search;
      console.log(queryString);
      const urlParams = new URLSearchParams(queryString);
      
      if (urlParams.get('code')) {  // && account == dropbox
        console.log('urlParams)', urlParams.get('code')); // send to server with uid for account info

      }  
      if (this.$route.params.sid && this.$route.params.sid.length == 48) {
        console.log('checkUSID checking', this.$route.params.sid)
        let tPath = ref(__db, `${__as}/${this.$route.params.sid}`);
        get(tPath).then(async (snapshot) => {
          if (snapshot.exists()) {
            if (snapshot.val().guests) {
              /*
              if (Object.keys(snapshot.val().guests).length >= 1) {
                update(tPath, {guests: null}).then((data) => {
                  //alert('k;jsadnfklsjgnlkafjn')
                  setTimeout(() => { console.log('PAUSE')}, 2000);
                });
              }
              */
            }
            this.usid = this.$route.params.sid;
            if (__au.currentUser) {
              if (__au.currentUser.email) {
                this.loggingOut = true;
                this.user = {};
                this.user.uid = __au.currentUser.uid;
                console.log(__au.currentUser, this.user.uid);
                if (this.onDisconnectRef) {
                  this.onDisconnectRef.cancel();
                }

                await this.removeSession();
                signOut(__au).then(() => {
                  console.log('signed out')

                  location.reload();
                }).catch((error) => {
                  console.log(error);
                });
              } else {
                console.log(this.usid)
              }
            }
            this.init();
          } else {
            this.usid = null;
            this.currentScreen = 'Expired';
            this.settingsData = true;
            //window.history.pushState('', 'Truecast Live', `${document.location.origin}${document.location.pathname}`);
          }
        }).catch(() => {
          this.usid = null;
        });
      } else {
        //if (this.$route.params.sid) alert(this.$route.params.sid)
        this.init();
        //return
      }
    },
    async init() {
      //console.log('this.checkingUSID start',this.checkingUSID)
      //this.checkingUSID = await this.checkUSID();
      //console.log('this.checkingUSID stop',this.checkingUSID)
      //console.log('init',this.usid)
      //alert(this.usid);
      //this is last version... keep until further tested...

      this.unsubscribeValue = onValue(ref(__db, '.info/connected'), async (snap) => {
        if (snap.val() === true) {
          console.log('this.unsubscribeValue > connected');
          //const presenceRef = __db.ref(this.myPath);
          //presenceRef.onDisconnect().set(false);
          this.userStatus = 'connected';
          if (this.currentScreen == 'AdminDashboard') {
            console.log('refreshing')
            //this.componentKey += 1;
            //this.$forceUpdate();
            
            location.reload();
          }
          onAuthStateChanged(__au, async (user) => {
            this.user = user;
            console.log('USER > ',this.user)
            if (user) {
              if (user.email) {
                this.userDataRef = doc(__fs, 'live_users', user.uid);
                const tempUserData = await getDoc(this.userDataRef);
                console.log('tempUserData > ', tempUserData);

                if (tempUserData.exists()) {
                  this.userData = tempUserData.data();
                  console.log('this.userData > ', this.userData);
                  //this.logMe('logged_in', { uid: user.uid, email: user.email, company: this.userData.company, ts: Math.floor(Date.now() / 1000) });
                  //setUserProperties(__an, { last_event_time: Date.now() });
                  //logEvent(__an, 'logged_in', { uid: user.uid, email: user.email, company: this.userData.company,ts: Math.floor(Date.now() / 1000) });
                  //setUserId(__an, user.email);

                  /*
                  this.baseDataRef = doc(__fs, 'live_data', this.userData.company.toLowerCase());
                  const tempBaseData = await getDoc(this.baseDataRef);
                  console.log('tempBaseData > ', tempBaseData);
                  this.baseData = tempBaseData.data();
                  */
                  
                  this.helpNotes = (this.userData.help) ? this.userData.help : false;
                } else {
                  console.log("No such document!");
                }
                if (user.photoURL && user.photoURL.replace('https://tcst.me/', '').length == 48) {
                  //alert(user.photoURL)
                  this.usid = user.photoURL.replace('https://tcst.me/', '');
                }
                //loadModule('AdminDashboard');
                this.currentScreen = 'AdminDashboard';
                this.settingsData = true;
              } else {
                if (this.$route.params.sid) {
                  this.usid = this.$route.params.sid;
                  this.currentScreen = 'UserSpace';
                  //logEvent(__an, 'anonymous', { screen: 'userspace', uid: user.uid, ts: Math.floor(Date.now() / 1000) });
                  this.logMe('anonymous', { uid: user.uid, screen: 'userspace', ts: Math.floor(Date.now() / 1000) });

                  //logEvent(__an, 'connected_user', { ts: Math.floor(Date.now() / 1000) });
                  //this.$refs.UserSpace.usid = this.$route.params.sid;
                } else {
                  if (this.currentScreen != 'Start') {
                    this.currentScreen = 'Start';
                    this.logMe('anonymous', { uid: user.uid, ts: Math.floor(Date.now() / 1000) });
                    //logEvent(__an, 'anonymous', { uid: user.uid, ts: Math.floor(Date.now() / 1000) });
                  }
                }
                this.settingsData = true;
              }
            } else {
              this.currentScreen = 'Start';
            }
          });
        } else {
          this.userStatus = 'notconnected';
        }
        //update(this.myPath, {connected: snap.val()});
      });
    },
    resetPassword() {
      if (this.$refs.Login) {
        this.$refs.Login.resetPassword();
      }
    },
    async handleVisibilityChange() {
      this.appVisible = !document[this.hidden];
      if (this.currentScreen == 'Start') return;
      console.log('this.appVisible', this.appVisible);
      if (!this.loggingOut && this.myPath) {
        update(this.myPath, {visible: this.appVisible});
      }
      clearTimeout(this.killMe);

      if (this.appVisible === false) {
        this.killMe = setTimeout(async () => {
          clearTimeout(this.killMe);
          //window.location = 'https://truecast.io';
          if (this.myPath) {
            if (__au.currentUser && __au.currentUser.email) {
              this.loggingOut = true;
              await this.removeSession();
              this.onDisconnectRef.cancel();
              window.location = document.location.origin + document.location.pathname;
              /*
              signOut(__au).then(() => {
                console.log('signed out')
                //location.reload();
                //window.location = 'https://truecast.io';
                window.location = document.location.origin + document.location.pathname;
              }).catch((error) => {
                console.log(error);
              });
              */
            } else {
              this.loggingOut = true;
              this.onDisconnectRef.cancel();
              this.createShortCode();

              //window.location.href = window.location = document.location.origin + document.location.pathname;

            }
          } else {
            if (this.onDisconnectRef) {
              this.onDisconnectRef.cancel();
            }

            window.location.href = window.location = document.location.origin + document.location.pathname + '#/expired';
          }

          //if(!this.loggingOut) update(this.myPath, {visible: this.appVisible});
        }, 60000);
      }

    },
    async logMe(e, d) {
      try {
        setAnalyticsCollectionEnabled(__an, true);
        await new Promise(resolve => setTimeout(resolve, 100));
        if (d.usid) {
          setUserProperties(__an, { unique_session_id: d.usid });
        }
        logEvent(__an, e, d);
        await new Promise(resolve => setTimeout(resolve, 100));
        setAnalyticsCollectionEnabled(__an, false);
      } catch (error) {
        console.error('Error logging event:', error);
      }
    },
    async updateConnectionInfo() {
      const connection = navigator.connection;
      this.connectionType = connection.type || 'Unknown';
      this.downlinkSpeed = connection.downlink || 'Unknown';
      this.effectiveType = connection.effectiveType || 'Unknown';
      this.rtt = connection.rtt || 'Unknown';
      console.log(connection)
    },
    async testConnectionSpeed() {
      this.networkSpeed = 'checking';
      const startTime = Date.now();
      const fileSizeInBytes = 1000000;//5000000;
      const url = 'https://tcst.me/speedtest.php';

      try {
        const response = await fetch(url);
        await response.blob();
        const endTime = Date.now();
        
        const durationInSeconds = (endTime - startTime) / 1000;
        const bitsLoaded = fileSizeInBytes * 8; // Convert bytes to bits
        //const speedMbps = (bitsLoaded / durationInSeconds / 1024 / 1024).toFixed(2); // Convert to Mbps
        const speedMbps = Math.floor(bitsLoaded / durationInSeconds / 1024 / 1024);
        
        console.log(`${speedMbps} Mbps`);
        this.networkSpeed = speedMbps;
        setTimeout(() => {
          this.testConnectionSpeed();
        }, 30000);
      } catch (error) {
        console.log('Error calculating speed');
        const ___speedCheckTimer = setTimeout(() => {
          clearTimeout(___speedCheckTimer);
          this.testConnectionSpeed();
        }, 10000);
      }
    },
    measureDnsTime() {
      const img = new Image();
      const startTime = Date.now();
      img.src = 'https://tcst.me/speedtest.php?' + startTime;

      img.onload = function () {
        const dnsTime = Date.now() - startTime;
        console.log(`${dnsTime} ms`);
      };

      img.onerror = function () {
        console.log('Error measuring time');
      };
    }
  },
  async mounted() {
    if (this.$route.params.sid && this.$route.params.sid == 'expired') {
      this.currentScreen = 'Expired';
      this.settingsData = true;
    } else {

      
      //this.init();
      this.checkUSID();
    }
    const currentYear = new Date().getFullYear();
    document.querySelector('.copyright').innerHTML = `Copyright ©${currentYear} Truecast Inc. All rights reserved.`;

    /*
    if (navigator.connection) {
      this.updateConnectionInfo();
      navigator.connection.addEventListener('change', this.updateConnectionInfo);
    }
    */

    this.testConnectionSpeed();
    //this.measureDnsTime();



  },
  beforeMount() {

  },
  beforeCreate() {

  },
  created() {
    this.__href = window.location.href;
    this.__dv = false;//window.location.hostname === 'localhost' ? true : false;
    this.__pt = this.__dv ? 'http://localhost:9999/tclive_v5/' : 'https://tcst.me/tclive_v5/';
    this.__dm = this.__dv ? 'localhost' : 'tcst.me';
    //this.__pt = 'https://truecast.me/tclive_v5/';
    if (typeof document.hidden !== 'undefined') {
      this.hidden = 'hidden';
      this.visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      this.hidden = 'msHidden';
      this.visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      this.hidden = 'webkitHidden';
      this.visibilityChange = 'webkitvisibilitychange';
    }

    if (typeof document.addEventListener != 'undefined' && this.hidden != undefined) {
      document.addEventListener(this.visibilityChange, this.handleVisibilityChange, false);
      this.appVisible = !document[this.hidden];
    }


    window.addEventListener('focus', () => {
      this.appFocused = true;
    }, false);
    window.addEventListener('blur', () => {
      this.appFocused = false;
    }, false);

  },
  beforeDestroy() {
    /*
    window.addEventListener('beforeunload', (event) => {
      update(this.myPath, {connected: false});
    });
    update(this.myPath, {connected: false});

     */
  },
  destroyed() {
    if (this.unsubscribeValue) this.unsubscribeValue();
    /*
    window.addEventListener('beforeunload', (event) => {
      update(this.myPath, {connected: false});
    });
    update(this.myPath, {connected: false});

     */
  },
  directives: {},
  computed: {},
  watch: {
    /*
    '$refs.AdminDashboard.guestList'(val) {
        alert('asdasdas')
        console.log(val);
    }
    */
  }
}
</script>

<style>
:root {
  --tc-oyellow: rgba(255, 242, 0, .5);
  --tc-blue: rgb(57, 127, 190);
  --tc-blue_lt: rgba(57, 127, 190, .5);
  --tc-magenta: rgb(207, 71, 142);  
  --tc-orange: rgb(239, 146, 16);
  --tc-magenta_lt: rgba(207, 71, 142, .5);
  --tc-dkgrey: rgb(51, 51, 51);
  --tc-grey: rgb(90, 90, 90);
  --tc-ltgrey: rgb(150, 150, 150);
  --tc-bordergrey: rgb(200, 200, 200);
  --tc-bkgdgrey: rgb(240, 240, 240);
  --tc-green: rgb(0, 180, 0);
  --tc-yellow: rgb(245, 232, 66);
  --tc-red: rgb(240, 0, 0);
  --tc-purple: rgb(121, 75, 158);
  --tc-gold: rgb(219, 181, 70);
  --tc-header-height: 60px;
  --tc-header-adminheight: 90px;
  --tc-share-height: 40px;
  --tc-footer-height: 50px;
  --tc-content-margin: 50px;
  --tc-content-mobilemargin: 20px;
  --tc-remotestream-bottom: 180px;
}

body {
  background-color: rgb(0,0,0);
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: env(safe-area-inset-top);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
}

#core {
  position: absolute;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-width: 750px; /*1085px*/;
  min-height: 500px; /*700px;*/
  font-family: 'bookType';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#core.mobile {
  position: absolute;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  font-family: 'bookType';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

button {
  outline: none;
}

.tcPageBlocker {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .3);
}

.alertBox {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  min-width: 300px;
  height: auto;
  min-height: 200px;
  background-color: rgb(55, 55, 55);
  box-shadow: 0 5px 15px rgba(0, 0, 0, .4);
  overflow: hidden;
  border-radius: 20px;
}
.alertBox.saveImage {
  background-color: rgba(55, 55, 55, .9);
}
.mobile .alertBox {
  width: 90%;
}

.alertBox .header {
  position: relative;
  padding: 10px;
  color: rgb(255, 255, 255);
  font-size: 22px;
  background-color: var(--tc-blue);
  box-shadow: 0 4px 10px rgba(0, 0, 0, .4);
}

.alertBox .message {
  position: relative;
  height: auto;
  font-size: 24px;
  padding: 10px 15px 0px 15px;
  line-height: 150%;
  color: rgb(255,255,255);
}

.alertBox .controls {
  position: relative;
  width: 100%;
  margin: 15px 0;
}

.alertBox ul {
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  padding-left: 15px;
}
.alertBox ul li {
  margin: 0 0 0 0;
  padding: 4px 0 2px 0;
}
.alertBox ul li li {
  margin: 0 0 0 15px;
  padding: 4px 0 4px 0;
}

.alertBox .imageTranslation {
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  overflow-y: scroll;
  max-height: 410px;
  margin: 20px 0;
}
.alertBox .imageTranslation div {
  padding-bottom: 2px;
  margin-bottom: 3px;
  border-bottom: 1px solid rgba(255,255,255,.2);
}
.alertBox .imageTranslation div:last-child {
  border: none;
}
.alertBox .videoTranscription {
  font-size: 14px;
  line-height: 17px;
  overflow-y: scroll;
  max-height: 450px;
}

.notifications {
  position: absolute;
  left: 0;
  top: var(--tc-header-adminheight);
  bottom: var(--tc-footer-height);
  pointer-events: none;
}

@keyframes slideInNotification {
  0% {
    opacity: 0;
    transform: translateX(-250px);
  }
  10% {
    opacity: 1;
    transform: translateX(10px);
  }
  90% {
    opacity: 1;
    transform: translateX(10px);
  }
  100% {
    opacity: 0;
    transform: translateX(10px);
  }
}

@keyframes fadeOutNotification {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.notifications .notification {
  pointer-events: none;
  position: relative;
  width: 200px;
  background-color: var(--tc-grey);
  color: rgb(255, 255, 255);
  font-size: 18px;
  border-radius: 5px;
  text-align: left;
  line-height: 140%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, .5);
  margin: 10px 0;
  padding: 10px 15px;
  left: 0;
  transform: translateX(-250px);
  animation: slideInNotification 3000ms;
  animation-fill-mode: forwards;
  opacity: 0;
}

.notifications .notification.slideIn {
  opacity: 1;
  transform: translateX(10px);
}

.notifications .notification.fadeOut {
  opacity: 0;
  /*animation: fadeOutNotification 3000ms;*/
}

.primaryHeader {
  position: absolute;
  top: 0;
  height: var(--tc-header-height);
  width: 100%;
}

.Login .primaryHeader,
.AdminDashboard .primaryHeader,
.UserSpace .primaryHeader {
  height: var(--tc-header-adminheight);
}

.primaryLogo {
  position: relative;
  left: 50px;
  width: 180px;
}

.primaryLogo.start {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 70%;
}

.primaryLogo svg {
  width: 100%;
  height: 100%;
}


.mobile .primaryLogo {
  left: var(--tc-content-mobilemargin);
}


.help {
  position: relative;
  left: 50px;
  font-family: georgia;
  text-align: left;
  color: rgb(255, 255, 255);
  font-size: 2.5em;
  font-weight: bold;
  margin-top: 30px;
}

.mobile .help {
  font-size: 28px;
  left: var(--tc-content-mobilemargin);
}

.shortCode {
  position: absolute;
  right: 133px;
  /* top: 29px; */
  top: 47px;
  font-size: 29px;
  text-align: right;
  color: rgb(255, 255, 255) !important;
  letter-spacing: 2px;
}

.mobile .shortCode {
  position: absolute;
  right: auto;
  left: 50%;
  transform: translateX(-50%);
  /* top: 29px; */
  top: 20px;
  font-size: 29px;
  text-align: center;
  color: rgb(255, 255, 255) !important;
}

.shortCode.min {
  top: 7px;
  font-size: 20px;
}

.primaryHeader {
  height: var(--tc-header-height);
}

.statusMessage {
  position: absolute;
  top: 220px;
  width: 100%;
  text-align: center;
  font-size: 22px;
  /*color: var(--tc-blue);*/
}

.mobile .statusMessage {
  top: 190px;
}


.currentScreen {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  /*top: var(--tc-header-height);
  bottom: var(--tc-remotestream-bottom);*/
  width: 100%;
}

.smallStreams {
  position: absolute;
  left: 50%;
  top: -80px;
  /*width: 610px;*/
  height: 300px;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-evenly;
  gap: 0 14px;
  pointer-events: none;
}

.localPreview {
  position: relative;
  width: 300px;
  height: 300px;
  background-color: rgba(0, 0, 0, 1);
  /*box-shadow: 0 5px 15px rgba(0, 0, 0, .4);*/
  display: flex;
  /*overflow: hidden;*/
  border: 1px solid rgba(255,255,255,.25);
}

.mobile .localPreview {
  border: none;
}

.localPreview .nowsharing {
  position: absolute;
  left: 0;
  top: -24px;
  height: 24px;
  width: 100%;
  text-align: center;
  background-color: var(--tc-blue);
  color: rgb(255, 255, 255);
  z-index: 10;
  box-shadow: 0 3px 10px rgba(0, 0, 0, .5);
  font-size: 14px;
  font-family: 'bookType';
  line-height: 27px;
}

.localPreview .sharePopupControls {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 300px;
  z-index: 10;
}

.localPreview .sharePopupControls button {
  box-shadow: 0 4px 10px rgba(0, 0, 0, .6);
}

.localStream {
  position: relative;
  display: flex;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  /*width: 100%;
  height: 100%; *//*auto;*/
  background-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}

.cameraSwap {
  position: absolute;
  right: 10px;
  bottom: 15px;
  width: 40px;
  height: auto;
}

.remotePreviews {
  position: absolute;
  left: 50%;
  top: -80px;
  width: 610px;
  height: 300px;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-evenly;
  gap: 0 14px;
}

.remotePreview {
  position: relative;
  width: 300px;
  height: 300px;
  background-color: rgba(0, 0, 0, 1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, .4);
  display: flex;
  overflow: hidden;
  border: 1px solid rgba(255,255,255,.25);
}
.remoteCameraFull .remotePreview {
  border: none;
}

.remotePreview .nowsharing {
  position: absolute;
  left: 0;
  top: 0;
  height: 24px;
  text-align: center;
  background-color: var(--tc-blue);
  color: rgb(255, 255, 255);
  width: 300px;
  z-index: 10;
  box-shadow: 0 3px 10px rgba(0, 0, 0, .5);
  font-size: 14px;
  font-family: 'bookType';
  line-height: 27px;
}

.remoteStreamScreen {
  position: absolute;
  width: 100%;
  height: calc(100% - var(--tc-remotestream-bottom));
  bottom: var(--tc-remotestream-bottom);
  background-color: rgba(0, 0, 0, 1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, .4);
  overflow: hidden;

}


.mobile .remoteStreamScreen {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: auto;
  top: 0;
  background-color: rgba(0, 0, 0, 1);
  overflow: hidden;
  transition: all 300ms ease-in-out;
}

.activeRemoteStreamScreen .smallStreams {
  top: auto;
  left: 15px;
  bottom: 15px;
  height: 150px;
  /*width: calc(50% - 245px);*/
  width: calc(100% - 30px);
  transform: translate(0, 0);
  display: flex;
  justify-content: left;
  gap: 0 5px;

}

.activeRemoteStreamScreen .smallStreams .localPreview {
  width: 150px;
  height: 150px;
}

.activeRemoteStreamScreen .smallStreams .remotePreview {
  width: 150px;
  height: 150px;
  position: absolute;
  right: 0;
}

.activeRemoteStreamScreen .smallStreams .localPreview .nowsharing {
  width: 100%;
  height: 14px;
  top: 0;
  z-index: 10;
  box-shadow: 0 3px 10px rgba(0, 0, 0, .5);
  font-size: 10px;
  font-family: 'bookType';
  line-height: 16px;
}


.activeRemoteStreamScreen #content .connectPane {
  top: auto;
  bottom: 100px;
}

.mobile .activeLocalStreams .localPreview {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transform: translate(0, 0);
}

.mobile .activeRemoteStreamScreen .localPreview,
.mobile .activeRemoteStreamCamera .localPreview {
  position: absolute;
  top: auto;
  left: auto;
  bottom: 0;
  right: 0;
  height: 120px;
  width: 120px;
  transform: translate(0, 0);
  border-left: 1px solid rgba(255, 255, 255, .8);
  border-top: 1px solid rgba(255, 255, 255, .8);
}


.mobile .remotePreview {
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  height: 100%;
  width: 100%;
  transform: translate(0, 0);
  border: none;
}


.primaryFooter {
  position: absolute;
  bottom: 0;
  height: var(--tc-footer-height);
  width: 100%;
}

.primaryFooter .footerText {
  position: relative;
  color: var(--tc-blue);
  left: 50px;
  line-height: var(--tc-footer-height);
  text-align: left;
}

.mobile .primaryFooter .footerText {
  left: var(--tc-content-mobilemargin);
}

.copyright {
  line-height: var(--tc-footer-height);
  display: block;
  font-size: 12px;
  color: rgba(255,255,255,.8);
  text-align: center;
  min-width: 300px;
  font-family: 'bookType', sans-serif;
}


.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.inputID {
  border: 1px solid rgba(0, 0, 0, .2);
  background-color: rgba(0, 0, 0, 0);
  color: rgb(0, 0, 0);
  box-sizing: border-box;
  border-radius: 4px;
  width: 170px;
  height: 50px;
  outline: none;
  font-family: 'bookType', sans-serif;
  font-size: 1.7em;
  text-transform: lowercase;
  text-align: center;
  padding: 5px;
}

.inputID::placeholder {
  color: var(--tc-bordergrey);
  opacity: 0;
  font-size: 1.2em;
}

.inputID:placeholder-shown {
  font-family: 'bookType', sans-serif;
  font-size: 1.2em;
}

*:focus {
  outline: none;
  outline-style: none;
}

.clicked {
  /*opacity: .5;*/
}

.primaryButton {
  position: relative;
  font-family: 'bookType', sans-serif;
  color: rgb(255, 255, 255);
  font-size: 1.1em;
  line-height: 38px;
  min-width: 140px;
  white-space: nowrap;
  height: 40px;
  border-radius: 30px !important;
  padding: 0 20px;
  margin: 5px;
  outline: none;
  background-color: var(--tc-blue);
  border: 2px solid var(--tc-blue);
  box-sizing: border-box;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}
.primaryButton.cancel {
  background-color: rgba(0,0,0,0);
  color: var(--tc-blue);
}

.primaryButton.clicked {
  opacity: .5;
}

.primaryButton.selected,
.primaryButton.disabled {
  background-color: var(--tc-blue_lt);
  pointer-events: none;
}

.primaryButton.fail {
  opacity: .5;
  pointer-events: none;
}

.primaryButton.login {
  position: absolute;
  left: 50%;
  bottom: 50px;
  transform: translateX(-50%) scale(.75);
}

.button {
  opacity: 1;
}

.button.clicked {
  opacity: .5;
}

.primaryButton.mobilealert::after {
  content: "!";
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  pointer-events: none;
  color: rgb(255, 255, 255);
  border-radius: 50%;
  background-color: rgb(255, 0, 0);
  right: .25em;
  top: -.75em;
  font-size: 1em;
  line-height: 1.5em;
  font-weight: bold;
  text-align: center;
}

.primaryButton.mobilealert {
  background-color: var(--tc-blue_lt);
  opacity: 1;
  pointer-events: auto;
}

.primaryButton#hangUpBtn:last-child {
  margin-left: 50px;
}

.primaryButton#hangUpBtn:first-child {
  margin-left: 0;
}


#content {
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);
  min-height: 320px;
  min-width: 320px;
  max-width: 440px;
  width: 440px;
}

.mobile #content {
  position: absolute;
  min-height: 320px;
  min-width: 300px;
  max-width: 100%;
  width: 320px;
  height: 320px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


#content.activeLocalStreams {
  height: 400px;
  transform: translate(-50%, calc(50% + 80px));
}

.activeRemoteStreamScreen #content {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  max-width: 100%;
  transform: translate(0, 0);
}

.mobile .activeRemoteStreamScreen #content {
  left: 0;
  bottom: auto;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  max-width: 100%;
  transform: translate(0, 0);
}

.mobile .activeRemoteStreamCamera #content,
.mobile .activeRemoteStreamScreen #content,
.mobile #content.activeLocalStreams {
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
  transform: translate(0, 0);

  min-height: 100%;
  min-width: 100%;
  max-width: 100%;

}


.remoteStreamScreen {
  position: absolute;
  width: 100%;
  height: calc(100% - var(--tc-remotestream-bottom));
  bottom: var(--tc-remotestream-bottom);
  background-color: rgba(0, 0, 0, 1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, .4);
  overflow: hidden;

}

.remoteCameraFull #content {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  max-width: 100%;
  transform: translate(0, 0);
}

.remoteScreenFull .remoteStreamScreen,
.remoteCameraFull #content .smallStreams {
  position: absolute;
  width: 100%;
  top: auto;
  height: calc(100% - 80px);
  bottom: 80px;
  background-color: rgba(0, 0, 0, 1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, .4);
  overflow: hidden;
}

.remoteCameraFull #content .remotePreview {
    position: absolute;
    width: 100%;
    height: calc(100% - var(--tc-remotestream-bottom));
    bottom: var(--tc-remotestream-bottom);
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 0 5px 15px rgba(0, 0, 0, .4);
    overflow: hidden;
    top: 50%;
    transform: translateY(-50%);
  /*
  position: absolute;
  width: 100%;
  top: auto;
  height: 100%;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, .4);
  overflow: hidden;
  */
}

.remoteScreenFull #content .connectPane,
.remoteCameraFull #content .connectPane {
  top: auto;
  bottom: 0px;
}

#content.hide {
  display: none;
}

#content .emailname {
  color: rgb(0, 0, 0);

}


#content .horiz {
  display: inline-block;
  width: auto;
  text-align: center;
  margin: 20px 10px;
  transition: all 300ms ease-in-out;
}

.mobile #content .horiz {
  display: inline-block;
  width: auto;
  text-align: center;
  margin: 10px 0;
  transition: all 300ms ease-in-out;
}

.logo {
  position: absolute;
  top: 0;
  height: 145px;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
}

.mobile .logo {
  position: relative;
  height: 145px;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 40px;
}

.logo img {
  width: 100%;
  height: 100%;
}

.guestStatus {
  color: rgb(255,255,255);
  position: absolute;
  top: 165px;
  left: 0;
  width: 100%;
  text-align: center;
}

.mobile .guestStatus {
  top: auto;
  position: relative;
  text-align: center;
}


#content .connectPane,
.desktop #content .connectPane,
.tablet #content .connectPane {
  position: absolute;
  top: 220px;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  min-width: 440px;
  max-width: 440px;
  height: 100px;
  /*transition: all 300ms ease-in-out;*/
}


.mobile #content .connectPane {
  position: absolute;
  top: 190px;
  width: 100%;
  display: flex;
  align-content: center;
  flex-direction: column;

}


#content .buttonBase {
  position: relative;
  display: flex;
}

.mobile #content .buttonBase {
  position: relative;
  display: block;
}


.mobile .activeRemoteStreamScreen #content .buttonBase,
.mobile .activeRemoteStreamCamera #content .buttonBase,
.mobile #content.activeLocalStreams .buttonBase {
  top: auto;
  position: absolute;
  bottom: 0;
  display: block;
  width: 145px; /*200px;*/
  height: 80px;
  left: 50%;
  transform: translateX(-50%);
}
/*
.mobile .activeRemoteStreamScreen #content .buttonBase,
.mobile .activeRemoteStreamCamera #content .buttonBase,
.mobile #content.activeLocalStreams .buttonBase {
  left: 5px;
  transform: translateX(0);
}
*/


.mobile .activeRemoteStreamScreen #content.activeLocalStreams .buttonBase,
.mobile .activeRemoteStreamCamera #content.activeLocalStreams .buttonBase {
  top: auto;
  position: absolute;
  bottom: 0;
  display: block;
  width: 200px;
  height: 80px;
  left: 0;
  transform: translate(0, 0);
}


#content .buttonPanel {
  display: inline-block;
  width: auto;
  text-align: center;
  margin: 20px 10px 0 10px;
}

.mobile #content .buttonPanel {
  display: block;
  width: auto;
  text-align: center;
  margin: 20px 10px 0 10px;
}


#content .buttonPanel button {
  position: relative;
  background-color: var(--tc-blue);
  font-family: 'bookType';
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  font-size: .8em;
  line-height: 38px;
  min-width: 140px;
  height: 40px;
  border-radius: 20px;
  /*margin: 5px;*/
  padding: 0 30px;
  outline: none;
  border: 2px solid var(--tc-blue);
  /*transition: opacity 200ms ease-in-out;*/
  cursor: pointer;
}

#content .buttonPanel div.selected,
#content .buttonPanel div.disabled,
#content .buttonPanel button.selected,
#content .buttonPanel button.disabled {
  /*
  background-color: var(--tc-blue_lt);
  border-color: var(--tc-blue_lt);
  */
  opacity: .5;
  pointer-events: none;
}

#content .sendSelect .fail {
  opacity: 0;
  pointer-events: none;
}

#content .buttonPanel button.mobilealert::after {
  content: "!";
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  pointer-events: none;
  color: rgb(255, 255, 255);
  border-radius: 50%;
  background-color: var(--tc-red);
  right: 1em;
  top: -.75em;
  font-size: 1em;
  line-height: 1.5em;
  font-weight: bold;
  text-align: center;
  font-family: 'bookType';
}

#content .buttonPanel button.mobilealert {
  background-color: var(--tc-blue);
  opacity: 1;
  pointer-events: auto;
}


#content .idInput {
  font-family: 'bookType';
  color: var(--tc-grey);
  margin: 15px 0;
  text-align: center;
}

.mobile #content .idInput {
  font-family: 'bookType';
  color: var(--tc-grey);
  margin: 10px 0;
  text-align: center;
}

#content .idInput .label {
  color: var(--tc-grey);
  text-transform: uppercase;
  font-size: 1.2em;
  padding: 35px 0 20px 0;
}

#content .idInput .id_input {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid var(--tc-blue);
  border-radius: 25px;
  width: 200px;
  height: 50px;
  box-sizing: border-box;
  background-color: rgb(255,255,255);
}
.mobile #content .idInput .id_input {
  width: 180px;
  height: 45px;
}

#content .idInput .id_input input {
  color: var(--tc-blue);
  text-transform: uppercase;
  line-height: 50px;
  font-size: 2em;
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
  margin: 2px 0 0 0;
  box-sizing: border-box;

}

#content .idInput .id_input input::placeholder {
  color: rgba(57, 127, 190, .6);
  opacity: 1;
}

#content .idInput .id_input input:placeholder-shown {
  font-size: 1.1em;
}
.mobile #content .idInput .id_input input:placeholder-shown {
  font-size: 1em;
}

#content .sendSelect {
  font-family: 'bookType';
  color: rgb(255, 255, 255);
  margin: 15px 0;
  text-align: center;
}

.mobile #content .sendSelect {
  transform: scale(.9);
}

#content .sendSelect .selector {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid var(--tc-blue);
  background: var(--tc-blue);
  border-radius: 25px;
  width: 140px;
  height: 50px;
  text-transform: uppercase;
  font-size: 1.1em;
  line-height: 50px;
  cursor: pointer;
}

.mobile #content .sendSelect .selector {
  position: absolute;
  /*box-shadow: 0 0 15px rgba(0, 0, 0, .7);*/
}

#content .sendSelect .selector.toggle:before {
  content: 'OFF';
  position: absolute;
  left: 0;
  top: 0;
  width: 38px;
  height: 38px;
  transform: translate(4px, 4px);
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, .6);
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0);
  color: rgb(0, 0, 0);
  line-height: 36px;
  font-size: 12px;
  font-family: 'mediumType';
  transition: all 150ms ease-in-out;
}

#content .sendSelect .selector.toggle.on:before {
  content: 'ON';
  transform: translate(154px, 4px);
  background-color: rgba(255, 255, 255, .6);
  border: 2px solid rgba(255, 255, 255, 0);
  transition: all 150ms ease-in-out;
}


#content .sendSelect .selector.camera,
#content .sendSelect .selector.screen {
  width: 140px;
}
#content .sendSelect .selector.camera .label,
#content .sendSelect .selector.screen .label {
  position: absolute;
  left: 0; top: 0;
  transform: translate(51px, 0);
}
#content .sendSelect .selector.camera.toggle:before,
#content .sendSelect .selector.screen.toggle:before {
  content: 'OFF';
  position: absolute;
  left: 0;
  top: 0;
  width: 38px;
  height: 38px;
  transform: translate(4px, 4px);
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, .6);
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0);
  color: rgb(0, 0, 0);
  line-height: 36px;
  font-size: 12px;
  font-family: 'mediumType';
  transition: all 150ms ease-in-out;
}
#content .sendSelect .selector.camera.toggle.on:before,
#content .sendSelect .selector.screen.toggle.on:before {
  content: 'ON';
  transform: translate(94px, 4px);
  background-color: rgba(255, 255, 255, .6);
  border: 2px solid rgba(255, 255, 255, 0);
  transition: all 150ms ease-in-out;
}
#content .sendSelect .selector.camera.toggle.on .label,
#content .sendSelect .selector.screen.toggle.on .label {
  transform: translate(12px, 0);
}



/*
#content .sendSelect .selector.toggle {
  position: relative;
}
#content .sendSelect .selector.toggle.on {

}
#content .sendSelect .selector.toggle.off {

}
 */


.console .buttonBase {
  position: absolute;

}

.contentCent {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.contentVert {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.contentHoriz {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.contentRow {
  display: block;
  margin: 10px 0;
}

.active {
  opacity: 1;
  pointer-events: all;
}

.inactive {
  opacity: .2;
  pointer-events: none;
}

#tcMenuBtn {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 30px;
  min-width: 24px;
  transition: opacity 300ms ease-in-out;
  cursor: pointer;
}

#tcMenuBtn.min {
  top: 7px;
  width: 24px;
}

#tcMenuBtn svg {
  pointer-events: none;
}

#tcMenuBtn .st0 {
  fill: rgba(255, 255, 255 ,.9);
}

#tcMenuBtn .st1 {
  fill: rgb(255, 255, 255);
}

#tcMenuBtn.min .st0 {
  fill: rgb(255, 255, 255);
}
#tcMenuBtn.reversed .st0 {
  fill: rgb(255, 255, 255) !important;
}

#tcMenuBtn .open {
  opacity: 0;
}

#tcMenuBtn.active .open {
  transition: opacity 200ms ease-in-out;
  opacity: 1;
}

#tcMenuBtn.active .closed {
  transition: opacity 200ms ease-in-out;
  opacity: 0;
}

#tcMenuBase {
  position: absolute;
  right: 0;
  top: -20px;
  width: 250px;
  height: 110%;
  background-color: rgba(50, 50, 50, .9);
  transition: all 200ms ease-in-out;
  transform: translateX(270px);
  box-shadow: 0 0 10px rgb(0, 0, 0, .8);
}

.mobile #tcMenuBase {
  position: absolute;
  right: 20px;
  top: 20px;
  left: 20px;
  bottom: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  background-color: rgba(50, 50, 50, .9);
  transition: all 200ms ease-in-out;
  box-shadow: 0 0 10px rgb(0, 0, 0, .8);
  opacity: 0;
  pointer-events: none;
  transform: translateX(0);
}


#tcMenuBase.active {
  transform: translateX(0);
}

.mobile #tcMenuBase.active {
  pointer-events: all;
  opacity: 1;
}

#tcMenuBase .tcMenu {
  position: absolute;
  top: 120px;
  margin: 25px;
}

.mobile #tcMenuBase .tcMenu {
  position: absolute;
  top: 60px;
  margin: 25px;
}


#tcMenuBase .tcMenu .links .btn {
  color: rgb(255, 255, 255);
  font-family: 'bookType', sans-serif;
  font-size: 18px;
  line-height: 240%;
  cursor: pointer;
  text-align: left;
}
#tcMenuBase .tcMenu .links .btn.clicked {
  color: rgb(152, 147, 147);
  border-bottom: 1px solid rgba(255, 255, 255, .6);
}
#tcMenuBase .tcMenu .links .btn:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, .6);
}


#tcMenuBase .tcMenu .switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 22px;
  top: 9px;
  left: 6px;
}

#tcMenuBase .tcMenu .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

#tcMenuBase .tcMenu .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

#tcMenuBase .tcMenu .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

#tcMenuBase .tcMenu input:checked+.slider {
  background-color: var(--tc-orange);
}

#tcMenuBase .tcMenu input:focus+.slider {
  box-shadow: 0 0 1px var(--tc-orange);
}

#tcMenuBase .tcMenu input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

#tcMenuBase .tcMenu .slider.round {
  border-radius: 22px;
}

#tcMenuBase .tcMenu .slider.round:before {
  border-radius: 50%;
}

#tcMenuBase .tcMenu .menuNotes {
  text-align: left;
  margin-top: 40px;
  color: rgb(255,255,255);
  font-size: 15px;
}
#tcMenuBase .tcMenu .red {
  color: var(--tc-red);
}

* {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input {
  -webkit-user-select: allow;
  -ms-user-select: allow;
  user-select: allow;
}

.noSelect {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.btn {
  cursor: pointer;
}

h1 {
  font-size: 48px;
  padding: 12px 0;
}

h2 {
  font-size: 36px;
  padding: 10px 0;
}

h3 {
  font-size: 24px;
  padding: 6px 0;
}

h4 {
  font-size: 15px;
  padding: 3px 0;
}

h5 {
  font-size: 14px;
  padding: 3px 0;
}

[v-cloak] {
  display: none;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 500ms;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.lightType {
  font-family: 'lightType';
}

.bookType {
  font-family: 'bookType';
}

.mediumType {
  font-family: 'mediumType';
}

@font-face {
  font-family: 'bookType';
  src: url('./assets/fonts/graphik/Graphik Regular/Graphik-400-Regular.eot');
  src: url('./assets/fonts/graphik/Graphik Regular/Graphik-400-Regular.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/graphik/Graphik Regular/Graphik-400-Regular.woff2') format('woff2'),
  url('./assets/fonts/graphik/Graphik Regular/Graphik-400-Regular.woff') format('woff'),
  url('./assets/fonts/graphik/Graphik Regular/Graphik-400-Regular.svg#SFProText-Regular') format('svg'),
  url('./assets/fonts/graphik/Graphik Regular/Graphik-400-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'lightType';
  src: url('./assets/fonts/graphik/Graphik Light/Graphik-300-Light.eot');
  src: url('./assets/fonts/graphik/Graphik Light/Graphik-300-Light.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/graphik/Graphik Light/Graphik-300-Light.woff2') format('woff2'),
  url('./assets/fonts/graphik/Graphik Light/Graphik-300-Light.woff') format('woff'),
  url('./assets/fonts/graphik/Graphik Light/Graphik-300-Light.svg#SFProText-Regular') format('svg'),
  url('./assets/fonts/graphik/Graphik Light/Graphik-300-Light.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mediumType';
  src: url('./assets/fonts/graphik/Graphik Semibold/Graphik-600-Semibold.eot');
  src: url('./assets/fonts/graphik/Graphik Semibold/Graphik-600-Semibold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/graphik/Graphik Semibold/Graphik-600-Semibold.woff2') format('woff2'),
  url('./assets/fonts/graphik/Graphik Semibold/Graphik-600-Semibold.woff') format('woff'),
  url('./assets/fonts/graphik/Graphik Semibold/Graphik-600-Semibold.svg#SFProText-Regular') format('svg'),
  url('./assets/fonts/graphik/Graphik Semibold/Graphik-600-Semibold.ttf');
  font-weight: normal;
  font-style: normal;
}
</style>
