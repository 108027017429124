<template>
  <div id="login">
    <div class="loginWindow contentHoriz">
      <div class="logo">
        <img ref="primary_logo" alt="Truecast" src="@/assets/truecast_logo_full_white.svg" width="100%" height="auto" style="opacity: .55;">
      </div>

      <div class="contentRow" style="margin-top: 40px;">
        <input v-model="myEmail" @keydown.enter="signIn" @keyup="isOkay($event)" @keydown="isOkay($event)"
          id="myEmail" class="inputFields" type="text" value="" autocorrect="off" autocapitalize="off" size="15"
          autocomplete="off" placeholder="USERNAME">
      </div>
      <div class="contentRow">
        <input v-model="myPassword" @keydown.enter="signIn" @keyup="isOkay($event)" @keydown="isOkay($event)"
          id="myPassword" class="inputFields" type="password" value="" autocorrect="off" autocapitalize="off"
          size="15" autocomplete="off" placeholder="PASSWORD">
      </div>

      <div class="contentRow">
        <button id="cancelLogInBtn" class="primaryButton button" @click="cancelSignIn">
          Cancel
        </button>

        <button id="logInBtn" class="primaryButton button" type="submit" @click="signIn"
          :class="[logInBtn ? 'pass' : 'fail']">
          Log In
        </button>
      </div>

      <div v-if="$parent.mobile">
        <h5 class="introText">
          Trouble logging in?<br>
          <span class="loginText" @click="resetPassword">Touch here to reset password</span>.
        </h5>
      </div>
      <div v-else>
        <h4 class="introText">
          Trouble logging in?<br>
          <span class="loginText" @click="resetPassword">Click here to reset password</span>.
        </h4>
      </div>
    </div>

  </div>
</template>

<script>
import { __au } from '@/scripts/firebase';
import { signInWithEmailAndPassword, browserLocalPersistence, setPersistence } from 'firebase/auth';
//import { auth, getDatabase, ref, set, onValue, child, get, on, push, update, onDisconnect, serverTimestamp } from 'firebase/database';
//import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';
export default {
  name: 'login',
  props: {},
  data() {
    return {
      myEmail: '',
      myPassword: '',
      logInBtn: false,
    }
  },
  components: {},
  methods: {
    resetPassword(e) {
      this.$parent.btnClick(e);
      this.$parent.tcMenuStatus = false;
      this.$parent.currentScreen = 'Reset';
    },
    cancelSignIn(evt) {
      evt.preventDefault();
      this.$parent.btnClick(evt);
      let ___buttonTimer = setTimeout(() => {
        clearTimeout(___buttonTimer);
        location.reload();
      }, 100);
    },
    signIn(evt) {
      evt.preventDefault();
      this.$parent.btnClick(evt);
      let ___buttonTimer = setTimeout(() => {
        clearTimeout(___buttonTimer);
        if (this.myEmail.length < 8 && this.myPassword.length < 8) return;
        __au.tenentId = 'truecast_session';
        setPersistence(__au, browserLocalPersistence).then(() => {
          signInWithEmailAndPassword(__au, this.myEmail, this.myPassword).then((userCredential) => {
            let user = userCredential.user;
            console.log('authorized', user)
            this.$parent.tcMenuStatus = false;
            //logEvent(__an, 'logging_in', { uid: user.uid, email: user.email, ts: Math.floor(Date.now() / 1000) });
            this.$parent.logMe('logging_in', { uid: user.uid, email: user.email, ts: Math.floor(Date.now() / 1000) })
            //setUserId(__an, user.email);

          }).catch((error) => {
            // myPassword myEmail 
            console.log('nope', error.toString());
            if (error.toString().includes('user-not-found')) {
              this.shakeMe('#myEmail');
              this.myEmail = '';
              this.myPassword = '';
            }
            if (error.toString().includes('wrong-password')) {
              this.shakeMe('#myPassword');
              this.myPassword = '';
            }
            if (error.toString().includes('invalid-email')) {
              this.shakeMe('#myEmail');
              this.myEmail = '';
              this.myPassword = '';
            }
          });
        }).catch((error) => {
          console.log(error);
        });
      }, 100);
    },
    shakeMe(me) {
      document.querySelector(me).classList.add('shake');
      let ___shakeTimer = setTimeout(() => {
        clearTimeout(___shakeTimer);
        document.querySelector(me).classList.remove('shake');
      }, 200);
    },
    isOkay(evt) {
      if (evt !== undefined) {
        if (this.myEmail.length > 8 && this.myPassword.length >= 8) {
          this.logInBtn = true;
        } else {
          this.logInBtn = false;
          //return true;
        }
      }
    },
    setFocus() {
      if (document.querySelector('#myEmail')) document.querySelector('#myEmail').focus();
      this.$nextTick(() => {
        if (document.querySelector('#myEmail')) document.querySelector('#myEmail').focus();
      });
    },
    async init() {

    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setFocus();
    });
    this.init();

    //this.resetPassword();
  },
  beforeMount() {

  },
  beforeCreate() {

  },
  created() {

  },
  beforeDestroy() {

  },
  destroyed() {

  },
  directives: {},
  computed: {},
  watch: {},
}
</script>

<style scoped>
.loginWindow {
  top: 25%;
}
.logo {
  position: relative;
  top: 0;
  height: 145px;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
}

.logo img {
  width: 100%;
  height: 100%;
}

.inputFields {
  border: 2px solid var(--tc-blue);
  color: var(--tc-blue);
  box-sizing: border-box;
  border-radius: 20px;
  width: 300px;
  height: 40px;
  outline: none;
  font-size: 1.2em;
  text-transform: lowercase;
  text-align: center;
}

.inputFields::placeholder {
  color: var(--tc-bordergrey);
  opacity: 1;
  font-size: 1em;
}

.inputFields:placeholder-shown {
  font-size: 1em;
}

.primaryButton {
  position: relative;
  background-color: var(--tc-blue);
  font-family: 'bookType';
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  font-size: .8em;
  line-height: 38px;
  min-width: 140px;
  height: 40px;
  border-radius: 20px !important;
  /*margin: 5px;*/
  padding: 0 30px;
  outline: none;
  border: 2px solid var(--tc-blue);
  /*transition: opacity 200ms ease-in-out;*/
  cursor: pointer;
}
.introText {
  display: block;
  padding: 0;
  margin: 0;
  line-height: 180%;
  white-space: nowrap;
  /*
  color: rgb(0,0,0);
  font-family: 'bookType';
  font-style: normal;
  */
}

.loginText {
  display: inline-block;
  cursor: pointer;
  /*color: rgb(0,0,0);*/
  text-decoration: underline;
}

@keyframes shake {
  0% {
    margin-left: 0;
  }

  25% {
    margin-left: 20px;
  }

  75% {
    margin-left: -20px;
  }

  100% {
    margin-left: 0;
  }
}

.shake {
  animation: shake 200ms ease-in-out;
  box-shadow: 0 0 5px #ff0000;
}

</style>
