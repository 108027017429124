<template>
  <div id="userspace" :class="[ !mobile && !localStreamCamera && !localStreamScreen && !remoteStreamScreen && remoteStreamCamera ? 'remoteCameraFull' : '', !mobile && !localStreamCamera && !localStreamScreen && !remoteStreamCamera && remoteStreamScreen ? 'remoteScreenFull' : '', remoteStreamScreen ? 'activeRemoteStreamScreen' : '', remoteStreamCamera ? 'activeRemoteStreamCamera' : '']">
    <div class="" id="content" :class="[(localStreamScreen || localStreamCamera) ? 'activeLocalStreams' : '']">
      <transition name="fade">
        <div v-if="!localStreamScreen && !localStreamCamera && !remoteStreamScreen && !remoteStreamCamera" class="logo">
          <img ref="primary_logo" alt="Truecast" src="@/assets/truecast_logo_full_white.svg" width="100%" height="auto" style="opacity: .55;">
        </div>
      </transition>

      <transition name="fade">
        <div v-if="!localStreamScreen && !localStreamCamera && !remoteStreamScreen && !remoteStreamCamera" class="guestStatus">
          <div v-if="!mobile" class="connectedWithRep">You are now connected with <span style="color: var(--tc-gold);">{{ admingFullName }}</span>.</div>
          <div v-else-if="mobile" class="connectedWithRep">You are now connected<br>with
            <span style="color: var(--tc-gold);">{{ admingFullName }}</span>.
          </div>
        </div>
      </transition>

      <div v-if="mobile" v-show="remoteStreamCamera" class="remotePreview camera">
        <video ref="remoteStreamCamera" :src="remoteStreamCamera" class="localStream" autoplay muted playsinline></video>
      </div>
      <div v-if="mobile" v-show="remoteStreamScreen" class="remoteStreamScreen">
        <video ref="remoteStreamScreen" :src="remoteStreamScreen" class="localStream" autoplay muted playsinline></video>
      </div>

      <div v-if="mobile" v-show="localStreamCamera" class="localPreview camera">
        <video ref="localStreamCamera" :src="localStreamCamera" class="localStream" autoplay muted playsinline></video>
        <img v-if="cameraSwapEnabled" @click="switchCamera" class="cameraSwap" alt="swap camera" src="@/assets/swapcamera.svg">

      </div>

      <transition name="fade">

        <div v-if="mobile" class="buttonBase">
          <div class="buttonPanel" :class="[spinnerStatus ? 'disabled' : '']">
            <div class="sendSelect">
              <button class="selector toggle camera" :class="[localStreamCamera ? 'on' : '', recording == 'camera' ? 'recordingOutline' : '']" @click="shareCamera">
                <div class="label">CAMERA</div>
              </button>
            </div>
          </div>
        </div>

        <div v-else-if="!mobile" class="connectPane" style="width: 320px; min-width: 320px; max-width: 320px;">
          <div class="buttonBase">
            <div class="buttonPanel" :class="[spinnerStatus ? 'disabled' : '']">
              <div class="sendSelect">
                <button class="selector toggle screen" :class="[localStreamScreen ? 'on' : '', recording == 'screen' ? 'recordingOutline' : '']" @click="shareScreen">
                  <div class="label">SCREEN</div>
                </button>
              </div>
            </div>
            <div class="buttonPanel" :class="[spinnerStatus ? 'disabled' : '']">
              <div class="sendSelect">
                <button class="selector toggle camera" :class="[localStreamCamera ? 'on' : '', recording == 'camera' ? 'recordingOutline' : '']" @click="shareCamera">
                  <div class="label">CAMERA</div>
                </button>
              </div>
            </div>
          </div>

          <transition name="fade">
            <svg v-if="someoneSharing && !remoteStream" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%); margin: auto; background-image: none; display: block; shape-rendering: auto; background-position: initial initial; background-repeat: initial initial;" width="50%" height="50%" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
              <circle cx="50" cy="50" r="36" stroke-width="8" stroke="rgb(121,75,158)" stroke-dasharray="56.548667764616276 56.548667764616276" fill="none" stroke-linecap="round">
                <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
              </circle>
              <circle cx="50" cy="50" r="27" stroke-width="8" stroke="rgb(121,75,158)" stroke-dasharray="42.411500823462205 42.411500823462205" stroke-dashoffset="42.411500823462205" fill="none" stroke-linecap="round">
                <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;-360 50 50"></animateTransform>
              </circle>
            </svg>
          </transition>


        </div>
      </transition>

      <div v-if="!mobile" class="smallStreams">
        <div v-show="localStreamScreen" class="localPreview screen" :class="[localStreamScreen ? 'activeLocalStreamPreview' : '']">
          <video ref="localStreamScreen" :src="localStreamScreen" class="localStream" autoplay muted playsinline></video>
          <div class="nowsharing">NOW SHARING</div>
        </div>
        <div v-show="localStreamCamera" class="localPreview camera" :class="[localStreamCamera ? 'activeLocalStreamPreview' : '']">
          <video ref="localStreamCamera" :src="localStreamCamera" class="localStream" autoplay muted playsinline></video>
        </div>
        <div v-show="remoteStreamCamera" class="remotePreview camera" :class="[remoteStreamCamera ? 'activeRemoteStreamPreview' : '']">
          <video ref="remoteStreamCamera" :src="remoteStreamCamera" class="localStream" autoplay muted playsinline></video>
        </div>
      </div>
      <div v-if="!mobile" v-show="remoteStreamScreen" class="remoteStreamScreen">
        <video ref="remoteStreamScreen" :src="remoteStreamScreen" class="localStream" autoplay muted playsinline></video>
      </div>


    </div>

  </div>
</template>

<script>
import {__db, __as} from '@/scripts/firebase';
import { ref, onValue, get, update } from 'firebase/database';
import Peer from 'peerjs';

export default {
  name: 'userspace',
  props: {},
  data() {
    return {
      shareScreenStatus: false,
      shareCameraStatus: false,
      useFrontCamera: true,
      usid: null,
      localStream: null,
      remoteStream: null,
      localStreamScreen: null,
      localStreamCamera: null,
      remoteStreamScreen: null,
      remoteStreamCamera: null,
      remoteShareScreenStatus: false,
      remoteShareCameraStatus: false,
      localEmptyStream: null,
      localEmptyStreamScreen: null,
      localEmptyStreamCamera: null,
      guestShare: {},
      pid: null,
      spid: null,
      someoneSharing: false,
      peer: null,
      peerIds: [],
      connections: {},
      pids: [],
      shareRequest: false,
      appVisible: this.$parent.appVisible,
      adminData: null,
      adminFName: null,
      adminLName: null,
      admingFullName: null,
      tcMenuStatus: this.$parent.tcMenuStatus,
      spinnerStatus: false,
      mobile: this.$parent.mobile,
      cameraSwapEnabled: true,
      recordingBlob: null,
      imageChunks: [],
      chunkSize: 32000,
      recording: null,
    }
  },
  components: {},
  methods: {
    async endSession(evt) {
      this.$parent.btnClick(evt);
      if (this.shareScreenBtn == 'stop sharing') {
        await this.shareScreen();
      }
      this.$parent.createShortCode();
    },
    async addConnection(conn) {
      console.log('addConnection', conn)
      this.connections[conn.peer] = conn;
      this.updatePeerIds();
      if (this.localStreamCamera) {
        const mycall = this.peer.call(this.spid, this.localStreamCamera, {metadata: {'type': 'camera'}});
        mycall.on('stream', (rS) => {
          this.remoteStreamScreen = this.$refs.remoteStreamScreen.srcObject = rS;
        });
      }
      if (this.localStreamScreen) {
        const mycall = this.peer.call(this.spid, this.localStreamScreen, {metadata: {'type': 'screen'}});
        mycall.on('stream', (rS) => {
          this.localStreamScreen = this.$refs.localStreamScreen.srcObject = rS;
        });
      }

      console.log(`Connected to ${conn.peer}!`);
    },
    async removeConnection(conn) {
      delete this.connections[conn.peer];
      this.updatePeerIds();
    },
    async updatePeerIds() {
      try {
        this.peerIds = Object.keys(this.connections);
      } catch (err) {
        //console.log('==> updatePeerIds: ',err);
      }
    },
    async disconnectPeer() {
      this.peer.disconnect();
      //this.peer.destroy();
    },
    async configureConnection(conn) {
      console.log('configureConnection =====>', conn)
      this.peerConnection = conn;
      
      conn.send('hello1');
      conn.on('data', data => {
        console.log('DATA:: ',data);
        if(data.cmd) data = data.cmd;
        switch (data) {
          case 'camera':
            console.log('DATA: ', 'camera still');
            this.stillPhoto('Camera');
            break;
          case 'screen':
            console.log('DATA: ', 'screen still');
            this.stillPhoto('Screen');
            break;
          case 'recordcamera':
            console.log('DATA: ', 'start recording camera');
            this.$parent.alertBox({
              message: '<p>Allow host to record video?</p>',
              primary: 'Approve',
              fctn: 'approveCameraRecord',
              cancel: 'cancelCameraRecord',
              evt: null,
            });
            break;
          case 'stoprecordcamera':
            console.log('DATA: ', 'stop recording camera');
            this.recording = null;
            this.$parent.alertBox({
              message: '<p>Host has stopped camera recording</p>',
              primary: 'Okay',
              fctn: 'cancelAlert',
              cancel: 'na',
              evt: null,
            });
            break;
          case 'cancelcamerarequest':
            console.log('DATA: ', 'cancel recording request');
            this.cancelAlert();
            break;
          case 'recordscreen':
            console.log('DATA: ', 'start recording screen');
            this.$parent.alertBox({
              message: '<p>Allow host to record screen?</p>',
              primary: 'Approve',
              fctn: 'approveScreenRecord',
              cancel: 'cancelScreenRecord',
              evt: null,
            });
            break;
          case 'stoprecordscreen':
            console.log('DATA: ', 'stop recording screen');
            this.recording = null;
            this.$parent.alertBox({
              message: '<p>Host has stopped screen recording</p>',
              primary: 'Okay',
              fctn: 'cancelAlert',
              cancel: 'na',
              evt: null,
            });
            break;
          case 'cancelscreenrequest':
            console.log('DATA: ', 'cancel screen request');
            this.cancelAlert();
            break;
          default:
            if (data.type === 'connections') {
              data.peerIds.forEach(peerId => {
                //console.log('==> peerId: ', peerId)

                if (!this.connections[peerId]) {
                  this.initiateConnection(peerId);
                }
              });
            }
        }
      });

      conn.on('close', () => this.removeConnection(conn));
      conn.on('error', () => this.removeConnection(conn));
      if (conn.metadata) {
        conn.metadata.peerIds.forEach(peerId => {
          if (!this.connections[peerId]) {
            //console.log('????????????????');
            this.initiateConnection(peerId);
          }
        });
      }
    },
    async initiateConnection(peerId) {
      console.log(this.peer)
      console.log(this.peerIds)
      console.log(this.peer.id)
      if (this.peer && !this.peerIds.includes(peerId) && peerId !== this.peer.id) {
        const options = {
          metadata: {
            peerIds: this.peerIds
          },
          serialization: 'json'
        };
        const conn = await this.peer.connect(peerId, options);
        this.configureConnection(conn);
        conn.on('open', () => {
          if (conn.peer == this.spid && this.someoneSharing) {
            this.someoneIsSharing();
          }
          this.addConnection(conn);
          conn.send('hello2');
        });
        return true;
      } else {
        return false;
      }
    },
    async getTS() {
      const response = await fetch(`${this.$parent.__pt}ts`);
      this.tsData = await response.json();
    },
    async createPeer() {
      if (this.peer) {
        this.peer.destroy();
        this.peer = null;
      }

      //update(this.myPath, {pid: null});
      await this.getTS();

      let config = {
        host: this.$parent.__dm,
        path: '/tclive_v5/pjs',
        secure: true,
        pingInterval: 2500,
        config: {
          iceServers: this.tsData
        }
      }
      if (this.$parent.__dv) {
        config.port = 9999;
        config.secure = false;
        //debug: 3,
      }
      //this.createPID();
      /*
      const storedPeerId = localStorage.getItem('peerId');
      if (storedPeerId) {
        this.peer = await new Peer(storedPeerId);
      } else {
        this.peer = await new Peer(this.pid, config);
      }
      */
      this.peer = await new Peer(this.pid, config);
      
      this.peer.on('open', () => {
        //localStorage.setItem('peerId', this.peer.id);
        console.log('connected to peer network => ', this.peer.id);
        update(this.myPath, {pid: this.pid});
        this.initiateConnection(this.spid);
      });

      const FATAL_ERRORS = ['invalid-id', 'invalid-key', 'network', 'ssl-unavailable', 'server-error', 'socket-error', 'socket-closed', 'unavailable-id', 'webrtc'];
      this.peer.on('error', (error) => {
        console.log(error)
        if (FATAL_ERRORS.includes(error.type)) {
          console.log('==> FATAL PEER ERROR: ', error.type)
          if (error.type == 'unavailable-id') {
            location.reload();
          } else {
            console.log('reconnect peer?');

            console.log('need to uncomment below?');
            //location.reload();



            //this.reconnectPeer(error);
          }
        } else {
          console.log('==> NON-FATAL PEER ERROR: ', error.type);
        }
      });

      this.peer.on('close', () => {
        console.log('==> CONNECTION CLOSED');
        update(this.myPath, {pid: null});
      });

      this.peer.on('connection', conn => {
        console.log('==> CONNECTION REQUEST')
        //return;
        console.log(this.peerIds, conn.peer);
        if (!this.peerIds.includes(conn.peer)) {
          this.configureConnection(conn);
          conn.on('open', () => {
            this.addConnection(conn);
            conn.send({
              type: 'connections',
              peerIds: this.peerIds
            });
          });
        }
      });
      this.peer.on('reconnected', () => {
        console.log('Reconnection successful.');
      });
      this.peer.on('disconnected', () => {
        this.reconnectPeer();
      });
      this.peer.on('call', (call) => {
        console.log('caller => ', call.peer, call, call.metadata.type);
        //call.answer(this.localStream);
        call.answer();
        call.on('stream', (rS) => {
          switch (call.metadata.type) {
            case 'screen':
              //call.answer(this.localStreamScreen);
              this.remoteStreamScreen = this.$refs.remoteStreamScreen.srcObject = rS;
              this.remoteScreenCall = call;
              break;
            case 'camera':
              //call.answer(this.localStreamCamera);
              this.remoteStreamCamera = this.$refs.remoteStreamCamera.srcObject = rS;
              this.remoteCameraCall = call;
              break;
          }
        });
        call.on('close', () => {
          console.log(call.metadata.type)
          switch (call.metadata.type) {
            case 'screen':
              this.remoteStreamScreen = null;
              break;
            case 'camera':
              this.remoteStreamCamera = null;
              break;

          }
          //this.$refs.localStreamCamera.play();
        });
      });
      return true;
    },
    reconnectPeer() {
      console.log('reconnecting peer')
      //return;
      setTimeout(() => {
        this.peer.reconnect();
      }, 2000);
    },
    async stopScreenSharing() {
      if (this.recording == 'screen') {
        this.cancelAlert();
        this.peerConnection.send({ cmd: 'stopscreenrecord' });
      }
      update(this.myPath, { screen: 'stop' });
      this.localStreamScreen.getTracks().forEach((track) => {
        if (track.readyState == 'live') {
          track.stop();
        }
      });
      this.peerScreenCall.close();
      this.localStreamScreen = null;
      this.recording = null;
    },
    async shareScreen(evt) {
      //https://developer.chrome.com/docs/web-platform/conditional-focus
      if (evt) this.$parent.btnClick(evt);
      if (this.localStreamScreen) {
        if (this.recording == 'screen') {
          this.$parent.alertBox({
            message: '<br>Screen recording in progress',
            primary: 'Continue',
            fctn: 'cancelAlert',
            cancel: 'stopScreenSharing',
            secondary: 'Stop'
          });
          return;
        }
        this.stopScreenSharing();
      } else {
        this.localStreamScreen = null;
        let config = {
          audio: false,
          frameRate: {
            ideal: 10, max: 20
          },
          video: {
            width: {ideal: 1920, max: 1920},
            height: {ideal: 1080, max: 1080},
            displaySurface: 'monitor', //browser monitor window
            cursor: 'motion', //never always motion
          },
          setFocusBehavior: true,
          surfaceSwitching: 'include',
          selfBrowserSurface: 'exclude',
          systemAudio: 'exclude',
        };
        try {
          this.localStreamScreen = this.$refs.localStreamScreen.srcObject = await navigator.mediaDevices.getDisplayMedia(config)

          this.localStreamScreen.getVideoTracks()[0].onended = () => {
            update(this.myPath, {screen: 'stop'});
            try {
              this.localStreamScreen.getTracks().forEach((track) => {
                if (track.readyState == 'live') {
                  track.stop();
                }
              });
            } catch (err) {
              console.log(err);
            }
            this.peerScreenCall.close();
            this.localStreamScreen = null;
            //this.shareScreen();
          };
          update(this.myPath, {screen: 'share'});
          const mycall = this.peer.call(this.spid, this.localStreamScreen, {metadata: {'type': 'screen'}});
          mycall.on('stream', (rS) => {
            this.remoteStreamScreen = this.$refs.remoteStreamScreen.srcObject = rS;
            //this.$refs.remoteStreamScreen.remoteStreamScreen.play();
          });
          this.peerScreenCall = mycall;
        } catch (err) {
          this.localStreamScreen = null;
          if (String(err).toLowerCase().includes('permission') && String(err).toLowerCase().includes('system')) {
            this.$parent.alertBox({
              message: '<br>Please update your settings to allow your browser to screen share.',
              header: 'Screen Share Error',
              primary: null,
              fctn: null,
              evt: null
            });
            update(this.myPath, {errors: {[Math.floor(Date.now() / 1000)]: String(err)}});
            console.log(err);
          }
          document.querySelector('.selector.toggle.screen').classList.remove('off');
          if (String(err).includes('Permission denied by system')) {
            this.alertMessage = 'Please update your settings to allow your browser to screen share.';
            this.alertType = 'default';
          }
        }
      }
    },
    async stopCameraSharing() {
      if (this.recording == 'camera') {
        this.cancelAlert();
        this.peerConnection.send({ cmd: 'stopcamerarecord' });
      }
      update(this.myPath, { camera: 'stop' });
      this.localStreamCamera.getTracks().forEach((track) => {
        if (track.readyState == 'live') {
          track.stop();
        }
      });
      this.localStreamCamera = null;
      this.recording = null;
    },
    async shareCamera(evt) {
      this.$parent.tcMenuStatus = false;
      if (this.localStreamCamera) {
        if(this.recording == 'camera') {
          this.$parent.alertBox({
            message: '<br>Camera recording in progress',
            primary: 'Continue',
            fctn: 'cancelAlert',
            cancel: 'stopCameraSharing',
            secondary: 'Stop'
          });
          return;
        }
        this.stopCameraSharing();
        /*
        update(this.myPath, {camera: 'stop'});
        this.localStreamCamera.getTracks().forEach((track) => {
          if (track.readyState == 'live') {
            track.stop();
          }
        });
        this.localStreamCamera = null;
        */
      } else {
        this.localStreamCamera = null;
        this.checkCameraMicrophone();
        try {
          const newStream = await this.getMediaStream();
          console.log(newStream.getTracks()[0])
          this.localStreamCamera = this.$refs.localStreamCamera.srcObject = newStream;
          //this.$refs.localStreamCamera.width = 1280;
          //this.$refs.localStreamCamera.height = 720;
          /*
          setTimeout(() => {
            console.log(`Width: ${this.$refs.localStreamCamera.videoWidth}, Height: ${this.$refs.localStreamCamera.videoHeight}`);
          }, 500);
          */

          //this.$refs.localStreamCamera.play();
          update(this.myPath, {camera: 'share'});
          const mycall = this.peer.call(this.spid, this.localStreamCamera, {metadata: {'type': 'camera'}});
          const sender = mycall.peerConnection.getSenders().find(s => s.track.kind === 'video');
          if (sender) {
            const params = sender.getParameters();
            console.log('params', params)

            params.encodings = [{
              maxBitrate: 2000000, // Adjust to desired bitrate
              scaleResolutionDownBy: 1.0, // Ensure full resolution
            }];
            sender.setParameters(params).catch(error => console.error('Failed to set parameters:', error));
          }
          
          mycall.on('stream', (rS) => {
            this.remoteStreamScreen = this.$refs.remoteStreamScreen.srcObject = rS;
            //this.$refs.remoteStreamScreen.remoteStreamScreen.play();
          });



        } catch (e) {
          console.log(e); //OverconstrainedError
          //this.sessionREF.update({'usererror':String(e)});
          if (String(e).includes('OverconstrainedError')) {
            this.shareCamera(evt);
            this.cameraSwapEnabled = false;
          }
          if (String(e).includes('Permission denied by system')) {
            this.alertMessage = 'camera share error......';
            this.alertType = 'default';
          }
        }
      }
    },
    async getMediaStream() {
      this.useFrontCamera = !this.useFrontCamera;
      const config = this.$parent.mobile ?
          {
            audio: false,//{ sampleRate: 48000 },
            video: {
              //facingMode: this.cameraDirection == 'user' ? { exact: 'environment' } : 'user',
              facingMode: (this.useFrontCamera ? 'user' : {exact: 'environment'}),
              frameRate: { min: 10, ideal: 24, max: 30 },
              //width: { min: 640, ideal: 1280, max: 1920 },
              //height: { min: 480, ideal: 720, max: 1080 },
              width: { ideal: 1280, max: 1920 },
              height: { ideal: 720, max: 1080 },
            }
          } : {
            audio: false, //{ sampleRate: 48000 },
            //video: true,
            video: {
              frameRate: { min: 10, ideal: 24, max: 30 },
              width: { ideal: 1280, max: 1920 },
              height: { ideal: 720, max: 1080 },
            }
          };
      return navigator.mediaDevices.getUserMedia(config);
    },
    replaceVideoTrack(stream) {
      let videoTrack = stream.getVideoTracks()[0];
      console.log('videoTrack:', videoTrack)

      let peers = this.peer.connections;
      let sender
      for (let pc in peers) {
        if (pc != 'null') {
          for (let peer in peers[pc]) {
            console.log(peers[pc][peer])
            console.log(peers[pc][peer].metadata.type)
            console.log(peers[pc][peer].peerConnection.getSenders()[0])
            //console.log(peers[pc][peer].peerConnection.getLocalStreams().length)
            //console.log(typeof peers[pc][peer].peerConnection.getSenders().find(s => s.track.kind === videoTrack.kind) !== undefined);
            if (peers[pc][peer].peerConnection.getLocalStreams().length == 1 && peers[pc][peer].peerConnection.getSenders().find(s => s.track.kind === videoTrack.kind)) {
              sender = peers[pc][peer].peerConnection.getSenders().find(s => s.track.kind === videoTrack.kind);
              sender.replaceTrack(videoTrack);
            }
          }

        }
      }
    },
    async switchCamera() {
      try {
        const newStream = await this.getMediaStream();
        this.replaceVideoTrack(newStream);
        this.localStreamCamera = this.$refs.localStreamCamera.srcObject = newStream;
      } catch (error) {
        console.error('Error switching camera:', error);
      }
    },
    stopSharing() {
      let tracks = this.$refs.localStream.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      this.localStream = this.$refs.localStream.srcObject = null;
      update(this.myPath, {sharing: null});
    },
    declineScreen() {
      this.shareRequest = null;
      update(this.myPath, {sharing: null});
    },
    async createEmptyStream() {
      const nullStream = Object.assign(document.createElement('canvas'), {width: 320, height: 240});
      nullStream.getContext('2d').fillRect(0, 0, 320, 240);
      const stream = nullStream.captureStream();
      const track = stream.getVideoTracks()[0];
      return Object.assign(track, {enabled: false});
    },
    saveRecording() {
      this.mediaRecorder.stop();
      this.mediaRecorder = null;
    },
    cancelRecording() {
      console.log('cancelRecording')
      this.mediaRecorder.onstop = () => { }
      this.mediaRecorder = null;
    },
    recordStream(event) {
      if (this.mediaRecorder) {
        this.recording = false;
        event.target.classList.remove('active');
        console.log('stop recording.........');
        this.$parent.alertBox({
          message: '<br>Save recording?',
          primary: 'Save',
          fctn: 'saveRecording',
          cancel: 'cancelRecording'
        });
      } else {
        this.recording = true;
        this.$nextTick(() => {
          if (this.mobile) {
            if (this.remoteStreamScreen) this.streamToRecord = 'remoteStreamScreen';
            if (this.remoteStreamCamera && !this.remoteStreamScreen) this.streamToRecord = 'remoteStreamCamera';
            if (!this.remoteStreamCamera && !this.remoteStreamScreen) this.streamToRecord = 'localStreamCamera';
          }
          if (!this.mobile) {
            if (this.remoteStreamScreen) this.streamToRecord = 'remoteStreamScreen';
            if (this.remoteStreamCamera && !this.remoteStreamScreen) this.streamToRecord = 'remoteStreamCamera';
            if (!this.remoteStreamCamera && !this.remoteStreamScreen) this.streamToRecord = 'localStreamCamera';
          }
          console.log(this.$refs[`${this.streamToRecord}Recording`]);
          //remoteStreamCamera remoteStreamScreen
          // if mobile then remoteStreamScreen over remoteStreamCamera

          const videoRect = this.$refs[this.streamToRecord].getBoundingClientRect();
          this.$refs[`${this.streamToRecord}Recording`].style.width = `${Math.ceil(videoRect.width)}px`;
          this.$refs[`${this.streamToRecord}Recording`].style.height = `${Math.ceil(videoRect.height)}px`;

          console.log(this.$refs[`${this.streamToRecord}Recording`], this.$refs[this.streamToRecord], videoRect)

          event.target.classList.add('active');
          console.log('start recording.........');
          this.recordingBlob = null;
          //const downloadLink = document.getElementById('downloadLink');
          this.mimeType = 'webm';//this.isMp4Supported ? 'mp4' : 'webm';
          this.recordedChunks = [];
          this.mediaRecorder = new MediaRecorder(this[this.streamToRecord], { type: `video/${this.mimeType}` });
          this.mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              this.recordedChunks.push(event.data);
            }
          };
          this.mediaRecorder.onstop = () => {
            //if (!this.localStream) return;
            this.dURL = null;
            this.blob = new Blob(this.recordedChunks, { type: `video/${this.mimeType}` }); //'video/webm'
            this.recordingBlob = this.blob;
            console.log(this.recordingBlob);
            this.uploadVideo();

          };
          this.mediaRecorder.start();
        });

      }
    },
    approveCameraRecord() {
      this.peerConnection.send({ cmd: 'allowcamerarecord' });
      this.recording = 'camera';
    },
    cancelCameraRecord() {
      this.peerConnection.send({ cmd: 'nocamerarecord' });
      this.recording = null;
    },
    approveScreenRecord() {
      this.peerConnection.send({ cmd: 'allowscreenrecord' });
      this.recording = 'screen';
    },
    cancelScreenRecord() {
      this.peerConnection.send({ cmd: 'noscreenrecord' });
      this.recording = null;
    },
    async uploadVideo() {
      if (!this.recordingBlob) {
        alert('Please select a video!');
        return;
      }
      this.loading = true;
      const formData = new FormData();
      formData.append('blobFile', this.recordingBlob, `video.${this.mimeType}`); // 'video.webm'
      for (let pair of formData.entries()) {
        console.log('====>', pair[0], pair[1]);
      }
      try {
        const response = await fetch(`${this.$parent.__pt}uploadvideo`, {
          //const response = await fetch('https://tcst.me/tclive_v5/uploadvideo', {
          method: 'POST',
          body: formData,
          headers: {
            'X-Usid': this.usid,
            'X-Uid': this.$parent.user.uid,
            'X-Email': this.$parent.user.email,
            'X-Ts': Date.now(),
          },
        });

        /*
        if (!response.ok) {
          throw new Error('Failed to upload video');
        }
        */
        const result = await response.json();
        console.log('result.filePath', result.filePath);
        this.dURL = `https://tcst.me/tclive_uploads/${result.usid}/${result.uid}/${result.ts}-video.${this.mimeType}`; //webm
        console.log('url: ', this.dURL);

        this.loading = false;
        if (this.streamToRecord != 'remoteStreamScreen') {
          if (result.message == 'Video uploaded successfully') {
            this.$parent.alertBox({
              message: '<br>Transcribe recording?',
              primary: 'Okay',
              fctn: 'getTranscription',
              cancel: 'cancelTranscription',
              evt: this.dURL
            });
          }
        }
      } catch (error) {
        console.error('Error uploading video:', error);
        this.loading = false;
      }
    },
    getTranscription(url) {
      this.mediaRecorder = null;
      console.log('getTranscription')
      this.loading = true;
      fetch('https://tcst.me/transcribe/t', {
        method: 'POST',
        body: JSON.stringify({
          url: url,
          //prompt: 'Summarize key decisions and important points from the transcript'
          //prompt: 'list transcript as bullet points in html format'
          prompt: `Your summaries do not describe the context of the transcript - they only summarize the events in the text. 
          Respond with just the summary and do not include a preamble or introduction. 
          list any next steps. format summary in html.`
        }),
        headers: { 'Content-type': 'application/json' }
      }).then(response => response.json()).then(data => {
        this.loading = false;
        console.log('RESPONSE: ', data);
        if (data.response == 'error') {
          this.$parent.alertBox({
            message: '<br>No dialog detected in recording',
            primary: 'Okay',
            fctn: 'errorTranscription',
            cancel: 'na',
          });
        } else {
          let msg = `<div class="videoTranscription">${this.convertText(data.response)}</div>`;
          //this.transcription = this.convertText(data.response);
          this.$parent.alertBox({
            message: msg,
            primary: 'Save',
            fctn: 'saveTranscription',
            evt: { text: data.text, response: data.response }
          });
        }
      });
    },
    saveTranscription(e) {
      console.log('SAVED TRANSCRIPTION', e)
    },
    cancelTranscription() {
      console.log('cancelTranscription')
      this.mediaRecorder = null;
    },
    errorTranscription() {
      console.log('errorTranscription')
    },
    convertText(text) {
      let items = text.split('\n').map(item => item.replace('•', '').trim());
      let html = '<ul>';
      items.forEach(item => {
        if (item) {
          if (item.charAt(0) == '-') {
            item = item.replace('- ', '');
            html += `<ul><li>${item}</li></ul>`;
          } else {
            html += `<li>${item}</li>`;
          }
        }
      });
      html += '</ul>';
      return html;
    },
    useCamera(e) {
      this.shareCamera(e);
      console.log(e)
    },
    async sendChunk(i) {
      await new Promise(resolve => setTimeout(resolve, 10));
      this.peerConnection.send({
        type: 'chunk',
        //data: this.imageChunks[i],
        //index: i,
        //totalChunks: this.imageChunks.length
      });
      console.log('sent: ', i )
    },
    async stillPhoto(e) {
      const video = this.$refs[`localStream${e}`];

      const canv = document.createElement('canvas');//this.$refs.canvasSnapshot;
      canv.id = 'canvasSnapshotTemp';
      canv.style.display = 'none';
      canv.getContext('2d').clearRect(0, 0, canv.width, canv.height);
      document.body.appendChild(canv);
      canv.width = video.videoWidth;
      canv.height = video.videoHeight;
      console.log('canvasSnapshot', document.querySelector('#canvasSnapshotTemp'), video.videoWidth, video.videoHeight)
      canv.getContext('2d').drawImage(video, 0, 0, canv.width, canv.height);
      const base64Image = canv.toDataURL('image/png');
      //console.log('this.peerConnection', this.peerConnection, base64Image)
      this.imageChunks = [];
      this.imageChunks = this.chunkData(base64Image, 9000);
      for (let i = 0; i < this.imageChunks.length; i++) {
        //await this.sendChunk(i);
        this.peerConnection.send({
          type: 'chunk',
          data: this.imageChunks[i],
          index: i,
          totalChunks: this.imageChunks.length
        });
        //console.log('sending chunk', i, this.imageChunks[i])
      }
      this.peerConnection.send({ type: 'end' });
      const ___tempTimer = setTimeout(() => {
        clearTimeout(___tempTimer);
        this.imageChunks = null;
        document.querySelector('#canvasSnapshotTemp').remove();
      }, 1000);
      /*
      this.$nextTick(() => {
        if (this.mobile) {
          if (this.remoteStreamScreen) this.streamToRecord = 'remoteStreamScreen';
          if (this.remoteStreamCamera && !this.remoteStreamScreen) this.streamToRecord = 'remoteStreamCamera';
          if (!this.remoteStreamCamera && !this.remoteStreamScreen) this.streamToRecord = 'localStreamCamera';
        }
        if (!this.mobile) {
          if (this.remoteStreamScreen) this.streamToRecord = 'remoteStreamScreen';
          if (this.remoteStreamCamera && !this.remoteStreamScreen) this.streamToRecord = 'remoteStreamCamera';
          if (!this.remoteStreamCamera && !this.remoteStreamScreen) this.streamToRecord = 'localStreamCamera';
        }
        const video = this.$refs[this.streamToRecord];
        const canv = this.$refs.canvasSnapshot;
        canv.width = video.videoWidth;
        canv.height = video.videoHeight;
        console.log('this.$refs.canvasSnapshot', this.$refs.canvasSnapshot, video.videoWidth, video.videoHeight)
        canv.getContext('2d').drawImage(video, 0, 0, canv.width, canv.height);
      });
      */
    },
    chunkData(data, size) {
      let chunks = [];
      for (let i = 0; i < data.length; i += size) {
        chunks.push(data.slice(i, i + size));
      }
      return chunks;
    },
    stillPhotoSave(e) {
      console.log('stillPhotoProcess', e.target.files[0])
      this.selectedFile = e.target.files[0];

      let reader = new FileReader();
      reader.onloadend = () => {
        //img.src = reader.result;
        this.imagePreview = reader.result;
        console.log(this.imagePreview)
      }
      reader.readAsDataURL(this.selectedFile);
      this.$parent.alertBox({
        message: '<br>Save image?',
        primary: 'Save',
        fctn: 'stillPhotoProcess',
        cancel: 'cancelPhoto'
      });
    },
    cancelPhoto() {
      this.selectedFile = null;
      this.imagePreview = null;
    },
    stillPhotoProcess() {
      this.imagePreview = null;
      this.uploadImage();
    },
    async uploadImage() {
      this.loading = true;
      const formData = new FormData();
      formData.append('image', this.selectedFile);
      for (let pair of formData.entries()) {
        console.log('====>', pair[0], pair[1]);
      }
      try {
        const response = await fetch(`${this.$parent.__pt}uploadimage`, {
          //const response = await fetch('https://tcst.me/tclive_v5/uploadimage', {
          method: 'POST',
          body: formData,
          headers: {
            'X-Usid': this.usid,
            'X-Uid': this.$parent.user.uid,
            'X-Email': this.$parent.user.email,
            'X-Ts': Date.now(),
          },
        });
        /*
        if (!response.ok) {
          throw new Error('Failed to upload image');
        }
        */
        const result = await response.json();
        console.log(result, `https://tcst.me/tclive_uploads/${result.usid}/${result.uid}/${result.file.filename}`);//`https://tcst.me/tclive_uploads/${result.usid}/${result.uid}/${result.file.filename}`
        this.dURL = `https://tcst.me/tclive_uploads/${result.usid}/${result.uid}/${result.file.filename}`;
        this.loading = false;
        if (result.message == 'Image uploaded successfully') {
          this.$parent.alertBox({
            message: '<br>Interpret image?',
            primary: 'Okay',
            fctn: 'getImageTranslation',
            cancel: 'cancelImageTranslation',
            evt: this.dURL
          });
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    },
    getImageTranslation(url) {
      console.log('getImageTranslation', url);
      this.loading = true;
      /*
      let prompt = `This tool will extract key details from the provided images, including the brand, model number, specifications, and any other relevant information.
      It will interpret text within images to accurately identify model details, ensuring that the extracted information is concise and relevant.
      Responses will be technical and precise, focusing on delivering exact information. 
      If applicable, it will provide identifying numbers, such as serial numbers and model numbers (in full text), gas or electric type, capacity, and device type. 
      If the device is a furnace, it will specify the required filter size.
      The output will be in a JSON format with keys in camelCase. 
      It will aim to provide a direct link to any user manuals available. 
      If a manual is not available, it will mention this clearly.`;
      */
      let prompt = `This tool will extract all details from the provided images, including the brand, model number, specifications, and any other relevant information.
      It will interpret text within images to accurately identify model details, ensuring that the extracted information is concise and relevant.
      Responses will be technical and precise, focusing on delivering exact information. 
      If applicable, it will provide identifying numbers, such as serial numbers and model numbers (in full text), gas or electric type, capacity, and device type.
      Always list device type first.
      If the device is a furnace, it must specify the required filter size.
      The output will be in a JSON format and not nested.
      It will aim to provide a direct link to any user manuals available. 
      If a manual is not available, it will mention this clearly.`;

      fetch('https://tcst.me/openai/t', {
        method: 'POST',
        body: JSON.stringify({
          url: url,
          prompt: prompt,
        }),
        headers: { 'Content-type': 'application/json' }
      }).then(response => response.json()).then(data => {
        this.loading = false;
        let obj = JSON.parse(data.response);
        console.log('RESPONSE: ', obj);
        //let obj = data.choices[0].message.content;
        let text = '';
        let key = '';
        let keys = Object.keys(obj);
        let finalOutput = '<div class="imageTranslation">';
        for (let i = 0; i < keys.length; i++) {
          text = JSON.stringify(obj[keys[i]]).replace(/['"]+/g, '');
          if (keys[i] == 'modelNumber') {
            console.log(`MODEL NUMBER: ${obj[keys[i]]}`);
            finalOutput += `<div><b>MODEL NUMBER:</b> ${text}</div>`;
            //modelInfo(obj[keys[i]]);
          } else if (keys[i] == 'serialNumber') {
            console.log(`SERIAL NUMBER: ${obj[keys[i]]}`);
            finalOutput += `<div><b>SERIAL NUMBER:</b> ${text}</div>`;
          } else {
            console.log(keys[i], ':', obj[keys[i]]);
            key = JSON.stringify(keys[i]).replace(/[_]+/g, ' ');
            key = key.replace(/['"]+/g, '');
            finalOutput += `<div><b><span style="text-transform: uppercase;">${key}</span>:</b> <code>${text}</code></div>`;
          }
        }
        finalOutput += '</div>';
        this.$parent.alertBox({
          message: finalOutput,
          primary: 'Save',
          fctn: 'saveImageTranslation',
          evt: obj
        });
      });
    },
    saveImageTranslation(e) {
      console.log(e);
    },
    cancelImageTranslation() {
      this.dURL = null;
    },
    checkCameraMicrophone() {
      /*
      try {
        navigator.permissions.query({ name: 'microphone' }).then(res => {
          console.log(res)
          if (res.state == "denied") {
            this.$parent.alertBox({
              message: '<p>Please update your browser settings to allow for microphone share.</p>',
              primary: 'Okay',
              fctn: 'cancelAlert',
              cancel: 'na',
              evt: null
            });
          }
        });
      } catch (e) {
        console.log(e)
      }
      */
      try {
        navigator.permissions.query({ name: 'camera' }).then(res => {
          console.log(res)
          if (res.state == "denied") {
            this.$parent.alertBox({
              message: '<p>Please update your browser settings to allow for camera share.</p>',
              primary: 'Okay',
              fctn: 'cancelAlert',
              cancel: 'na',
              evt: null
            });
          }
        });
      } catch (e) {
        console.log(e)
      }
    },
    cancelAlert() {
      this.$parent.alertStatus = {};
      this.$parent.tcMenuStatus = false;
    },
    alertMsg(msg) {
      console.log('====> ALERT:    ', msg)
    },
    async createPID() {
      this.pid = `${this.usid}${this.uid}`;//-${Date.now()}`;
    },
    async init() {
      if (!this.$parent.usid) return;

      if (this.$refs.blank) {
        this.$refs.blank.addEventListener('click', () => {
          this.$refs.blank.play();
          this.$refs.blank.style.display = 'none';
          console.log('SET!')
          const ___firstClickTimer = setTimeout(() => {
            clearTimeout(___firstClickTimer);
            this.$refs.blank.remove();
          }, 1000);
        });
      }

      //const emptyStream = await this.createEmptyStream();
      //const mediaStream = await new MediaStream([emptyStream]);
      //this.localEmptyStream = await new MediaStream([emptyStream]);
      this.localEmptyStreamScreen = await new MediaStream([await this.createEmptyStream()]);
      this.localEmptyStreamCamera = await new MediaStream([await this.createEmptyStream()]);

      this.uid = this.$parent.user.uid;
      this.usid = this.$parent.usid;
      //this.createPID();
      this.pid = `${this.usid}${this.uid}`;//-${Date.now()}`;
      this.$parent.pid = this.pid;
      this.usidPath = ref(__db, `${__as}/${this.usid}`);
      this.$parent.usidPath = this.usidPath;
      this.admPath = ref(__db, `${__as}/${this.usid}/admin`);
      this.myPath = ref(__db, `${__as}/${this.usid}/guests/${this.uid}`);
      await get(this.myPath).then((snapshot) => {
        if (snapshot.exists()) {
          console.log('exists');
          this.refreshPeer = true;
        } else {
          console.log('does not exists');
        }
      }).catch((error) => {
        console.error(error);
      });
      this.$parent.myPath = this.myPath;
      this.myStatus = ref(__db, `${__as}/${this.usid}/guests/${this.uid}/connected`);
      this.$parent.myStatus = this.myStatus;
      this.videosPath = ref(__db, `${__as}/${this.usid}/videos`);
      this.$parent.videosPath = this.videosPath;
      this.stillsPath = ref(__db, `${__as}/${this.usid}/stills`);
      this.$parent.stillsPath = this.stillsPath;

      /*
      this.sharePath = ref(__db, `${__as}/${this.usid}/sharing/`);
      this.$parent.sharePath = this.sharePath;
      this.mySharePath = ref(__db, `${__as}/${this.usid}/sharing/${this.uid}`);
      this.$parent.mySharePath = this.mySharePath;
      */
      
      
      //onDisconnect(this.myPath).remove();
      
      
      //onDisconnect(this.myStatus).set(false)

      await this.createPeer()

      this.$parent.setDisconnect();

      update(this.myPath, {
        mobile: this.$parent.mobile,
        //pid: false,
        refresh: this.refreshPeer ? true : null,
        visible: this.appVisible,
        nickname: this.$parent.nickname,
        connected: true,
        ts: Math.floor(Date.now() / 1000)
      }).then(() => {
        if (!window.location.pathname.includes(this.usid)) {
          console.log('userspace line 631')
          window.history.pushState('', 'Truecast Live', `${document.location.origin}${document.location.pathname}#/${this.usid}`);
        }
      }).catch((error) => {
        console.log(error);
      });

      this.unsubscribeGetMyPath = await get(this.myPath).then(async (snap) => {
        if (snap.exists()) {
          if (snap.val().camera == 'share') {
            console.log('unsubscribeGetMyPath', snap.val());
            this.shareCamera();
          }
        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });

      this.unsubscribeMyPath = onValue(this.myPath, async (snap) => {
        console.log('unsubscribeMyPath', snap, snap.val());
        if (!snap.val()) {
          ///*
          location.replace(document.location.origin + document.location.pathname + '#/expired');
          setTimeout(() => {
            location.reload(true);
          }, 500);

           //*/
        }
        if (snap.val() && snap.val().camera == 'share') {
          //this.shareCamera();
        }
      });
      this.unsubscribeMyValue = onValue(this.admPath, async (snap) => {
        if (!snap.val()) {
          window.location.href = document.location.origin + document.location.pathname;
        }
        //console.log('==> adminValue', snap, snap.val());
        if (snap.val() && snap.val().first && snap.val().last) {
          this.adminCompany = snap.val().company;
          this.adminFName = snap.val().first ? snap.val().first : '';
          this.adminLName = snap.val().last ? snap.val().last : '';
          this.admingFullName = `${snap.val().first} ${snap.val().last}`;
        }
        if (snap.val() && snap.val().pid) {
          this.spid = snap.val().pid;
        }
        if (snap.val() && snap.val().screen == 'share') {
          this.remoteShareScreenStatus = true;
        }
        if (snap.val() && snap.val().screen == 'stop') {
          this.remoteShareScreenStatus = false;
          this.remoteStreamScreen = null;
          if (this.remoteScreenCall) this.remoteScreenCall.close();
        }
        if (snap.val() && !snap.val().screen) {
          this.remoteShareScreenStatus = false;
          this.remoteStreamScreen = null;
          if (this.remoteScreenCall) this.remoteScreenCall.close();
        }

        if (snap.val() && snap.val().camera == 'share') {
          this.remoteShareCameraStatus = true;
        }
        if (snap.val() && snap.val().camera == 'stop') {
          this.remoteShareCameraStatus = false;
          this.remoteStreamCamera = null;
          if (this.remoteCameraCall) this.remoteCameraCall.close();
        }
        if (snap.val() && !snap.val().camera) {
          this.remoteShareCameraStatus = false;
          this.remoteStreamCamera = null;
          if (this.remoteCameraCall) this.remoteCameraCall.close();
        }
        if (snap.val() && snap.val().data) {
          //console.log('DATA:', snap.val().data);
          this.adminData = snap.val().data
        }

        if (snap.val() && snap.val().endedsession) {
          //set(this.myPath, null)
          window.location.href = document.location.origin + document.location.pathname;
        }

        if (snap.val() && Object.values(snap.val())[0].pid) {
          this.spid = Object.values(snap.val())[0].pid;
        }
      });


    }
  },
  async mounted() {
    this.$parent.nickname = 'guest';
    //if (this.$parent.usid) this.init();
    this.init();
    /*
    navigator.permissions.query({ name: 'screen-wake-lock' }).then(res => {
      //https://developer.mozilla.org/en-US/docs/Web/API/Screen_Wake_Lock_API
      console.log(res)
      if (res.state == "granted") {
        // has permission
        this.wakeLock = null;
        try {
          navigator.wakeLock.request('screen').then(lock => {
            this.wakeLock = lock;
            console.log("Wake Lock is active!");
          });
        } catch (err) {
          console.log(err)
        }
      } else {
        this.wakeLock = null;
        this.wakeLock = navigator.wakeLock.request('screen').then(lock => {
          this.wakeLock.addEventListener('onchange', () => {
            alert("Wake Lock change!");
          });
        });
        console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX');
        //navigator.wakeLock.request()
        document.addEventListener('click', () => {
          navigator.wakeLock.request()
        })
      }
    });
    */
    this.checkCameraMicrophone();


    /*
    if(localStorage.getItem('nickname')) {
        this.$parent.nickname = localStorage.getItem('nickname');
        this.init();
    } else {
        this.$parent.currentScreen = 'NickName';
    }

    this.$parent.footerText = '';
    */
  }
  ,
  beforeMount() {

  }
  ,
  beforeCreate() {

  }
  ,
  created() {
    if (document.querySelector('.primaryFooter')) document.querySelector('.primaryFooter').remove();
  }
  ,
  beforeDestroy() {

  }
  ,
  destroyed() {
    if (this.unsubscribeMyPath) this.unsubscribeMyPath();
    if (this.unsubscribeUSID) this.unsubscribeUSID();
    if (this.unsubscribeMyValue) this.unsubscribeMyValue();
    if (this.unsubscribeValue) this.unsubscribeValue();
    if (this.unsubscribeChildAdded) this.unsubscribeChildAdded();
    if (this.unsubscribeChildAdded) this.unsubscribeChildAdded();
    if (this.unsubscribeChildChanged) this.unsubscribeChildChanged();
    if (this.unsubscribeMyChildChanged) this.unsubscribeChildChanged();
    if (this.unsubscribeMyChildRemoved) this.unsubscribeChildRemoved();
    if (this.unsubscribeMyChildRemoved) this.unsubscribeChildRemoved();
  }
  ,
  directives: {}
  ,
  computed: {}
  ,
  watch: {
    '$parent.appFocused'(val) {
      this.appFocused = val;
      update(this.myPath, { focused: this.appFocused });
      return;
    },
  },
}
</script>

<style scoped>

#userspace {
  position: absolute;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;


}

.controls {
  position: relative;
  width: 100%;
  height: auto;
  margin: 25px 0;
}

.endSession {
  position: fixed;
  bottom: 40px;
  z-index: 9999px;
}

.primaryButton {
  font-size: 16px;
  line-height: 32px;
  min-width: 90px;
  white-space: nowrap;
  height: 32px;
  border-radius: 16px;
  padding: 0 20px;
  margin: 0 15px 0 0;
  border: none;
}

.remoteStreamContainer {
  padding: 0;
  margin: 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
}

.remoteStreamContainer .remoteStream {
  position: absolute;
  left: 50%;
  top: var(--tc-share-height);
  width: 100%;
  height: calc(100% - var(--tc-share-height));
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}


.remoteStreamContainer .nowsharing {
  position: absolute;
  left: 0;
  top: 0;
  height: var(--tc-share-height);
  text-align: center;
  background-color: var(--tc-blue);
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: var(--tc-share-height);
  width: 100%;
  z-index: 10;
  box-shadow: 0 5px 8px rgba(0, 0, 0, .3);
  text-transform: uppercase;
}

.connectionStatus {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.connectedWithRep {
  font-size: 20px;
  line-height: 28px;
  pointer-events: none;
  margin: 15px 0;
}

.nickname {
  border: 2px solid var(--tc-blue);
  color: var(--tc-blue);
  box-sizing: border-box;
  border-radius: 25px;
  width: 300px;
  height: 50px;
  outline: none;
  font-size: 1.7em;
  text-transform: lowercase;
  text-align: center;
}

.nickname::placeholder {
  color: var(--tc-bordergrey);
  opacity: 1;
  font-size: 1.2em;
}

.nickname:placeholder-shown {
  font-size: 1.2em;
}
.toolBar {
  position: absolute;
  left: 50%;
  top: -40px;
  transform: translateX(-50%);
  width: 96px;
}
.recordBTN {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid rgb(255, 255, 255);
  box-shadow: 0 0 15px rgba(0,0,0,.7);
}

.recordBTN:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgb(255, 0, 0);
}

.recordBTN.active:after {
  content: "";
  position: absolute;
  left: 12.5px;
  top: 12.5px;
  width: 16px;
  height: 16px;
  border-radius: 25%;
  background-color: rgb(255, 0, 0);
  animation: pulse 2000ms infinite;
  opacity: 1;
}

.stillBTN {
  position: absolute;
  left: 60px;
  top: 3px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 3px solid rgb(255, 255, 255);
  box-shadow: 0 0 15px rgba(0, 0, 0, .7);
}

.stillBTN:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.canvasSnapshot {
  position: absolute;
  left: 150%;
  display: none;
}

@keyframes pulseOpacityOutline {
  0% {
    outline: 3px solid rgba(255, 0, 0, 0);
  }

  40% {
    outline: 3px solid rgba(255, 0, 0, 1);
  }

  60% {
    outline: 3px solid rgba(255, 0, 0, 1);
  }

  100% {
    outline: 3px solid rgba(255, 0, 0, 0);
  }
}
.recordingOutline {
  outline: 3px solid rgba(255, 0, 0, 0);
  outline-offset: -2px;
  animation: pulseOpacityOutline 3000ms infinite;
}

</style>
