import Vue from 'vue';
import VueRouter from 'vue-router';
import Core from './Core.vue'

Vue.config.productionTip = false

//Vue.component('AdminDashboard', AdminDashboard); // Register the component globally

//if (window.location.hostname !== 'localhost') console.log = function () {  };
/* eslint-disable no-console */
if (typeof window !== 'undefined' && window.location.hostname !== 'localhost') {
  console.log = function () { };
}
/* eslint-enable no-console */
new Vue({
  render: h => h(Core),
  VueRouter
}).$mount('#core')
