import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { initializeFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getAnalytics, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBYVnF0J9k89M7i1zySet606mYZOvGTBow",
    authDomain: "truecast-live.firebaseapp.com",
    projectId: 'truecast-live',
    databaseURL: 'https://truecast-live-default-rtdb.firebaseio.com',
    storageBucket: 'truecast-live.appspot.com',
    appId: '1:765229346099:web:9f90a57a025ab47a7b6246',
    measurementId: 'G-PTQDDSNSVF'
};
const firebaseApp = initializeApp(firebaseConfig);


export const __db = getDatabase(firebaseApp);
export const __fs = initializeFirestore(firebaseApp, { experimentalForceLongPolling: true });
export const __au = getAuth();
export const __sc = '/tclv5_root/code_repo';
export const __as = '/tclv5_root/active_sessions';
export const __an = getAnalytics(firebaseApp);

setAnalyticsCollectionEnabled(__an, false);

export const __st = getStorage(firebaseApp);

