<template>
  <div id="expired">
    <div class="contentCent">
      <div class="logo">
        <img ref="primary_logo" alt="Truecast" class="primary_logo" src="@/assets/truecast_logo_full_white.svg" width="100%" height="auto">
      </div>

      <div class="contentRow">
        <div class="message">This session has expired.</div>

        <button id="logInBtn" class="primaryButton button" type="submit" @click="gotit">
          got it
        </button>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: 'expired',
  data() {
    return {}
  },
  components: {},
  methods: {
    gotit() {
      window.location.href = window.location = document.location.origin + document.location.pathname;
    }
  },
  mounted() {
  },
  beforeMount() {

  },
  beforeCreate() {

  },
  created() {

  },
  beforeDestroy() {

  },
  destroyed() {

  },
  directives: {},
  computed: {},
  watch: {},
}
</script>

<style scoped>

.logo {
  position: relative;
  top: 0;
  height: 145px;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  opacity: .55;
}

.logo img {
  width: 100%;
  height: 100%;
}
.message {
  font-size: 20px;
  margin: 30px;
  white-space: nowrap;
  color: rgb(255, 255, 255);
}
.primaryButton {
  position: relative;
  background-color: var(--tc-blue);
  font-family: 'bookType';
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  font-size: .8em;
  line-height: 38px;
  min-width: 140px;
  height: 40px;
  border-radius: 20px !important;
  /*margin: 5px;*/
  padding: 0 30px;
  outline: none;
  border: 2px solid var(--tc-blue);
  /*transition: opacity 200ms ease-in-out;*/
  cursor: pointer;
}



</style>
