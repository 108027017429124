import Vue from 'vue';
import VueRouter from 'vue-router';

import Start from '@/components/Start';

//const AdminDashboard = () => import('./components/AdminDashboard.vue');

//import Expired from '@/components/Expired';
//import Login from '@/components/Login';
//import Reset from '@/components/Reset';
//import CodeInput from '@/components/CodeInput';
//import UserSpace from '@/components/UserSpace';
//import NickName from '@/components/NickName';
//import AdminDashboard from '@/components/AdminDashboard';

Vue.use(VueRouter);

export default new VueRouter({
    //mode: 'history',
    routes: [
        //{ path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound, props: true },
        { path: '/:sid', name: 'default', component: Start, props: true },
        //{ path: '/expired', name: 'Expired', component: Expired},
        //{ path: '/start', name: 'Start', component: Start, props: true},
        //{ path: '/login', name: 'Login', component: Login, props: true},
        //{path: '/:product/:code', name: 'shared', component: shared, props: true},
        //{path: '/library', name: 'library', component: library},
        //{path: '/shared/:code', name: 'shared', component: shared, props: true},

    ]
});
