<template>
  <div id="admindashboard" :class="[
      !mobile && !localStreamCamera && !localStreamScreen && !remoteStreamScreen && remoteStreamCamera ? 'remoteCameraFull' : '',
      !mobile && !localStreamCamera && !localStreamScreen && !remoteStreamCamera && remoteStreamScreen ? 'remoteScreenFull' : '',
      remoteStreamScreen ? 'activeRemoteStreamScreen' : '',
      remoteStreamCamera ? 'activeRemoteStreamCamera' : ''
      ]" v-cloak>
    <div class="" id="content" :class="[(localStreamScreen || localStreamCamera || (!mobile && remoteStreamCamera)) ? 'activeLocalStreams' : '']">
      <transition name="fade">
        <div v-if="!localStreamScreen && !localStreamCamera && !remoteStreamScreen && !remoteStreamCamera" class="logo">
          <img ref="primary_logo" alt="Truecast" src="@/assets/truecast_logo_full_white.svg" width="100%" height="auto" style="opacity: .55;">
        </div>
      </transition>

      <transition name="fade">
        <div v-if="!localStreamScreen && !localStreamCamera && !remoteStreamScreen && !remoteStreamCamera && guestList && Object.keys(guestList).length > 0" class="guestStatus">
          <div v-if="!mobile" class="connectedWithGuests">You are now connected with your guest.</div>
          <div v-else-if="mobile" class="connectedWithGuests">You are now connected<br>with your guest.</div>
        </div>
      </transition>

      <div v-if="mobile" v-show="remoteStreamCamera" class="remotePreview camera">
        <video ref="remoteStreamCamera" :src="remoteStreamCamera" class="localStream" :class="[remoteStreamCamera && recording ? 'recordingFrame' : '']" autoplay muted playsinline></video>
        <img v-if="remoteStreamCamera && imageData" :src="imageData" class="imagePreview" />
      </div>
      <div v-if="mobile" v-show="remoteStreamScreen" class="remoteStreamScreen">
        <video ref="remoteStreamScreen" :src="remoteStreamScreen" class="localStream" :class="[remoteStreamScreen && recording ? 'recordingFrame' : '']" autoplay muted playsinline></video>
        <img v-if="remoteStreamScreen && imageData" :src="imageData" class="imagePreview" />
      </div>

      <div v-if="mobile" v-show="localStreamCamera" class="localPreview camera">
        <video ref="localStreamCamera" :src="localStreamCamera" class="localStream" :class="[localStreamCamera && recording ? 'recordingFrame' : '']" autoplay muted playsinline></video>
        <img v-if="cameraSwapEnabled" @click="switchCamera" class="cameraSwap" alt="swap camera" src="@/assets/swapcamera.svg">
        <img v-if="localStreamCamera && imageData && !remoteStreamCamera && !remoteStreamScreen" :src="imageData" class="imagePreview" />
      </div>

      <transition name="fade">
        <div v-if="guestList && Object.keys(guestList).length == 0 && !localStreamCamera && !handshakeActive && !mobile" class="connectPane">
          <div class="buttonBase">
            <div class="buttonPanel" :class="[spinnerStatus ? 'disabled' : '']">
              <div class="idInput">
                <div class="id_input">
                  <input v-model="shareID" @keyup="isNumber($event,'up')" @keydown="isNumber($event,'down')" id="shareID" class="inputID" type="text" value="" autocorrect="off" autocapitalize="off" size="5" autocomplete="off" placeholder="ENTER CODE">
                  <transition name="fade">
                    <div v-if="loaded && helpNotes && !sendInvitesPanel && helpNotesScreen1" class="talk-bubble right">Direct your caller to true.live and ask for the 5-digit code they see there.</div>
                  </transition>
                </div>
              </div>
            </div>
            <div class="buttonPanel" :class="[spinnerStatus ? 'disabled' : '']">
              <div class="sendSelect" style="position: absolute;" :class="[shareID.length == 0 ? 'active' : 'inactive']">
                <button class="selector" @click="sendLinkPanel" style="width: 200px;">
                  SEND LINK
                </button>
                <transition name=" fade">
                  <div v-if="loaded && helpNotes && !sendInvitesPanel && helpNotesScreen1" class="talk-bubble left">Or, send a Truecast link to your caller by text or email.</div>
                </transition>
              </div>
              <div class="sendSelect">
                <button id="connectBtn" type="submit" @click="codeCheck" class="selector" :class="[shareIDStatus ? 'pass' : 'fail']" style="width: 200px;">
                  CONNECT
                </button>
              </div>
            </div>
          </div>

          <transition name="fade">
            <div v-if="sendInvitesPanel && inviteeStatus != 'waiting'" class="sendInvitesPanel">
              <div class="invitesToSend">
                <div class="invitesBase">
                  <input v-model="inviteeID" id="inviteeID" @keyup="checkInvitee($event,'up')" @keydown="checkInvitee($event,'down')" class="inviteInputField" type="text" value="" name="" autocorrect="off" autocapitalize="off" autocomplete="off" placeholder="ENTER PHONE OR EMAIL">
                  <transition name="fade">
                    <div v-if="loaded && helpNotes && sendInvitesPanel && helpNotesScreen2" class="talk-bubble right">Enter caller's<br>10-digit phone number or email address.</div>
                  </transition>
                </div>
                <div class="buttons">
                  <button @click="cancelWindow" id="endSessionBtn">CANCEL</button>
                  <button @click="checkInvitee($event,'click')" id="sendLinkBtn" :class="[inviteeID.length < 8 ? 'inactive' : 'active']">
                    SEND
                  </button>
                </div>
              </div>

            </div>
          </transition>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="guestList && Object.keys(guestList).length == 0 && !handshakeActive && mobile && !localStreamCamera" class="connectPane">
          <div class="buttonBase">
            <div class="buttonPanel" :class="[spinnerStatus ? 'disabled' : '']">
              <div class="idInput">
                <div class="id_input">
                  <input v-model="shareID" @keyup="isNumber($event,'up')" @keydown="isNumber($event,'down')" id="shareID" class="inputID" type="number" inputmode="decimal" value="" autocorrect="off" autocapitalize="off" size="5" autocomplete="off" placeholder="ENTER CODE">
                  <transition name="fade">
                    <div v-if="loaded && helpNotes && !sendInvitesPanel && helpNotesScreen1" class="talk-bubble right">Direct your caller to true.live and ask for the 5-digit code they see there.</div>
                  </transition>
                </div>
              </div>
            </div>
            <div class="buttonPanel" :class="[spinnerStatus ? 'disabled' : '']">
              <div class="sendSelect" :class="[shareID.length == 0 ? 'active' : 'inactive']">
                <button v-if="!sendInvitesPanel" class="selector" @click="sendLinkPanel" style="width: 200px;">
                  SEND LINK
                </button>
                <transition name=" fade">
                  <div v-if="loaded && helpNotes && !sendInvitesPanel && helpNotesScreen1" class="talk-bubble left">Or, send a Truecast link to your caller by text or email.</div>
                </transition>
              </div>
              <div class="sendSelect">
                <button id="connectBtn" type="submit" @click="codeCheck" class="selector" style="width: 200px;" :class="[shareIDStatus ? 'pass' : 'fail']">
                  CONNECT
                </button>
              </div>
            </div>
          </div>

          <transition name="fade">
            <div v-if="sendInvitesPanel && inviteeStatus != 'waiting'" class="sendInvitesPanel">
              <div class="invitesToSend">
                <div class="invitesBase">
                  <input v-model="inviteeID" id="inviteeID" @keyup="checkInvitee($event,'up')" @keydown="checkInvitee($event,'down')" class="inviteInputField" type="text" value="" name="" autocorrect="off" autocapitalize="off" autocomplete="off" placeholder="ENTER PHONE OR EMAIL">
                  <transition name="fade">
                    <div v-if="loaded && helpNotes && sendInvitesPanel && helpNotesScreen2" class="talk-bubble right" style="left: 25px;">Enter caller's<br>10-digit phone number or email address.</div>
                  </transition>
                </div>
                <div class="buttons">
                  <button @click="cancelWindow" id="endSessionBtn">CANCEL</button>
                  <button @click="checkInvitee($event,'click')" id="sendLinkBtn" :class="[inviteeID.length < 8 ? 'inactive' : 'active']">
                    SEND
                  </button>
                </div>
              </div>

            </div>
          </transition>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="mobile && guestList && Object.keys(guestList).length == 0 && localStreamCamera" class="buttonBase cameraMode recordEnabled">
          <div class="buttonPanel" :class="[spinnerStatus ? 'disabled' : '']">
            <div class="sendSelect">
              <button class="selector toggle camera" :class="[localStreamCamera ? 'on' : '']" @click="shareCamera">
                <div class="label">CAMERA</div>
              </button>
            </div>
            <div v-if="mobile && localStreamCamera" class="toolBar">
              <div @click="recordStream" class="recordBTN" :class="[recording ? 'active' : '']"></div>
              <input @change="stillPhotoSave" class="stillBTNInput" type="file" id="stillBTNInput" name="picture" accept="image/*" capture="environment" />
              <div @click="stillPhoto" class="stillBTN"></div>
            </div>
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="mobile && guestList && Object.keys(guestList).length > 0" class="buttonBase" :class="[localStreamCamera ? 'recordEnabled' : '']">
          <div class="buttonPanel" :class="[spinnerStatus ? 'disabled' : '']">
            <div class="sendSelect">
              <button class="selector toggle camera" :class="[localStreamCamera ? 'on' : '']" @click="shareCamera">
                <div class="label">CAMERA</div>
              </button>
            </div>
            <div v-if="mobile && (localStreamCamera || remoteStreamCamera || remoteStreamScreen)" class="toolBar">
              <div @click="recordStream" class="recordBTN" :class="[recording ? 'active' : '']"></div>
              <input @change="stillPhotoSave" class="stillBTNInput" type="file" id="stillBTNInput" name="picture" accept="image/*" capture="environment" />
              <div @click="stillPhoto" class="stillBTN"></div>
            </div>
          </div>
        </div>

        <div v-else-if="!mobile && guestList && Object.keys(guestList).length > 0" class="connectPane" style="width: 340px; min-width: 340px; max-width: 340px;">
          <div class="buttonBase">
            <div v-if="!mobile" class="buttonPanel" :class="[spinnerStatus ? 'disabled' : '']">
              <div class="sendSelect">
                <button class="selector toggle screen" :class="[localStreamScreen ? 'on' : '']" @click="shareScreen">
                  <div class="label">SCREEN</div>
                </button>
              </div>
            </div>
            <div class="buttonPanel" :class="[spinnerStatus ? 'disabled' : '']">
              <div class="sendSelect">
                <button class="selector toggle camera" :class="[localStreamCamera ? 'on' : '']" @click="shareCamera">
                  <div class="label">CAMERA</div>
                </button>
              </div>

            </div>
          </div>

        </div>
      </transition>
      <div v-if="mobile && canvasSnapshotActive" class="canvasSnapshotContainer">
        <canvas ref="canvasSnapshot" class="canvasSnapshot"></canvas>
      </div>

      <transition name="fade">
        <div v-if="statusMessage" class="statusMessage">
          {{ statusMessage }}
        </div>
      </transition>


      <div v-if="!mobile" class="smallStreams">
        <div v-show="localStreamScreen" class="localPreview screen">
          <video ref="localStreamScreen" :src="localStreamScreen" class="localStream" :class="[localStreamScreen && recording && !remoteStreamScreen ? 'recordingFrame' : '']" autoplay muted playsinline></video>
          <div class="nowsharing">NOW SHARING</div>
        </div>
        <transition name="fade">
          <div v-if="loaded && helpNotes && helpNotesScreen3 && localStreamScreen" class="talk-bubble right screen">Thumbnail view of shared screen is for reference only.</div>
        </transition>

        <div v-show="localStreamCamera" class="localPreview camera">
          <video ref="localStreamCamera" :src="localStreamCamera" class="localStream" :class="[localStreamCamera && recording && !remoteStreamScreen ? 'recordingFrame' : '']" autoplay muted playsinline></video>
        </div>
        <div v-show="remoteStreamCamera" class="remotePreview camera">
          <video ref="remoteStreamCamera" :src="remoteStreamCamera" class="localStream" :class="[remoteStreamCamera && !remoteStreamScreen && recording ? 'recordingFrame' : '']" autoplay muted playsinline></video>
          <div v-if="!remoteStreamScreen" class="toolBar">
            <div @click="recordStream" class="recordBTN" :class="[recording ? 'active' : '']"></div>
            <div @click="stillPhoto" class="stillBTN"></div>
          </div>
          <canvas v-if="canvasSnapshotActive" ref="canvasSnapshot" class="canvasSnapshot"></canvas>
          <img v-if="remoteStreamCamera && imageData" :src="imageData" class="imagePreview" />

        </div>
      </div>
      <div v-if="!mobile" v-show="remoteStreamScreen" class="remoteStreamScreen">
        <video ref="remoteStreamScreen" :src="remoteStreamScreen" class="localStream" :class="[remoteStreamScreen && recording ? 'recordingFrame' : '']" autoplay muted playsinline></video>
        <div v-if="!mobile && (localStreamCamera || remoteStreamCamera || remoteStreamScreen)" class="toolBar">
          <div @click="recordStream" class="recordBTN" :class="[recording ? 'active' : '']"></div>
          <div @click="stillPhoto" class="stillBTN"></div>
        </div>
        <canvas v-if="canvasSnapshotActive" ref="canvasSnapshot" class="canvasSnapshot"></canvas>
        <img v-if="remoteStreamScreen && imageData" :src="imageData" class="imagePreview" />
      </div>
    </div>

    <div id="test"></div>

    <transition name="fade">
      <img v-if="imagePreview && 2+2==5" class="imagePreview" :src="imagePreview">
    </transition>

    <video ref="remoteVideo" playsinline autoplay muted style="width: 100%; height: auto; pointer-events: none;"></video>

    <transition name="fade">
      <div v-if="transcription" @click="transcription = ''" class="transcription" v-html="transcription"></div>
    </transition>

    <transition name="fade">
      <div v-if="loading" class="progress-container"><!-- v-if="!chunksUploaded && loading" -->
        <div class="progress" id="progressPie"></div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="loading" class="loader">
        {{ uploadProgress }}
        <svg width="105" height="105" viewBox="0 0 105 105" xmlns="http://www.w3.org/2000/svg" fill="rgb(57, 127, 190)">
          <circle cx="12.5" cy="12.5" r="12.5">
            <animate attributeName="fill-opacity"
              begin="0s" dur="1s"
              values="1;.2;1" calcMode="linear"
              repeatCount="indefinite" />
          </circle>
          <circle cx="12.5" cy="52.5" r="12.5" fill-opacity=".5">
            <animate attributeName="fill-opacity"
              begin="100ms" dur="1s"
              values="1;.2;1" calcMode="linear"
              repeatCount="indefinite" />
          </circle>
          <circle cx="52.5" cy="12.5" r="12.5">
            <animate attributeName="fill-opacity"
              begin="300ms" dur="1s"
              values="1;.2;1" calcMode="linear"
              repeatCount="indefinite" />
          </circle>
          <circle cx="52.5" cy="52.5" r="12.5">
            <animate attributeName="fill-opacity"
              begin="600ms" dur="1s"
              values="1;.2;1" calcMode="linear"
              repeatCount="indefinite" />
          </circle>
          <circle cx="92.5" cy="12.5" r="12.5">
            <animate attributeName="fill-opacity"
              begin="800ms" dur="1s"
              values="1;.2;1" calcMode="linear"
              repeatCount="indefinite" />
          </circle>
          <circle cx="92.5" cy="52.5" r="12.5">
            <animate attributeName="fill-opacity"
              begin="400ms" dur="1s"
              values="1;.2;1" calcMode="linear"
              repeatCount="indefinite" />
          </circle>
          <circle cx="12.5" cy="92.5" r="12.5">
            <animate attributeName="fill-opacity"
              begin="700ms" dur="1s"
              values="1;.2;1" calcMode="linear"
              repeatCount="indefinite" />
          </circle>
          <circle cx="52.5" cy="92.5" r="12.5">
            <animate attributeName="fill-opacity"
              begin="500ms" dur="1s"
              values="1;.2;1" calcMode="linear"
              repeatCount="indefinite" />
          </circle>
          <circle cx="92.5" cy="92.5" r="12.5">
            <animate attributeName="fill-opacity"
              begin="200ms" dur="1s"
              values="1;.2;1" calcMode="linear"
              repeatCount="indefinite" />
          </circle>
        </svg>
      </div>
    </transition>

  </div>
</template>

<script>
import { __db, __fs, __as } from '@/scripts/firebase';
//import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { ref, onValue, get, update, onChildAdded, onChildChanged, onChildRemoved, remove } from 'firebase/database';
//import { ref as sRef, getStorage, uploadBytes, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import Peer from 'peerjs';
//import { IVSRealTimeClient, CreateParticipantTokenCommand, GetStageCommand, JoinStageCommand } from "@aws-sdk/client-ivs-realtime";

/*
rm -rf node_modules/.cache
npm run build
*/

export default {
  name: 'admindashboard',
  props: {},
  data() {
    return {
      baseData: null,
      shareScreenStatus: false,
      shareCameraStatus: false,
      incomingStreamType: null,
      shareID: '',
      shareIDStatus: false,
      shareIDObj: null,
      shortCode: null,
      guestList: null,
      firstGuest: null,
      localStream: null,
      localStreamScreen: null,
      localStreamCamera: null,
      remoteStreamScreen: null,
      remoteStreamCamera: null,
      remoteShareScreenStatus: false,
      remoteShareCameraStatus: false,
      remoteStream: null,
      videoStream: null,
      localEmptyStreamScreen: null,
      localEmptyStreamCamera: null,
      pid: null,
      spid: null,
      someoneSharing: null,
      someoneSharingScreen: null,
      someoneSharingCamera: null,
      guestShare: null,
      peer: null,
      peerIds: [],
      connections: {},
      shareButtonText: 'share',
      currentGuestRequest: null,
      appVisible: this.$parent.appVisible,
      appFocused: this.$parent.appFocused,
      tcMenuStatus: this.$parent.tcMenuStatus,
      spinnerStatus: false,
      statusMessage: null,
      handshakeActive: null,
      sendInvitesPanel: false,
      inviteeStatus: null,
      inviteeID: '',
      connectedToGuest: false,
      mobile: this.$parent.mobile,
      helpNotes: this.$parent.helpNotes,
      helpNotesScreen1: this.$parent.helpNotes,
      helpNotesScreen2: this.$parent.helpNotes,
      helpNotesScreen3: this.$parent.helpNotes,
      loaded: false,
      cameraSwapEnabled: true,
      recordingBlob: null,
      transcription: null,
      recording: false,
      loading: false,
      imagePreview: null,
      canvasSnapshotActive: null,
      isMp4Supported: MediaRecorder.isTypeSupported('video/mp4'),
      receivedChunks: [],
      totalChunks: 0,
      imageData: null,
      uploadProgress: null,
      photoSaved: false,
      chunksUploaded: null,
      chunksRemaining: null,
    }
  },
  components: {},
  methods: {
    flipToggle(me) {
      console.log(me)
      if (me.classList.contains('on')) {
        me.classList.remove('on');
      } else {
        me.classList.add('on');
      }
    },
    connectCode(e) {
      console.log(e)
    },
    cancelWindow() {
      console.log('cancelWindow');
      if (this.statusMessage == 'waiting for guest...') {
        this.statusMessage = null;
        this.$parent.endSession();
      }
      this.shareID = '';
      this.endSessionRequest = false;
      this.continueSessionRequest = false;
      this.alertType = null;
      this.alertMessage = null;
      this.sendInvitesPanel = false;
      this.inviteeStatus = null;
      this.inviteeID = '';
      this.$nextTick(() => {
        console.log();
        if (document.querySelector('#shareID')) document.querySelector('#shareID').focus();
      });
    },
    sendLinkPanel(e) {
      console.log(e.target)
      //this.btnHighlight(e.target);
      this.sendInvitesPanel = true;
      this.$nextTick(() => {
        document.querySelector('#inviteeID').focus();
        if (this.helpNotes) {
          this.helpNotesScreen2 = true;
          setTimeout(() => {
            this.helpNotesScreen2 = false;
          }, 20000);
        }
      });
    },
    async alertMe(evt) {
      this.$parent.btnClick(evt);
      this.$parent.alertBox({message: 'this is a test', header: 'ERROR', primary: 'test', fctn: 'testRES', evt: evt});
    },
    testRES(e) {
      console.log(e)
    },
    async guestRequestCheck(evt) {
      this.$parent.btnClick(evt);

      if (this.guestList && Object.keys(this.guestList).length == 1) {
        //console.log(Object.keys(this.guestList)[0],Object.values(this.guestList)[0]);
        this.guestRequest(Object.keys(this.guestList)[0]);
      } else {
        this.requestCallerShare = true;
        this.$parent.tcMenuStatus = true;
      }
    },
    async guestRequest(g) {
      if (typeof g === 'object') {
        if (this.guestList && Object.keys(this.guestList).length == 1) {
          console.log(Object.keys(this.guestList)[0], Object.values(this.guestList)[0]);
        } else {
          this.requestCallerShare = true;
          this.$parent.tcMenuStatus = true;
        }
      } else if (typeof g === 'string') {
        if (this.guestList[g].mobile) {
          this.$parent.alertBox({
            message: '<p>Screen sharing from a mobile device is not supported at this time.</p>',
            header: 'Mobile Device',
            primary: null,
            fctn: null,
            evt: null
          });
        } else {
          this.currentGuestRequest = g;
          if (await this.getGuestData(g, 'sharing')) {
            this.updateGuestData(g, 'sharing', null);
            this.currentGuestRequest = null;
            //update(guest, { sharing: true } );
          } else {
            this.updateGuestData(g, 'sharing', 'request')
          }
        }
      }
    },
    cancelRequest(evt) {
      this.$parent.btnClick(evt);
      this.updateGuestData(this.currentGuestRequest, 'sharing', null);
      this.requestCallerShare = null;
      this.currentGuestRequest = null;
      this.$parent.tcMenuStatus = false;
    },
    cancelSharing(evt) {
      this.$parent.btnClick(evt);
      console.log(this.guestShare.spid);
      update(ref(__db, `${__as}/${this.usid}/guests/${this.guestShare.spid.slice(48)}`), {sharing: 'stop'});
    },
    async getGuestPath(g) {
      return ref(__db, `${__as}/${this.usid}/guests/${g}`);
    },
    async getGuestData(g, i) {
      let snap = await get(ref(__db, `${__as}/${this.usid}/guests/${g}`), '/');
      //return i ? (!snap.val()[i]) ? false : snap.val()[i] : snap.val();
      return i ? snap.val()[i] : snap.val();
    },
    async updateGuestData(g, n, v) {
      update(ref(__db, `${__as}/${this.usid}/guests/${g}`), {[n]: v});
    },
    mutedStatus() {
      this.$refs.remoteStream.muted = false;
    },
    muteMe() {
      this.$refs.localStream.muted = !this.$refs.localStream.muted;
      console.log(this.$refs.localStream.muted)
    },
    requestGuestScreen(evt) {
      this.$parent.btnClick(evt);
    },
    cancelGuestRequest(evt) {
      this.$parent.btnClick(evt);
    },
    codeCheck(evt) {
      evt.preventDefault();
      if (this.shareID.length < 5) return;
      this.$parent.btnClick(evt);
      fetch(`${this.$parent.__pt}cc`, {
        method: 'POST',
        body: JSON.stringify({usid: this.usid, uid: this.uid, code: this.shareID}),
        headers: {'Content-type': 'application/json'}
      }).then(response => response.json()).then(data => {
        if (data && !this.connectedToGuest) {
          this.shareID = '';
          if (data.response == false) {
            this.shareID = '';
          }
          if (data.response.ts > 100000) {
            setTimeout(() => {
              this.shareID = '';
            }, 1000);
            this.connectedToGuest = true;
          }
        }
      });
    },
    isNumber(evt, t) {
      this.resetMe()
      if (evt && evt.target && evt.target.id != 'shareID') return;
      if (evt !== undefined) {
        this.helpNotesScreen1 = false;
        let charCode = (evt.which) ? evt.which : evt.keyCode;
        if (this.shareID.length < 5) {
          this.shareIDStatus = false;
          if (charCode > 31 && ((charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105))) {
            evt.preventDefault();
            this.shareIDStatus = false;
          } else {
            return true;
          }
        } else {
          if (charCode != 8 && charCode != 13) {
            evt.preventDefault();
            this.shareIDStatus = true;
          } else {
            if (charCode == 13 && t == 'up') {
              this.codeCheck(evt)
            }
            return true;
          }
        }
      }
    },
    checkInvitee(evt, t) {
      this.helpNotesScreen2 = false;
      if (t == 'click') this.sendInvite(evt);
      if (evt && evt.target && evt.target.id != 'inviteeID') return;
      if (evt !== undefined) {
        let charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode == 13 && t == 'up') {
          this.sendInvite(evt)
        }
        return true;
      }
    },
    sendInvite() {
      document.querySelector('#sendLinkBtn').classList.add('inactive');
      document.querySelector('#inviteeID').classList.add('noSelect');
      document.querySelector('#inviteeID').disabled = true;
      let invitee = this.inviteeID;//document.querySelector('#inviteeID').value;

      console.log(invitee)
      //this.inviteeID = 'sending invite...'
      this.statusMessage = 'sending invite...'

      fetch(`${this.$parent.__pt}s`, {
        method: "POST",
        body: JSON.stringify({
          url: this.$parent.__href,
          //url: this.$parent.__dv ? 'https://localhost:8080/#/' : 'https://true.live/r/?',
          invitee: invitee,
          handshake: this.usid,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      }).then(response => response.text()).then(data => {
        //console.log(data.length);
        if (data && data.length == 48) {
          //this.inviteeStatus = 'waiting';
          console.log(data);
          //this.inviteeID = 'waiting for guest...'
          this.statusMessage = 'waiting for guest...'
        }
      });
    },
    setFocus() {
      this.shareIDObj = document.querySelector('#shareID');
      if (this.shareIDObj) this.shareIDObj.focus();
      this.$nextTick(() => {
        if (this.shareIDObj) this.shareIDObj.focus();
      });
    },
    async setupSession() {
      const response = await fetch(`${this.$parent.__pt}csid`, {
        method: 'POST',
        body: JSON.stringify({uid: this.uid}),
        headers: {'Content-type': 'application/json'}
      });
      return await response.json();
    },
    async addConnection(conn) {
      console.log('addConnection =====>', conn)
      this.connections[conn.peer] = conn;
      this.updatePeerIds();
      if (this.localStreamCamera) {
        const mycall = this.peer.call(this.spid, this.localStreamCamera, {metadata: {'type': 'camera'}});
        mycall.on('stream', (rS) => {
          this.remoteStreamScreen = this.$refs.remoteStreamScreen.srcObject = rS;
        });
      }
      if (this.localStreamScreen) {
        const mycall = this.peer.call(this.spid, this.localStreamScreen, {metadata: {'type': 'screen'}});
        mycall.on('stream', (rS) => {
          this.localStreamScreen = this.$refs.localStreamScreen.srcObject = rS;
        });
      }

      console.log(`Connected to ${conn.peer}!`);
    },
    async removeConnection(conn) {
      delete this.connections[conn.peer];
      this.updatePeerIds();
    },
    async updatePeerIds() {
      this.peerIds = Object.keys(this.connections);
    },
    async disconnectPeer() {
      this.peer.disconnect();
    },
    async reconnectPeers(p) {
      if(!p) return;
      //this.peer.connect(p);
      //this.configureConnection(this.peerConnection)
      console.log('trying to connect to peer: ',p)
      //this.addConnection(p)
      this.initiateConnection(p);
    },
    async configureConnection(conn) {
      console.log('configureConnection =====>', conn)
      this.peerConnection = conn;
      conn.on('data', data => {
        console.log('DATA:: ', data);
        conn.send('back atcha')
        if (data.cmd) data = data.cmd;
        switch (data) {
          case 'camera':

            break;
          case 'screen':

            break;
          case 'allowcamerarecord':
            console.log('DATA: ', 'permission to record camera');
            this.recordStreamContinueGCS()
            break;
          case 'nocamerarecord':
            console.log('DATA: ', 'not allowed to record camera');
            this.cancelApproval()
            break;
          case 'stopcamerarecord':
            console.log('DATA: ', 'stop camera record');
            this.recordStream();
            break;
          case 'allowscreenrecord':
            console.log('DATA: ', 'permission to record screen');
            this.recordStreamContinueGCS();
            break;
          case 'noscreenrecord':
            console.log('DATA: ', 'not allowed to record screen');
            this.cancelApproval()
            break;
          case 'stopscreenrecord':
            console.log('DATA: ', 'stop screen record');
            this.recordStream();
            break;
          default:
            if (data.type === 'connections') {
              data.peerIds.forEach(peerId => {
                console.log('==> peerId: ', peerId)

                if (!this.connections[peerId]) {
                  this.initiateConnection(peerId);
                }
              });
            }
            if (data.type === 'chunk') {
              this.receivedChunks[data.index] = data.data;
              this.totalChunks = data.totalChunks;
              console.log(`Received chunk ${data.index + 1}/${data.totalChunks}`);
              if (this.receivedChunks.length === this.totalChunks && this.receivedChunks.every(Boolean)) {
                this.reassembleImage();
              }
            }
            if (data.type === 'end') {
              console.log('All chunks received.');
            }
        }

      });
      conn.on('close', () => this.removeConnection(conn));
      conn.on('error', () => this.removeConnection(conn));

      conn.metadata.peerIds.forEach(peerId => {
        console.log(peerId)
        if (!this.connections[peerId]) {
          this.initiateConnection(peerId);
        }
      });
    },
    async initiateConnection(peerId) {
      if(!peerId) return;
      console.log('initiateConnection =====>', peerId)
      console.log('connecting to:', peerId)
      console.log(this.peer)
      console.log(this.peerIds)
      console.log(this.peer.id)
      if (this.peer && !this.peerIds.includes(peerId) && peerId !== this.peer.id) {
        const options = {
          metadata: {
            peerIds: this.peerIds
          },
          serialization: 'json'
        };
        const conn = await this.peer.connect(peerId, options);
        this.peerConnection = conn;
        this.configureConnection(conn);
        conn.on('open', () => {
          this.addConnection(conn);
          conn.send('hello');
        });
        return true;
      } else {
        return false;
      }
    },
    async getTS() {
      const response = await fetch(`${this.$parent.__pt}ts`);
      this.tsData = await response.json();
    },
    logoutOther() {
      update(this.myPath, { logout: this.__tcstTS });
      setTimeout(async () => {
        location.reload();
      }, 2000);
    },
    logMeOut() {
      this.$parent.logOut3();
    },
    async createPeer() {
      if (this.peer) {
        this.peer.destroy();
        this.peer = null;
      }
      update(this.myPath, {pid: null});
      await this.getTS();

      let config = {
        host: this.$parent.__dm,
        path: '/tclive_v5/pjs',
        secure: true,
        token: Date.now(),
        pingInterval: 2500,
        config: {
          iceServers: this.tsData
        }
      }
      if (this.$parent.__dv) {
        config.port = 9999;
        config.secure = false;
        //debug: 3,
      }

      //this.createPID();
      this.peer = await new Peer(this.pid, config);
      console.log('PEER', this.peer)

      this.peer.on('open', () => {
        console.log('connected to peer network => ', this.peer.id);
        update(this.myPath, {pid: this.pid});
        //if (this.spid) {
        this.initiateConnection(this.spid);
        //}
      });
      const FATAL_ERRORS = ['invalid-id', 'invalid-key', 'network', 'ssl-unavailable', 'server-error', 'socket-error', 'socket-closed', 'unavailable-id', 'webrtc'];
      this.peer.on('error', (error) => {
        console.log(error)
        if (FATAL_ERRORS.includes(error.type)) {
          console.log('==> FATAL PEER ERROR: ', error.type);
          if (error.type == 'unavailable-id') {
            this.$parent.alertBox({
              message: '<p>You are currently logged into another browser. Log out of that session and continue?</p>',
              primary: 'Okay',
              fctn: 'logoutOther',
              cancel: 'logMeOut'
            });
            /*
            logoutOther
            this.logoutCheck = Date.now();
            update(this.myPath, { logout: this.logoutCheck });
            setTimeout(async () => {
              location.reload();
            }, 2000);
            */

            /*
            this.peer.destroy();
            this.peer = null;
            console.log('destroyed peer');
            console.log(this.peer);
            setTimeout(async () => {
              await this.createPeer();
              console.log(this.peer);
            }, 30000);
            */
          } else {
            console.log('reconnect peer? location.reload()?');
            
            console.log('need to uncomment below?');
            //location.reload();



            //this.reconnectPeer(error);
          }
        } else {
          console.log('==> NON-FATAL PEER ERROR: ', error.type);
        }
      });

      this.peer.on('close', () => {
        console.log('==> CONNECTION CLOSED');
        update(this.myPath, {pid: null});
      });

      this.peer.on('connection', conn => {
        console.log('==> CONNECTION REQUEST')
        //return;
        console.log(this.peerIds, conn.peer);
        if (!this.peerIds.includes(conn.peer)) {
          this.configureConnection(conn);
          conn.on('open', () => {
            this.addConnection(conn);
            conn.send({
              type: 'connections',
              peerIds: this.peerIds
            });
          });
        }
      });
      this.peer.on('reconnected', () => {
        console.log('Reconnection successful.');
      });
      this.peer.on('call', (call) => {
        console.log('caller => ', call.peer, call, call.metadata.type);
        call.answer();
        /*call.answer(this.localStream);*/
        //this.remoteStreamCall = call;
        call.on('stream', (rS) => {
          switch (call.metadata.type) {
            case 'screen':
              //call.answer(this.localStreamScreen);
              this.remoteStreamScreen = this.$refs.remoteStreamScreen.srcObject = rS;
              this.remoteScreenCall = call;
              break;
            case 'camera':
              //call.answer(this.localStreamCamera);
              /*
              this.$refs.remoteStreamCamera.width = 1280;
              this.$refs.remoteStreamCamera.height = 720;

              const videoTracks = rS.getVideoTracks();
              if (videoTracks.length > 0) {
                const track = videoTracks[0];
                track.applyConstraints({
                  width: { ideal: 1280 },
                  height: { ideal: 720 },
                  frameRate: { ideal: 15 }
                }).catch(error => console.error('Error applying constraints:', error));
              }
              */
              this.remoteStreamCamera = this.$refs.remoteStreamCamera.srcObject = rS;
              this.remoteCameraCall = call;
              this.$refs.remoteStreamCamera

              /*
              this.$refs.remoteStreamCamera.onplay = () => {
                setTimeout(() => {
                  console.log(`Width: ${this.$refs.remoteStreamCamera.videoWidth}, Height: ${this.$refs.remoteStreamCamera.videoHeight}`);
                }, 30000);
              };
              */
              /*
              if (rS && rS.getVideoTracks().length > 0) {
                let videoTrack = rS.getVideoTracks()[0];
                let settings = videoTrack.getSettings();

                console.log(`Width: ${settings.width || 'N/A'}, Height: ${settings.height || 'N/A'}`);
              } else {
                console.error('No video tracks found in the remote stream.');
              }
              */

              break;
          }
        });
        this.peer.on('disconnected', () => {
          this.disconnectBackoff = 1;
          this.reconnectPeer();
        });
        call.on('close', () => {
          console.log(call.metadata.type)
          switch (call.metadata.type) {
            case 'screen':
              this.remoteStreamScreen = null;
              break;
            case 'camera':
              this.remoteStreamCamera = null;
              break;

          }
          //this.$refs.localStreamCamera.play();
        });

      });
    },
    reconnectPeer(error) {
      console.log('ATTEMPTING RECONNECT', error);
      //return;
      setTimeout(() => {
        this.createPeer();
      }, 2000);
    },
    async shareScreen(evt) {
      if (evt) this.$parent.btnClick(evt);
      if (this.localStreamScreen) {
        update(this.myPath, {screen: 'stop'});
        this.localStreamScreen.getTracks().forEach((track) => {
          if (track.readyState == 'live') {
            track.stop();
          }
        });
        this.peerScreenCall.close();
        this.localStreamScreen = null;
      } else {
        this.localStreamScreen = null;
        let config = {
          audio: false,
          frameRate: {
            ideal: 10, max: 20
          },
          video: {
            width: {ideal: 1920, max: 1920},
            height: {ideal: 1080, max: 1080},
            displaySurface: 'monitor', //browser monitor window
            cursor: 'motion', //never always motion
          },
          setFocusBehavior: true,
          surfaceSwitching: 'include',
          selfBrowserSurface: 'exclude',
          systemAudio: 'exclude',
        };
        try {
          this.localStreamScreen = this.$refs.localStreamScreen.srcObject = await navigator.mediaDevices.getDisplayMedia(config);
          this.localStreamScreen.getVideoTracks()[0].onended = () => {
            update(this.myPath, {screen: 'stop'});
            try {
              this.localStreamScreen.getTracks().forEach((track) => {
                if (track.readyState == 'live') {
                  track.stop();
                }
              });
            } catch (err) {
              console.log(err)
            }
            this.peerScreenCall.close();
            this.localStreamScreen = null;
            //this.shareScreen();
          };
          update(this.myPath, {screen: 'share'});
          const mycall = this.peer.call(this.spid, this.localStreamScreen, {metadata: {'type': 'screen'}});
          mycall.on('stream', (rS) => {
            this.remoteStreamScreen = this.$refs.remoteStreamScreen.srcObject = rS;
          });
          this.peerScreenCall = mycall;
          if (this.helpNotes) {
            this.helpNotesScreen3 = true;
            setTimeout(() => {
              this.helpNotesScreen3 = false;
            }, 20000);
          }

        } catch (err) {
          console.log(err)
          this.localStreamScreen = null;
          if (String(err).toLowerCase().includes('permission') && String(err).toLowerCase().includes('system')) {
            this.$parent.alertBox({
              message: '<p>Please update your settings to allow your browser to screen share.</p>',
              header: 'Screen Share Error',
              primary: null,
              fctn: null,
              evt: null
            });
            update(this.myPath, {errors: {[Math.floor(Date.now() / 1000)]: String(err)}});
            console.log(err);
          }
          document.querySelector('.selector.toggle.screen').classList.remove('off');
          if (String(err).includes('Permission denied by system')) {
            this.alertMessage = 'Please update your settings to allow your browser to screen share.';
            this.alertType = 'default';
          }
        }
      }
    },
    async shareCamera(evt) {
      this.$parent.tcMenuStatus = false;
      if (this.localStreamCamera) {
        update(this.myPath, {camera: 'stop'});
        this.localStreamCamera.getTracks().forEach((track) => {
          if (track.readyState == 'live') {
            track.stop();
          }
        });
        this.localStreamCamera = null;
      } else {
        this.localStreamCamera = null;

        this.checkCameraMicrophone();
        try {
          const newStream = await this.getMediaStream();
          this.localStreamCamera = this.$refs.localStreamCamera.srcObject = newStream;
          if (this.guestList && Object.keys(this.guestList).length == 0) return;
          //this.$refs.localStreamCamera.play();
          update(this.myPath, {camera: 'share'});
          const mycall = this.peer.call(this.spid, this.localStreamCamera, {metadata: {'type': 'camera'}});

          const sender = mycall.peerConnection.getSenders().find(s => s.track.kind === 'video');
          if (sender) {
            const params = sender.getParameters();
            console.log('params', params)

            params.encodings = [{
              maxBitrate: 2000000, // Adjust to desired bitrate
              scaleResolutionDownBy: 1.0 // Ensure full resolution
            }];
            sender.setParameters(params).catch(error => console.error('Failed to set parameters:', error));
          }

          mycall.on('stream', (rS) => {
            this.remoteStreamScreen = this.$refs.remoteStreamScreen.srcObject = rS;
            //this.$refs.remoteStreamScreen.remoteStreamScreen.play();
          });
        } catch (e) {
          console.log(e);
          if (String(e).includes('OverconstrainedError')) {
            this.shareCamera(evt);
            this.cameraSwapEnabled = false;
          }
          //this.sessionREF.update({'usererror':String(e)});
          if (String(e).includes('Permission denied by system')) {
            this.alertMessage = 'camera share error......';
            this.alertType = 'default';
          }
        }
      }
    },
    async getMediaStream() {
      this.useFrontCamera = !this.useFrontCamera;
      const audioConstraints = (this.guestList === null || this.guestList && Object.keys(this.guestList).length == 0) ? { sampleRate: 48000 } : false;
      const config = this.$parent.mobile ?
          {
          audio: audioConstraints,
          //audio: { sampleRate: 48000 },
          video: {
              //facingMode: this.cameraDirection == 'user' ? { exact: 'environment' } : 'user',
              facingMode: (this.useFrontCamera ? 'user' : {exact: 'environment'}),
              //frameRate: { ideal: 15, max: 25 },
              width: { ideal: 1280, max: 1920 },
              height: { ideal: 720, max: 1080 },
            }
          } : {
            audio: audioConstraints,
            //video: true,
            video: {
              //frameRate: { ideal: 15, max: 25 },
              width: { ideal: 1280, max: 1920 },
              height: { ideal: 720, max: 1080 },
            }
          };
      //console.log('navigator.mediaDevices.getSupportedConstraints',navigator.mediaDevices.getSupportedConstraints());
      return this.myStream = await navigator.mediaDevices.getUserMedia(config);
    },
    replaceVideoTrack(stream) {
      let videoTrack = stream.getVideoTracks()[0];
      console.log('videoTrack:', videoTrack)

      let peers = this.peer.connections;
      let sender
      for (let pc in peers) {
        if (pc != 'null') {
          for (let peer in peers[pc]) {
            console.log(peers[pc][peer])
            console.log(peers[pc][peer].metadata.type)
            console.log(peers[pc][peer].peerConnection.getSenders()[0])
            //console.log(peers[pc][peer].peerConnection.getLocalStreams().length)
            //console.log(typeof peers[pc][peer].peerConnection.getSenders().find(s => s.track.kind === videoTrack.kind) !== undefined);
            if (peers[pc][peer].peerConnection.getLocalStreams().length == 1 && peers[pc][peer].peerConnection.getSenders().find(s => s.track.kind === videoTrack.kind)) {
              sender = peers[pc][peer].peerConnection.getSenders().find(s => s.track.kind === videoTrack.kind);
              sender.replaceTrack(videoTrack);
            }
          }

        }
      }
    },
    async switchCamera() {
      try {
        const newStream = await this.getMediaStream();
        this.replaceVideoTrack(newStream);
        this.localStreamCamera = this.$refs.localStreamCamera.srcObject = newStream;
      } catch (error) {
        console.error('Error switching camera:', error);
        this.cameraSwapEnabled = false;
      }
    },
    async someoneIsSharingScreen() {
      console.log(this.spid, this.localEmptyStreamScreen)
      const mycall = await this.peer.call(this.spid, this.localEmptyStreamScreen);
      console.log(mycall)
      mycall.on('stream', (rS) => {
        console.log(rS, mycall.metadata.type)
        this.remoteStreamScreen = this.$refs.remoteStreamScreen.srcObject = rS;
        this.$refs.remoteStreamScreen.onloadedmetadata = () => {
          this.$refs.remoteStreamScreen.play();
        };
      });
    },
    async createEmptyStream() {
      const nullStream = Object.assign(document.createElement('canvas'), {width: 320, height: 240});
      nullStream.getContext('2d').fillRect(0, 0, 320, 240);
      const stream = nullStream.captureStream();
      const track = stream.getVideoTracks()[0];
      return Object.assign(track, {enabled: false});
    },
    async saveRecording() {
      console.log('saveRecording');
      this.loading = true;
      //this.chunksUploaded
      //if (!this.chunksUploaded) this.updateProgress(100);
      await this.waitForChunks(() => this.chunksUploaded);

      console.log('All the chunks have been uploaded!!!!');
      //this.loading = null;

      //return;
      //fetch('http://localhost:9999/tclive_v5/savevideo', {
      fetch(`${this.$parent.__pt}savevideo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cmd: 'save', sessionId: this.chunkSessionID, usid: this.usid, email: this.emailDirectory }),
      }).then(response => response.json()).then(data => {
        console.log(data,data.message);
        if (data.message == 'saved' && data.url && this.streamToRecord != 'remoteStreamScreen') {
          this.dURL = data.url;
          console.log('URL to transcribe: ', this.dURL);

          /*
          fetch('https://localhost:9999/tclive_v5/uploadURL', {
            //fetch(`${this.$parent.__pt}uploadURL`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            //body: JSON.stringify({ sessionId: this.chunkSessionID, usid: this.usid, uid: this.$parent.user.uid, email: this.emailDirectory, totalChunks: this.chunkIndex }),
            body: JSON.stringify({ fileUrl: this.dURL, sessionId: this.chunkSessionID, usid: this.usid, email: this.emailDirectory }),
          }).then(response => response.json()).then(data => {
            console.log(data);
          }).catch(error => console.error('Error uploading to dropbox:', error));
          */

          this.loading = false;
          if ((this.guestList === null || this.guestList && Object.keys(this.guestList).length == 0)) {
            this.$parent.alertBox({
              message: '<p>Transcribe recording?</p>',
              primary: 'Okay',
              fctn: 'getTranscription',
              cancel: 'cancelTranscription',
              evt: this.dURL
            });
          } else {
            this.cancelTranscription();
          }
        }
      }).catch(error => console.error('Error saving recording:', error));
    },
    waitForChunks(conditionFunction) {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (conditionFunction()) {
            clearInterval(interval);
            resolve();
          }
        }, 500);
      });
    },
    cancelRecording() {
      console.log('cancelRecording');
      //fetch('http://localhost:9999/tclive_v5/savevideo', {

      this.cancelAllUploads();

      fetch(`${this.$parent.__pt}savevideo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cmd: 'delete', sessionId: this.chunkSessionID, usid: this.usid, email: this.emailDirectory }),
      }).then(response => response.json()).then(data => {
        console.log(data.message);
        this.mediaRecorder.onstop = () => { }
        this.mediaRecorder = null;
        this.chunkSessionID = null;
      }).catch(error => console.error('Error saving recording:', error));
    },
    recordStream(event) {
      if(this.mediaRecorder) {
        if (this.streamToRecord == 'remoteStreamCamera') {
          this.peerConnection.send({ cmd: 'stoprecordcamera' });
        }
        if (this.streamToRecord == 'remoteStreamScreen') {
          this.peerConnection.send({ cmd: 'stoprecordscreen' });
        }
        this.recording = false;
        this.mediaRecorder.stop();
        //this.localStreamCamera.getTracks().forEach(track => track.stop());
        this.dURL = null;
        //this.mediaRecorder.onstop = () => { }
        //this.mediaRecorder = null;
        if(event) event.target.classList.remove('active');
        console.log('stop recording.........');
        this.$parent.alertBox({
          message: '<p>Save recording?</p>',
          primary: 'Save',
          fctn: 'saveRecording',
          cancel: 'cancelRecording'
        });        
      } else {
        this.$nextTick(() => {
          this.mobilePortrait = '';
          if(this.mobile) {
            if (this.remoteStreamScreen) this.streamToRecord = 'remoteStreamScreen';
            if (this.remoteStreamCamera && !this.remoteStreamScreen) this.streamToRecord = 'remoteStreamCamera';
            if (!this.remoteStreamCamera && !this.remoteStreamScreen) this.streamToRecord = 'localStreamCamera';
          } 
          if (!this.mobile) {
            if (this.remoteStreamScreen) this.streamToRecord = 'remoteStreamScreen';
            if (this.remoteStreamCamera && !this.remoteStreamScreen) this.streamToRecord = 'remoteStreamCamera';
            if (!this.remoteStreamCamera && !this.remoteStreamScreen) this.streamToRecord = 'localStreamCamera';
          }
          if (this.streamToRecord == 'remoteStreamCamera') {
            console.log('send recordcamera command');
            console.log('this[this.streamToRecord]', this.$refs[this.streamToRecord].videoWidth, this.$refs[this.streamToRecord].videoHeight);
            this.mobilePortrait = this.$refs[this.streamToRecord].videoWidth < this.$refs[this.streamToRecord].videoHeight ? '_M' : '';
            this.peerConnection.send({ cmd: 'recordcamera' });
            this.$parent.alertBox({
              message: '<p>Waiting on approval to record camera share</p>',
              //primary: 'Okay',
              //fctn: 'getTranscription',
              cancel: 'cancelApproval',
              //evt: this.dURL
            });
          } else if (this.streamToRecord == 'remoteStreamScreen') {
            this.peerConnection.send({ cmd: 'recordscreen' });
            this.$parent.alertBox({
              message: '<p>Waiting on approval to record screen share</p>',
              //primary: 'Okay',
              //fctn: 'getTranscription',
              cancel: 'cancelApproval',
              //evt: this.dURL
            });
          } else {
            //this.startRecordingGCS(); //
            this.recordStreamContinueGCS()
          }

        });
        
      }
    },
    async recordStreamContinueGCS() {
      this.cancelAlert();
      this.recording = true;
      console.log('start recording.........');
      this.mediaRecorder = null;
      this.chunkSessionID = `${Date.now().toString()}${this.mobilePortrait}`;
      this.chunkCount = -1;
      this.chunkURLS = {};
      this.chunksUploaded = null;
      this.totalChunks = null;
      this.lastResetTime = 0;
      //this.updateProgress(100);

      //this.chunkSessionID, usid: this.usid, uid: this.$parent.user.uid, email: this.emailDirectory
      this.abortControllers = [];

      this.mediaRecorder = new MediaRecorder(this[this.streamToRecord]);

      this.mediaRecorder.ondataavailable = async (event) => {
        if (event.data.size > 0 && this.recording) {
          this.chunkCount++;
          await this.uploadChunkGCS(event.data);
        }
      };
      this.mediaRecorder.onstop = () => {
        this.dURL = null;
        //this.finalizeUpload();
      };

      this.mediaRecorder.start(1000);
      /*
      setTimeout(async () => {
        this.stopRecordingGCS();
      }, 10000);
      */
    },
    async uploadChunkGCS(chunk) {
      if(!this.recording) return;
      const chunkFileName = `chunk-${this.chunkCount}.webm`;
      console.log('this.recording', this.recording)
      console.log('this.chunkCount', this.chunkCount)

      // Create an AbortController for this upload
      const abortController = new AbortController();
      const signal = abortController.signal;

      // Add the controller to the list so it can be canceled later
      this.abortControllers.push(abortController);

      try {
        const signedUrlResponse = await fetch(`${this.$parent.__pt}generate-signed-url`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            fileName: chunkFileName,
            chunkSessionID: this.chunkSessionID,
            usid: this.usid,
            emailDirectory: this.emailDirectory,
          }),
          signal // Pass the signal to allow cancellation
        });

        if (signal.aborted) {
          console.log('Request for signed URL was aborted');
          return;
        }

        this.resetUploadTimer(1);

        const { url } = await signedUrlResponse.json();
        console.log('signed url: ', url.match(/\/chunk-(\d+)\.webm/)[1], url);
        this.chunkURLS[url] = 'waiting';
        this.totalChunks = this.chunkCount;
        this.chunksRemaining = this.totalChunks;

        const uploadResponse = await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'video/webm',
          },
          body: chunk,
          signal // Pass the signal here to allow cancellation of the upload
        });
        console.log('uploadResponse',uploadResponse);
        this.resetUploadTimer(2);
        if (uploadResponse.ok) {
          const chunkNumber = url.match(/\/chunk-(\d+)\.webm/)[1];
          console.log(`Successfully uploaded chunk-${chunkNumber}`);

          delete this.chunkURLS[uploadResponse.url];
          this.chunksRemaining = Object.keys(this.chunkURLS).length;
          console.log(`Remaining chunks to upload: ${this.chunksRemaining}`);
          this.updateProgress((this.chunksRemaining / this.totalChunks) * 100)
          if (this.chunksRemaining == 0) {
            this.chunksUploaded = true;
          }
        } else {
          console.error('Failed to upload chunk');
        }
      } catch (err) {
        if (err.name === 'AbortError') {
          console.log(`Upload of chunk was aborted`);
        } else {
          console.error('Error during chunk upload:', err);
        }
      }

    },
    async resetUploadTimer(x) {
      const now = Date.now(); // Get the current timestamp in milliseconds
      const interval = 10000; // 10 seconds in milliseconds

      // Check if 10 seconds have passed since the last execution
      if (now - this.lastResetTime >= interval) {
        this.lastResetTime = now; // Update the last execution time

        const response = await fetch(`${this.$parent.__pt}resettimeout`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            chunkSessionID: this.chunkSessionID,
            usid: this.usid,
            emailDirectory: this.emailDirectory,
          }),
        });

        console.log(`resetUploadTimer${x}`, response);
      } else {
        console.log(`Skipping resetUploadTimer${x}: Only ${(now - this.lastResetTime) / 1000} seconds since last call`);
      }
    },
    async resetUploadTimerORIG(x) {
      const response = await fetch(`${this.$parent.__pt}resettimeout`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          chunkSessionID: this.chunkSessionID,
          usid: this.usid,
          emailDirectory: this.emailDirectory,
        }),
      });

      console.log(`resetUploadTimer${x}`, response);
    },
    updateProgress(percent) {
      if (!document.getElementById('progressPie'))return;
      const progressPie = document.getElementById('progressPie');
      progressPie.style.setProperty('--progress', percent);
    },
    cancelAllUploads() {
      console.log('send cancel command to node to clean up?');
      this.abortControllers.forEach(controller => controller.abort());
      this.abortControllers = []; // Clear the controllers list
      console.log('All chunk uploads aborted.');
    },
    async stopRecordingGCS() {
      this.mediaRecorder.stop();
      //this.localStreamCamera.getTracks().forEach(track => track.stop());

      setTimeout(async () => {
        const composeFiles = await fetch(`${this.$parent.__pt}compose-chunks`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            chunkSessionID: this.chunkSessionID,
            usid: this.usid,
            emailDirectory: this.emailDirectory,
            chunkCount: this.chunkCount
          }),
        });
        const { url } = await composeFiles.json();
        console.log(url);
      }, 5000);
      
    },
    cancelApproval() {
      this.cancelAlert();
      if (this.streamToRecord == 'remoteStreamCamera') {
        this.peerConnection.send({ cmd: 'cancelcamerarequest' });
      }
      if (this.streamToRecord == 'remoteStreamScreen') {
        this.peerConnection.send({ cmd: 'cancelscreenrequest' });
      }
    },
    emailToDirectoryName(email) {
      return email.replace(/[@.]/g, '_');
    },
    finalizeUpload() {
      //fetch('http://localhost:9999/tclive_v5/finalize', {
      fetch(`${this.$parent.__pt}finalize`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sessionId: this.chunkSessionID, usid: this.usid, uid: this.$parent.user.uid, email: this.emailDirectory, totalChunks: this.chunkIndex }),
      }).then(response => response.json()).then(data => {
        console.log(data);
        //console.log(`Download your video here: ${data.downloadLink}`);
        // You can now display the link or automatically download it
      }).catch(error => console.error('Error finalizing recording:', error));
    },
    getChunkSessionID() {
      if (!this.chunkSessionID) {
        this.chunkSessionID = Date.now().toString();
      }
      return this.chunkSessionID;
    },
    async getTranscription(url) {

      this.mediaRecorder = null;
      console.log('getTranscription')
      this.loading = true;
      fetch('https://tcst.me/transcribe/t', {
        method: 'POST',
        body: JSON.stringify({
          url: url,
          //prompt: 'Summarize key decisions and important points from the transcript'
          //prompt: 'list transcript as bullet points in html format'
          prompt: `Your summaries do not describe the context of the transcript - they only summarize the events in the text. 
          Respond with just the summary and do not include a preamble or introduction. 
          list any next steps. format summary in html.`
        }),
        headers: { 'Content-type': 'application/json' }
      }).then(response => response.json()).then(data => {
        this.loading = false;
        console.log('RESPONSE: ', data);
        if(data.response == 'error') {
          this.$parent.alertBox({
            message: '<p>No dialog detected in recording</p>',
            primary: 'Okay',
            fctn: 'errorTranscription',
            cancel: 'na',
          });
        } else {
          let msg = `<div class="videoTranscription">${this.convertText(data.response)}</div>`;
          //this.transcription = this.convertText(data.response);
          this.$parent.alertBox({
            message: msg,
            primary: 'Save',
            fctn: 'saveTranscription',
            evt: { text: data.text, response: data.response }
          });
        }
      });
    },
    async saveTranscription(e) {
      console.log('SAVED TRANSCRIPTION', e)
      try {
        const response1 = await fetch(`${this.$parent.__pt}savetext`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            text: e.response,// JSON.stringify(e.response),
            fileName: `${this.chunkSessionID.split('_')[0]}-video_transcription_brief.txt`,
            usid: this.usid,
            emailDirectory: this.emailDirectory,
          }),
        });

        const result1 = await response1.text();
        console.log(result1);

      } catch (err) {
        console.error('Error uploading text:', err);
      }

      try {
        const response2 = await fetch(`${this.$parent.__pt}savetext`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            text: e.text,// JSON.stringify(e.response),
            fileName: `${this.chunkSessionID.split('_')[0]}-video_transcription_full.txt`,
            usid: this.usid,
            emailDirectory: this.emailDirectory,
          }),
        });

        const result2 = await response2.text();
        console.log(result2);
        this.sendEmailLinks('video', `${this.chunkSessionID.split('_')[0]}-video_transcription_brief.txt`, `${this.chunkSessionID.split('_')[0]}-video_transcription_full.txt`)

      } catch (err) {
        console.error('Error uploading text:', err);
      }
    },
    cancelTranscription() {
      console.log('cancelTranscription')
      this.mediaRecorder = null;
      this.sendEmailLinks('video');
    },
    errorTranscription() {
      console.log('errorTranscription')
    },
    convertText(text) {
      let items = text.split('\n').map(item => item.replace('•', '').trim());
      let html = '<ul>';
      items.forEach(item => {
        if(item) {
          if (item.charAt(0) == '-') {
            item = item.replace('- ', '');
            html += `<ul><li>${item}</li></ul>`;
          } else {
            html += `<li>${item}</li>`;
          }
        }
      });
      html += '</ul>';
      return html;
    },
    async sendEmailLinks(fileType, textFile1 = null, textFile2 = null) {
      //this.$nextTick(() => {
      setTimeout(async () => {
        this.$parent.alertBox({
          message: `<p>Your files will be emailed to ${this.$parent.user.email}</p>`,
          primary: 'Okay',
          fctn: 'errorTranscription',
          cancel: 'na',
        });
      }, 1000);
      //});
      const email = await fetch(`${this.$parent.__pt}sendemaillink`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          fileType: fileType,
          recipient: this.$parent.user.email,
          fileName: fileType == 'image' ? `${this.stillSessionID}.png` : `${this.chunkSessionID}.webm`,
          textFile1: textFile1,
          textFile2: textFile2,
          usid: this.usid,
          emailDirectory: this.emailDirectory,
        })
      });
      const response = await email.json();
      console.log(response)
      if(response.message != 'email sent') {
        this.$parent.alertBox({
          message: `<p>There was a problem sending an email to to ${this.$parent.user.email}</p>`,
          primary: 'Okay',
          fctn: 'errorTranscription',
          cancel: 'na',
        });
      }
      /*
      if(response.message == 'email sent') {
        this.$parent.alertBox({
          message: `<p>Your files will be emailed to ${this.$parent.user.email}</p>`,
          primary: 'Okay',
          fctn: 'errorTranscription',
          cancel: 'na',
        });
      } else {
        this.$parent.alertBox({
          message: `<p>There was a problem sending an email to to ${this.$parent.user.email}</p>`,
          primary: 'Okay',
          fctn: 'errorTranscription',
          cancel: 'na',
        });
      }
      */
      /*
            const sendLinkOnly = await fetch(`${this.$parent.__pt}sendimagelink`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          //type: 'image',
          recipient: 'crathburn@truecast.io',
          fileName: `${this.stillSessionID}.png`,
          usid: this.usid,
          emailDirectory: this.emailDirectory,
        })
      });
      const resp = await sendLinkOnly.json();
      console.log(resp)
      */
    },
    useCamera(e) {
      this.shareCamera(e);
      console.log(e)
    },
    stillPhoto(e) {
      if (this.mediaRecorder) return;
      this.$parent.btnClick(e);
      this.stillSessionID = Date.now().toString();
      let cmd;
      if (this.mobile) {
        if (this.remoteStreamScreen) cmd = 'screen';
        if (this.remoteStreamCamera && !this.remoteStreamScreen) cmd = 'camera';
        if (!this.remoteStreamCamera && !this.remoteStreamScreen) cmd = 'local';
      }
      if (!this.mobile) {
        if (this.remoteStreamScreen) cmd = 'screen';
        if (this.remoteStreamCamera && !this.remoteStreamScreen) cmd = 'camera';
        //if (!this.remoteStreamCamera && !this.remoteStreamScreen) cmd = 'local';
      }
      if(cmd != 'local') {
        this.peerConnection.send({ cmd: cmd });
        //this.canvasSnapshotActive = true;
      } else {
        this.canvasSnapshotActive = true;
        this.$nextTick(() => {
          if (this.mobile) {
            if (this.remoteStreamScreen) this.streamToRecord = 'remoteStreamScreen';
            if (this.remoteStreamCamera && !this.remoteStreamScreen) this.streamToRecord = 'remoteStreamCamera';
            if (!this.remoteStreamCamera && !this.remoteStreamScreen) this.streamToRecord = 'localStreamCamera';
          }
          if (!this.mobile) {
            if (this.remoteStreamScreen) this.streamToRecord = 'remoteStreamScreen';
            if (this.remoteStreamCamera && !this.remoteStreamScreen) this.streamToRecord = 'remoteStreamCamera';
            if (!this.remoteStreamCamera && !this.remoteStreamScreen) this.streamToRecord = 'localStreamCamera';
          }
          const video = this.$refs[this.streamToRecord];
          const canv = this.$refs.canvasSnapshot;
          canv.width = video.videoWidth;
          canv.height = video.videoHeight;
          console.log('this.$refs.canvasSnapshot', this.$refs.canvasSnapshot, video.videoWidth, video.videoHeight)
          canv.getContext('2d').drawImage(video, 0, 0, canv.width, canv.height);
          const base64Image = canv.toDataURL('image/png');
          this.imageData = base64Image;
          //this.stillPhotoSave()
          this.stillPhotoProcess();
        });
      }
    },
    reassembleImage() {
      this.canvasSnapshotActive = true;
      this.imageData = this.receivedChunks.join('');
      this.receivedChunks = [];
      this.totalChunks = 0;
      console.log('Image reassembled successfully.', this.imageData);
      //this.stillPhotoSave();
      this.stillPhotoProcess();
    },
    stillPhotoSave() {
      //console.log('stillPhotoProcess', this.imageData)
      this.$parent.alertBox({
        message: '<p>Save image?</p>',
        primary: 'Save',
        fctn: 'savePhoto',
        cancel: 'cancelPhoto'
      });
    },
    stillPhotoSaveORIG(e) {
      console.log('stillPhotoProcess', e.target.files[0])
      this.selectedFile = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        //img.src = reader.result;
        this.imagePreview = reader.result;
        console.log(this.imagePreview)
      }
      reader.readAsDataURL(this.selectedFile);
      this.$parent.alertBox({
        message: '<p>Save image?</p>',
        primary: 'Save',
        fctn: 'stillPhotoProcess',
        cancel: 'cancelPhoto'
      });
    },
    async savePhoto() {
      console.log('savePhoto');
      this.imageData = null;
      this.loading = true;
      if (!this.photoSaved) {
        this.$watch(() => this.photoSaved, (newValue) => {
          console.log('this.photoSaved', this.photoSaved)
          if (newValue !== null && this.photoSaved) {
            this.savePhotoDone();
          }
        });
      } else {
        this.savePhotoDone()
      }
    },
    async savePhotoDone() {
      const signedUrlResponse = await fetch(`${this.$parent.__pt}generate-signed-url-image-read`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          fileName: `${this.stillSessionID}.png`,
          usid: this.usid,
          emailDirectory: this.emailDirectory,
        })
      });
      const data = await signedUrlResponse.json();
      if(data.url) {
        //this.$nextTick(() => {
          this.loading = false;
          this.$parent.alertBox({
            message: '<p>Interpret image?</p>',
            primary: 'Okay',
            fctn: 'getImageTranslation',
            cancel: 'cancelImageTranslation',
            evt: data.url
          });
        //});

      }
    },
    async savePhotoDoneORIG() {
      //fetch('http://localhost:9999/tclive_v5/saveimage', {
      fetch(`${this.$parent.__pt}saveimage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cmd: 'save', sessionId: this.stillSessionID, usid: this.usid, uid: this.$parent.user.uid, email: this.emailDirectory }),
      }).then(response => response.json()).then(data => {
        console.log(data.message);
        this.loading = false;
        this.$parent.alertBox({
          message: '<p>Interpret image?</p>',
          primary: 'Okay',
          fctn: 'getImageTranslation',
          cancel: 'cancelImageTranslation',
          evt: this.dURL
        });
      }).catch(error => console.error('Error saving still:', error));

    },
    cancelPhoto() {
      console.log('cancelPhoto')
      this.imageData = null;
      //fetch('http://localhost:9999/tclive_v5/saveimage', {
      fetch(`${this.$parent.__pt}deleteimage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sessionId: this.stillSessionID, usid: this.usid, email: this.emailDirectory }),
      }).then(response => response.json()).then(data => {
        console.log(data.message);
        this.canvasSnapshotActive = null;
        this.imageData = null;
        this.selectedFile = null;
        this.imagePreview = null;
        this.receivedChunks = [];
        this.totalChunks = 0;
      }).catch(error => console.error('Error saving recording:', error));
    },
    cancelPhotoOLD() {
      console.log('cancelPhoto')
      //fetch('http://localhost:9999/tclive_v5/saveimage', {
      fetch(`${this.$parent.__pt}saveimage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cmd: 'delete', sessionId: this.stillSessionID, usid: this.usid, uid: this.$parent.user.uid, email: this.emailDirectory }),
      }).then(response => response.json()).then(data => {
        console.log(data.message);
        this.canvasSnapshotActive = null;
        this.imageData = null;
        this.selectedFile = null;
        this.imagePreview = null;
        this.receivedChunks = [];
        this.totalChunks = 0;
      }).catch(error => console.error('Error saving recording:', error));
    },
    stillPhotoProcess() {
      this.loading = true;
      this.canvasSnapshotActive = null;
      this.imageDataToSave = this.imageData;
      //this.imageData = null;
      this.imagePreview = null;
      this.receivedChunks = [];
      this.totalChunks = 0;
      this.uploadImage();
    },
    async uploadImage() {
      this.photoSaved = false;
      this.stillPhotoSave();

      try {
        const abortController = new AbortController();
        const signal = abortController.signal;

        const signedUrlResponse = await fetch(`${this.$parent.__pt}generate-signed-url-image`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            fileName: `${this.stillSessionID}.png`,
            usid: this.usid,
            emailDirectory: this.emailDirectory,
          }),
          signal
        });

        if (signal.aborted) {
          console.log('Request for signed URL was aborted');
          return;
        }

        const { url } = await signedUrlResponse.json();
        console.log('signed url: ', url);

        const base64Data = this.imageDataToSave.split(',')[1];
        const binaryImageData = atob(base64Data);
        const byteArray = new Uint8Array(binaryImageData.length);

        for (let i = 0; i < binaryImageData.length; i++) {
          byteArray[i] = binaryImageData.charCodeAt(i);
        }

        const uploadResponse = await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'image/png',
          },
          body: byteArray,
          signal 
        });

        console.log('uploadResponse', uploadResponse);
        if (uploadResponse.ok) {
          console.log('Successfully uploaded image', uploadResponse);
          this.dURL = `https://storage.cloud.google.com/truecastpro_storage/${this.emailDirectory}/${this.usid}/${this.stillSessionID}.png`;
          console.log('IMAGE URL: ', this.dURL);
          this.photoSaved = true;
          this.loading = false;
          this.imageDataToSave = null;
        } else {
          console.error('Failed to upload image');
        }
      } catch (err) {
        if (err.name === 'AbortError') {
          console.log('Image upload was aborted');
        } else {
          console.error('Error during image upload:', err);
        }
      }
    },
    getImageTranslation(url) {
      console.log('getImageTranslation',url);

      this.loading = true;
      /*
      let prompt = `This tool will extract key details from the provided images, including the brand, model number, specifications, and any other relevant information.
      It will interpret text within images to accurately identify model details, ensuring that the extracted information is concise and relevant.
      Responses will be technical and precise, focusing on delivering exact information. 
      If applicable, it will provide identifying numbers, such as serial numbers and model numbers (in full text), gas or electric type, capacity, and device type. 
      If the device is a furnace, it will specify the required filter size.
      The output will be in a JSON format with keys in camelCase. 
      It will aim to provide a direct link to any user manuals available. 
      If a manual is not available, it will mention this clearly.`;
      */
      let prompt = `This tool will extract all details from the provided images, including the brand, model number, specifications, and any other relevant information.
      It will interpret text within images to accurately identify model details, ensuring that the extracted information is concise and relevant.
      Responses will be technical and precise, focusing on delivering exact information. 
      If applicable, it will provide identifying numbers, such as serial numbers and model numbers (in full text), gas or electric type, capacity, and device type.
      Always list device type first.
      If the device is a furnace, it must specify the required filter size.
      The output will be in a JSON format and not nested.
      It will aim to provide a direct link to any user manuals available. 
      If a manual is not available, it will mention this clearly.`;

      fetch('https://tcst.me/openai/t', {
        method: 'POST',
        body: JSON.stringify({
          url: url,
          prompt: prompt,
        }),
        headers: { 'Content-type': 'application/json' }
      }).then(response => response.json()).then(data => {
        this.loading = false;
        let obj = JSON.parse(data.response);
        console.log('RESPONSE: ', obj);
        if(obj) {
          //let obj = data.choices[0].message.content;
          let text = '';
          let key = '';
          let keys = Object.keys(obj);
          let finalOutput = '<div class="imageTranslation">';
          for (let i = 0; i < keys.length; i++) {
            text = JSON.stringify(obj[keys[i]]).replace(/['"]+/g, '');
            if (keys[i] == 'modelNumber') {
              console.log(`MODEL NUMBER: ${obj[keys[i]]}`);
              finalOutput += `<div><b>MODEL NUMBER:</b> ${text}</div>`;
              //modelInfo(obj[keys[i]]);
            } else if (keys[i] == 'serialNumber') {
              console.log(`SERIAL NUMBER: ${obj[keys[i]]}`);
              finalOutput += `<div><b>SERIAL NUMBER:</b> ${text}</div>`;
            } else {
              console.log(keys[i], ':', obj[keys[i]]);
              key = JSON.stringify(keys[i]).replace(/[_]+/g, ' ');
              key = key.replace(/['"]+/g, '');
              finalOutput += `<div><b><span style="text-transform: uppercase;">${key}</span>:</b> <code>${text}</code></div>`;
            }
          }
          finalOutput += '</div>';
          this.$parent.alertBox({
            message: finalOutput,
            primary: 'Save',
            fctn: 'saveImageTranslation',
            evt: obj
          });
        } else {
          this.$parent.alertBox({
            message: '<p>No useful data detected in photo</p>',
            primary: 'Okay',
            fctn: 'errorTranscription',
            cancel: 'na',
          });
        }
      });
    },
    async saveImageTranslation(e) {
      console.log(JSON.stringify(e));
      
      try {
        const response = await fetch(`${this.$parent.__pt}savetext`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            text: JSON.stringify(e),
            fileName: `${this.stillSessionID}-image_translation.json`,
            usid: this.usid,
            emailDirectory: this.emailDirectory,
          }),
        });

        const result = await response.text();
        console.log(result);
        this.sendEmailLinks('image', `${this.stillSessionID}-image_translation.json`);
      } catch (err) {
        console.error('Error uploading text:', err);
      }
    },
    cancelImageTranslation() {
      this.dURL = null;
      console.log('need to send email with image only')
      this.sendEmailLinks('image')
    },  
    checkCameraMicrophone() {
      if(this.guestList === null || (this.guestList && Object.keys(this.guestList).length == 0)) {
        try {
          navigator.permissions.query({ name: 'microphone' }).then(res => {
            console.log(res)
            if (res.state == "denied") {
              this.$parent.alertBox({
                message: '<p>Please update your browser settings to allow for microphone share.</p>',
                primary: 'Okay',
                fctn: 'cancelAlert',
                cancel: 'na',
                evt: null
              });
            }
          });
        } catch (e) {
          console.log(e)
        }
      }
      try {
        navigator.permissions.query({ name: 'camera' }).then(res => {
          console.log(res)
          if (res.state == "denied") {
            this.$parent.alertBox({
              message: '<p>Please update your browser settings to allow for camera share.</p>',
              primary: 'Okay',
              fctn: 'cancelAlert',
              cancel: 'na',
              evt: null
            });
          }
        });
      } catch (e) {
        console.log(e)
      }
    },
    cancelAlert() {
      this.$parent.alertStatus = {};
      this.$parent.tcMenuStatus = false;
    },
    async createPID() {
      this.pid = `${this.usid}${this.uid}`;//-${Date.now()}`;
    },
    resetMe() {
      //console.log('resetMe');
      clearTimeout(this.resetTimer);
      this.resetTimer = setTimeout(async () => {
        if (!this.sendInvitesPanel && this.guestList && Object.keys(this.guestList).length == 0 && !this.localStreamCamera && this.$parent.currentScreen == 'AdminDashboard') {
          location.reload();
        }
        this.resetMe();
      }, 360000);

    },
    dashboard() {
      console.log('DASHBOARD', this.$parent.userData.admin);
      this.$parent.currentScreen = 'Dashboard';
      this.$parent.tcMenuStatus = false;
    },
    async init() {
      this.uid = this.$parent.user.uid;
      this.emailDirectory = this.$parent.user.email.replace(/[@.]/g, '_');
      if (!this.$parent.usid) {
        //alert('not set')
        let {usid, sc} = await this.setupSession();
        //alert('setting to: '+usid)
        this.usid = usid;
        this.shortCode = sc;
      } else {
        this.usid = this.$parent.usid;
        console.log('this.usid > ', this.usid);
        await get(ref(__db, `${__as}/${this.usid}`)).then((snapshot) => {
          if (snapshot.exists()) {
            this.shortCode = snapshot.val().sc;
            if (snapshot.val().camera == 'share') {
              console.log('unsubscribeGetMyPath', snapshot.val());
              this.shareCamera();
            }
          }
        }).catch((error) => {
          console.error(error);
        });
        //console.log(this.usid, this.shortCode)
      }

      //const emptyStream = await this.createEmptyStream();
      //const mediaStream = await new MediaStream([emptyStream]);
      this.localEmptyStreamScreen = await new MediaStream([await this.createEmptyStream()]);
      this.localEmptyStreamCamera = await new MediaStream([await this.createEmptyStream()]);

      this.pid = `${this.usid}${this.uid}`;//-${Date.now()}`;
      //this.createPID();
      this.$parent.pid = this.pid;
      this.usidPath = ref(__db, `${__as}/${this.usid}`);
      this.$parent.usidPath = this.usidPath;
      this.myPath = ref(__db, `${__as}/${this.usid}/admin/`);
      this.$parent.myPath = this.myPath;
      this.guestsPath = ref(__db, `${__as}/${this.usid}/guests/`);
      this.$parent.guestsPath = this.guestsPath;

      this.$parent.setDisconnect();
      
      try { 
        const docSnap = await getDoc(doc(__fs, 'live_company', this.$parent.userData.company.toLowerCase())); 
        if (docSnap.exists()) { 
          this.baseData = docSnap.data();
          this.$parent.baseData = this.baseData;
          console.log('tempBaseData > ', this.baseData);
        } else { 
          console.log('Document does not exist'); 
        } 
      } catch(error) { 
        console.log('ERROR',error) 
      }      
      //onDisconnect(this.myPath).remove();

      await this.createPeer();
      update(this.usidPath, {
        admin: {
          first: this.$parent.userData.first,
          last: this.$parent.userData.last,
          company: this.$parent.userData.company,
          visible: this.appVisible,
          mobile: this.$parent.mobile,
          pid: false,
          connected: true,
          ts: Math.floor(Date.now() / 1000),
          focused: this.appFocused,
          data: this.baseData,
        }
      }).then(() => {
        //update(this.myPath, { pid: false, connected: true, ts: Math.floor(Date.now() / 1000) } ).then(() => {
        console.log('admin updated');
      }).catch((error) => {
        console.log(error);
      });
      this.$parent.logMe('logged_in', { usid: this.usid, uid: this.$parent.user.uid, email: this.$parent.user.email, company: this.$parent.userData.company, ts: Math.floor(Date.now() / 1000) }, this.usid);

      this.unsubscribeValue = onValue(this.myPath, (snap) => {
        console.log('unsubscribeValue:onValue', snap, snap.val())
        this.resetMe();
        if (snap.val() && snap.val().logout && snap.val().logout != this.__tcstTS) this.$parent.logOut3();
      });
      
      this.unsubscribeGuestsValue = onValue(this.guestsPath, async (snap) => {
        //console.log('onValue::', snap.key, snap.val())
        /*
        console.log('this.peerConnection', this.peerConnection)
        if (this.peerConnection) {
          this.peerConnection.send('THIS IS A TEST')
        }
        */
        //if(this.peerConnection) this.configureConnection(this.peerConnection);
        if (this.guestList && Object.keys(this.guestList).length > 0 && snap.val() === null) {
          //this.$parent.endSession();
        }
        if (snap.val() && snap.key == 'guests' && this.guestList && Object.keys(this.guestList).length > 0 && Object.values(snap.val())[0].pid && !Object.values(snap.val())[0].refresh) {
          //console.log('Object.values(snap.val())[0].pid',Object.values(snap.val()))
          //this.reconnectPeers(Object.values(snap.val())[0].pid);
        }

        this.guestList = snap.val() ? snap.val() : {};
        if (snap.val() && snap.key == 'guests' && this.guestList && Object.keys(this.guestList).length > 0 && Object.values(snap.val())[0].refresh) {
          //console.log('Object.values(snap.val())[0].pid',Object.values(snap.val()))
          this.reconnectPeers(Object.values(snap.val())[0].pid);
          update(ref(__db, `${__as}/${this.usid}/guests/${Object.keys(this.guestList)[0]}`), {
              refresh: null
          }).then(() => {
            console.log('guest updated');
          }).catch((error) => {
            console.log(error);
          });
        }
        if (!this.firstGuest && snap.val()) this.firstGuest = Object.values(this.guestList)[0].nickname;
        if (snap.val() && Object.values(snap.val())[0].screen && Object.values(snap.val())[0].screen == 'share') {
          this.remoteShareScreenStatus = true;
        }
        if (snap.val() && Object.values(snap.val())[0].screen && Object.values(snap.val())[0].screen == 'stop') {
          this.remoteShareScreenStatus = false;
          this.remoteStreamScreen = null;
          if (this.remoteScreenCall) this.remoteScreenCall.close();
        }
        if (snap.val() && !Object.values(snap.val())[0].screen) {
          this.remoteShareScreenStatus = false;
          this.remoteStreamScreen = null;
          if (this.remoteScreenCall) this.remoteScreenCall.close();
        }

        if (snap.val() && Object.values(snap.val())[0].camera && Object.values(snap.val())[0].camera == 'share') {
          this.remoteShareCameraStatus = true;
        }
        if (snap.val() && Object.values(snap.val())[0].camera && Object.values(snap.val())[0].camera == 'stop') {
          this.remoteShareCameraStatus = false;
          this.remoteStreamCamera = null;
          if (this.remoteCameraCall) this.remoteCameraCall.close();
        }
        if (snap.val() && !Object.values(snap.val())[0].camera) {
          this.remoteShareCameraStatus = false;
          this.remoteStreamCamera = null;
          if (this.remoteCameraCall) this.remoteCameraCall.close();
        }
        if (snap.val() && Object.values(snap.val())[0].endedsession) {
          console.log(Object.keys(snap.val())[0])
          remove(ref(__db, `${__as}/${this.usid}/guests/${Object.keys(snap.val())[0]}`)).then(() => {
            //update(this.myPath, { pid: false, connected: true, ts: Math.floor(Date.now() / 1000) } ).then(() => {
            console.log('guest deleted')
            this.$parent.endSession();
          }).catch((error) => {
            console.log(error);
          });
        }

        if (snap.val() && Object.values(snap.val())[0].pid) {
          this.statusMessage = null;
          this.spid = Object.values(snap.val())[0].pid;
        }
      });

      this.unsubscribeGuestsAdded = onChildAdded(this.guestsPath, (snap) => {
        //console.log('onChildAdded', snap, snap.val())
        //this.$parent.tcMenuStatus = true;
        //console.log(snap.val().nickname);
        console.log('ALERT BOX!! Guest ADDED', snap.val(), this.peerIds.length, Object.values(snap.val())[0].pid)
        //logEvent(__an, 'share_start', { ts: Math.floor(Date.now() / 1000), usid: this.usid, uid: this.uid });
        this.$parent.logMe('share_start', { usid: this.usid, uid: this.uid, email: this.$parent.user.email, ts: Math.floor(Date.now() / 1000) })
        //this.$parent.alertBox({message: `${snap.val().nickname} joined the session.`, timer: true });
      });

      this.unsubscribeChildAdded = onChildAdded(this.usidPath, async (data) => {
        //console.log('==> onChildAdded', data.key, data.val());
        if (data.key == 'sharing') {
          if (data.val().sharing && data.val().spid && data.val().spid != this.pid) {
            this.$parent.tcMenuStatus = false;
            this.guestShare = data.val();
            this.spid = data.val().spid;
            this.someoneSharing = true;
            //await this.createPeer();
            this.someoneIsSharing();
          }
        }
      });

      this.unsubscribeChildChanged = onChildChanged(this.usidPath, async (data) => {
        //console.log('==> onChildChanged', data.key, data.val());
        if (data.key == 'sharing') {
          if (data.val().sharing && data.val().spid && data.val().spid != this.pid) {
            this.$parent.tcMenuStatus = false;
            this.guestShare = data.val();
            this.spid = data.val().spid;
            this.someoneSharing = true;
            //await this.createPeer();
            this.someoneIsSharing();
          } else {
            //if (this.peer) this.peer.destroy();
            this.guestShare = null;
            this.remoteStream = null;
            this.someoneSharing = false;
          }
        }
      });

      this.unsubscribeChildRemoved = onChildRemoved(this.usidPath, async (data) => {
        //console.log('==> onChildRemoved', data.key, data.val());
        if (data.key == 'sharing') {
          if (data.val().sharing && data.val().spid && data.val().spid != this.pid) {
            this.$parent.tcMenuStatus = false;
            //if (this.peer) this.peer.destroy();
            this.guestShare = null;
            this.spid = null;
            this.remoteStream = null;
            this.someoneSharing = false;
          }
        }
      });

      this.unsubscribeGuestsChildChanged = onChildChanged(this.guestsPath, async (data) => {
        //console.log('==> onChildChanged', data.key, data.val());
        if (data.key == this.currentGuestRequest) {
          if (data.val()['sharing']) {
            //console.log(data.val())
            //console.log('>>>>>> done sharing');
          } else {
            console.log(this.currentGuestRequest, 'stopped sharing')
            this.guestShare = null;
            this.currentGuestRequest = null;
            this.requestCallerShare = null;
            this.$parent.tcMenuStatus = false;
          }
        }
        //if (this.peerIds.length == 0 && Object.values(snap.val())[0].pid) this.reconnectPeers(Object.values(snap.val())[0].pid);

        if (data.val().pid && !data.val().refresh && this.peerIds.length == 0) {
          console.log('XXXXXXXXXXXXXXxxxxXXXXxxxXXXXXXxxxxXXXX', data.val().pid)
          this.reconnectPeers(data.val().pid)
        }
      });

    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setFocus();

      if(this.helpNotes) {
        setTimeout(() => {
          this.helpNotesScreen1 = false;
        }, 20000);
      }

      console.log('this.$parent.userData >>>>> ', this.$parent.userData)
    });

    setTimeout(() => {
      this.loaded = true;
    }, 1000);

    this.init();
    this.resetMe();

    /*
    navigator.permissions.query({ name: 'screen-wake-lock' }).then(res => {
      //https://developer.mozilla.org/en-US/docs/Web/API/Screen_Wake_Lock_API
      console.log(res)
      if (res.state == "granted") {
        // has permission
        this.wakeLock = null;
        try {
          navigator.wakeLock.request('screen').then(lock => {
            this.wakeLock = lock;
            console.log("Wake Lock is active!");
          });
        } catch (err) {
          console.log(err)
        }
      }
    });
    */

    this.checkCameraMicrophone();

  },
  beforeMount() {

  },
  beforeCreate() {
    this.__tcstTS = Date.now();
    localStorage.setItem('__tcstTS', this.__tcstTS);
  },
  created() {
    if (document.querySelector('.primaryFooter')) document.querySelector('.primaryFooter').remove();
  },
  async beforeDestroy() {
    //window.removeEventListener('beforeunload', this.handleBeforeUnload);

    if (this.unsubscribeValue) this.unsubscribeValue();
    if (this.unsubscribeChildAdded) this.unsubscribeChildAdded();
    if (this.unsubscribeChildChanged) this.unsubscribeChildChanged();
    if (this.unsubscribeChildRemoved) this.unsubscribeChildRemoved();
    if (this.unsubscribeGuestsChildChanged) this.unsubscribeGuestsChildChanged();

  },
  destroyed() {
  },
  directives: {},
  computed: {},
  watch: {
    loading() {
      this.$parent.loading = this.loading;
    },
    guestList() {
      this.$parent.guestList = this.guestList;
    },
    shortCode(val) {
      this.$parent.shortCode = val;
    },
    remoteStream(val) {
      let s = val ? true : null;
      this.$parent.remoteStream = s;
    },
    '$parent.tcMenuStatus'(val) {
      this.tcMenuStatus = val;
    },
    '$parent.appVisible'() {
      return;
    },
    '$parent.appFocused'(val) {
      this.appFocused = val;
      update(this.myPath, { focused: this.appFocused });
      return;
    },
    '$parent.helpNotes'(val) {
      this.helpNotes = val;
      this.helpNotesScreen1 = val;
      this.helpNotesScreen2 = val;
      this.helpNotesScreen3 = val;
      console.log('helpNotes: ', this.helpNotes, this.sendInvitesPanel);
      updateDoc(this.$parent.userDataRef, {
        help: this.helpNotes,
      });
      return;
    } 

  },
}
</script>

<style scoped>

#admindashboard {
  position: absolute;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}


.contentRow.liveshareid {
  text-align: left;
  float: left;
  margin-left: 15px;
}

.contentRow.liveshareid .inputID {
  text-align: left;
  display: block;
  padding: 0 0 0 12px;
}

.contentRow.liveshareid button {
  display: block;
  margin: 15px 0 0 0;
}


.inputID {
  border: 1px solid rgba(0, 0, 0, .2);
  background-color: rgba(0, 0, 0, 0);
  color: rgb(0, 0, 0);
  box-sizing: border-box;
  border-radius: 25px;
  width: 170px;
  height: 50px;
  outline: none;
  font-family: 'bookType', sans-serif;
  font-size: 1.7em;
  text-transform: lowercase;
  text-align: center;
  padding: 5px;
}

.inputID::placeholder {
  color: rgba(154, 189, 219, .85);
  opacity: 1;
  font-size: 1.1em;
  text-transform: capitalize;
}

.inputID:placeholder-shown {
  font-family: 'bookType', sans-serif;
  font-size: 1.1em;
  text-transform: capitalize;
}

.connectedWithGuests {
  font-size: 20px;
  line-height: 28px;
  pointer-events: none;
  margin: 15px 0;
}

.mobile .buttonBase.recordEnabled {
  left: 5px!important;
  transform: translateX(0)!important;
  width: 140px!important;
}
.remoteStreamContainer {
  padding: 0;
  margin: 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
}

.remoteStreamContainer .remoteStream {
  position: absolute;
  left: 50%;
  top: var(--tc-share-height);
  width: 100%;
  height: calc(100% - var(--tc-share-height));
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}


.remoteStreamContainer .nowsharing {
  position: absolute;
  left: 0;
  top: 0;
  height: var(--tc-share-height);
  text-align: center;
  background-color: var(--tc-blue);
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: var(--tc-share-height);
  width: 100%;
  z-index: 10;
  box-shadow: 0 5px 8px rgba(0, 0, 0, .3);
  text-transform: uppercase;
}

.remoteStreamContainer .sharePopupControls {
  position: absolute;
  left: 40px;
  bottom: 20px;
  z-index: 10;
}

.remoteStreamContainer .sharePopupControls button {
  box-shadow: 0 4px 10px rgba(0, 0, 0, .6);
}

/*
.localStream {
  width: 400px;
  height: auto;
  background-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}
*/

.mobile .localStream {
  position: absolute;
  /*display: flex;*/
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}

.guestList {
  position: fixed;
  right: 0;
  top: 260px;
  width: 225px;
  transition: all 200ms ease-in-out;
  transform: translateX(270px);
  z-index: 10;
  text-align: left;
}

.mobile .guestList {
  position: abolute;
  left: 45px;
  top: 210px;
  width: calc(100% - 40px);
  transition: all 200ms ease-in-out;
  transform: translateX(0);
  z-index: 10;
  text-align: left;
  opacity: 0;
}

.guestList.active {
  transform: translateX(0);
}

.mobile .guestList.active {
  transform: translateX(0);
  opacity: 1;
}


.guestList .guestListHeader {
  text-align: left;
  font-size: 20px;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
  margin-bottom: 7px;
}

.guestList button {
  position: relative;
  margin: 20px 0 0 0;
}

.guestRoot {
  position: relative;
  color: rgb(255, 255, 255, 1);
  width: 225px;
}

.guestBase {
  display: flex;
  width: 225px;
  cursor: pointer;
}

.guestBase.disabled {
  opacity: .3;
  pointer-events: none;
}

.guestBase .guest {
  position: relative;
  width: 115px;
  height: 36px;
  line-height: 36px;
  font-size: 20px;
  pointer-events: none;
  text-align: left;
}


.guestBase.mobile .guest {
  opacity: .6;
}



.guestBase .status {
  display: flex;
  pointer-events: none;
  width: 90px;
  height: 20px;
  margin: 9px 0 0 0;
}

.guestBase .status > div {
  display: flex;
  pointer-events: none;
  width: 30px;
  height: 20px;
  box-sizing: border-box;
  /* border: 1px solid rgb(0,0,0); */
  margin: 0 0 0 3px;
}


.controls {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
}

.primaryButton {
  font-size: 16px;
  line-height: 32px;
  min-width: 90px;
  white-space: nowrap;
  height: 32px;
  border-radius: 16px;
  padding: 0 20px;
  margin: 5px;
  border: none;
}

.sendInvitesPanel {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 1);
  pointer-events: all;
}

.sendInvitesPanel #endSessionWindow {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  border: 2px solid var(--tc-blue);
  border-radius: 6px;
  box-shadow: 8px 8px 10px rgba(0, 0, 0, .25);
  overflow: hidden;
}

.sendInvitesPanel #endSessionWindow {
  width: 70%;
  min-width: 300px;
  max-width: 450px;
}

.mobile .sendInvitesPanel #endSessionWindow {
  width: 94%;
  min-width: 94%;
}

.sendInvitesPanel .title {
  background-color: var(--tc-blue);
  color: rgb(255, 255, 255);
  padding: 10px 15px;
  font-size: 1em;
  border-bottom: 1px solid var(--tc-blue);
  text-align: center;
  font-family: 'bookType';
}

.sendInvitesPanel .message {
  color: var(--tc-grey);
  padding: 30px 20px 20px 15px;
  font-size: 1em;
  text-align: center;

}

.sendInvitesPanel .buttons {
  position: relative;
  text-align: center;
  padding: 10px 30px 20px 30px;
}

.sendInvitesPanel .buttons button {
  font-family: 'bookType';
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  font-size: 1em;
  line-height: 38px;
  min-width: 140px;
  white-space: nowrap;
  height: 40px;
  border-radius: 30px;
  margin: 5px 10px;
  outline: none;
  background-color: var(--tc-blue);
  border: 2px solid var(--tc-blue);
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.sendInvitesPanel div.selected,
.sendInvitesPanel .buttons button.selected {
  opacity: .5;
  pointer-events: none;
}

.sendInvitesPanel .invitesToSend {
  position: relative;
  padding: 35px 10px 0 10px;

}
.mobile .sendInvitesPanel .invitesToSend {
  position: relative;
  padding: 28px 10px 0 10px;

}

.sendInvitesPanel .invitesToSend .invitesBase {
  position: relative;
  display: inline-table;
  width: 100%;
  border: 2px solid var(--tc-blue);
  border-radius: 25px;
  height: 50px;
  box-sizing: border-box;
}

.mobile .sendInvitesPanel .invitesToSend .invitesBase {
  width: 80%;
  height: 45px;
}

.sendInvitesPanel .invitesToSend input {
  color: var(--tc-blue);
  border: none;
  line-height: 50px;
  height: 100%;
  font-size: 1.5em;
  background-color: rgba(255,255,255,1);
  padding: 5px 20px;
  font-family: 'bookType';
  text-transform: lowercase;
  display: table-cell;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  border-radius: 25px;

}
.mobile .sendInvitesPanel .invitesToSend input {
  line-height: 455px;
  height: 100%;
  font-size: 1.3em;
  padding: 5px 10px 0 10px;
}

.sendInvitesPanel .invitesToSend input::placeholder {
  color: rgba(154, 189, 219, .85);
  opacity: 1;
  font-size: 1em;
  text-transform: capitalize;
  text-align: center;
}
.mobile .sendInvitesPanel .invitesToSend input::placeholder {
  opacity: 1;
  font-size: 1em;
}

.sendInvitesPanel .invitesToSend input:placeholder-shown {
  font-family: 'bookType', sans-serif;
  font-size: 1.2em;
  text-transform: capitalize;
  text-align: center;
}
.mobile .sendInvitesPanel .invitesToSend input:placeholder-shown {
  font-size: 1.2em;
  opacity: 1;
}

.sendInvitesPanel .invitesToSend .status {
  position: relative;
  display: table-cell;
  width: 8%;
}

.sendInvitesPanel .invitesToSend .status .statusIndicator {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* border: 1px solid rgba(0,0,0,.5); */
  border-radius: 8px;
  width: 16px;
  height: 16px;
}

.sendInvitesPanel .invitesToSend .status .statusIndicator.waiting {
  background-color: var(--tc-red);
}

.sendInvitesPanel .invitesToSend .status .statusIndicator.active {
  background-color: var(--tc-green);
}


.talk-bubble {
  position: absolute;
  background-color: var(--tc-orange);
  border: 1px solid var(--tc-orange);
  padding: 20px 20px;
  width: 150px;
  text-align: left;
  font-size: 16px;
  font-family: 'mediumType';
  color: rgb(255, 255, 255);
  line-height: 140%;
  z-index: 100;
  box-shadow: 4px 4px 8px rgba(0,0,0,.2);
}
.talk-bubble::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}

.talk-bubble.right {
  left: -230px;
  bottom: 20px;
  border-radius: 10px 10px 0 10px;
}
.talk-bubble.right::after {
  content: "";
  position: absolute;
  right: -30px;
  bottom: -1px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 0 solid transparent;
  border-left: 30px solid var(--tc-orange);
  border-left-color: inherit;
}
.talk-bubble.right.screen {
  left: -45px;
  bottom: 323px;
  border-radius: 10px 10px 0 10px;
}
.talk-bubble.right.screen::after {
  content: "";
  position: absolute;
  bottom: -20px;
  right: -1px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 0 solid transparent;
  border-top: 20px solid var(--tc-orange);
  border-top-color: inherit;
}

.talk-bubble.left {
  left: 233px;
  bottom: 20px;
  border-radius: 10px 10px 10px 0;
}
.talk-bubble.left::after {
  content: "";
  position: absolute;
  left: -30px;
  bottom: -1px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 30px solid var(--tc-orange);
  border-right-color: inherit;
}
.talk-bubble .close {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 15px;
  height: 15px;
}
.talk-bubble .close svg {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}


.mobile .talk-bubble {
  position: absolute;
  background-color: var(--tc-orange);
  border: 1px solid var(--tc-orange);
  padding: 15px 15px;
  width: 115px;
  text-align: left;
  font-family: 'mediumType';
  color: rgb(255, 255, 255);
  font-size: 14px;
  line-height: 130%;
  z-index: 100;
  transition: all 300ms ease-in-out;
}

.mobile .talk-bubble.right {
  left: -45px;
  bottom: 70px;
  border-radius: 10px 10px 0 10px;
}

.mobile .talk-bubble.right::after {
  content: "";
  position: absolute;
  bottom: -20px;
  right: -1px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 0 solid transparent;
  border-top: 20px solid var(--tc-orange);
  border-top-color: inherit;
}

.mobile .talk-bubble.left {
  left: 212px;
  bottom: -180px;
  border-radius: 0 10px 10px 10px;
}

.mobile .talk-bubble.left::after {
  content: "";
  position: absolute;
  top: -30px;
  left: -1px;
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-left: 0 solid transparent;
  border-bottom: 20px solid var(--tc-orange);
  border-bottom-color: inherit;
}

.mobile .talk-bubble .close {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 15px;
  height: 15px;
}

.toolBar {
  position: absolute;
  /*
  left: 50%;
  transform: translateX(-50%);
  */
  left: 145px;
  top: 20px;
  width: 120px;
  pointer-events: all;
}
.remotePreview.camera .toolBar {
  top: auto;
  left: 50%;
  bottom: 55px;
  transform: translateX(-50%);
}

.remoteStreamScreen .toolBar {
    top: auto;
      left: 50%;
      bottom: 55px;
      transform: translateX(-50%);
}
.recordBTN {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid rgb(255, 255, 255);
}

.recordBTN:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgb(255, 0, 0);
}
.recordBTN.active:after {
  content: "";
  position: absolute;
  left: 12.5px;
  top: 12.5px;
  width: 16px;
  height: 16px;
  border-radius: 25%;
  background-color: rgb(255, 0, 0);
  animation: pulse 2000ms infinite;
  opacity: 1;
}
.stillBTN {
  position: absolute;
  left: 55px;
  top: 3px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 3px solid rgb(255, 255, 255);
}

.stillBTN:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
}

.stillBTN.clicked {
  transform: scale(.8);
}
.mobile .stillBTN {

}
.mobile .stillBTNInput {
  display: none;
  pointer-events: none;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
@keyframes pulseOpacity {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes pulseOpacityOutline {
  0% {
    outline: 2px solid rgba(255, 0, 0, 0);
  }

  40% {
    outline: 2px solid rgba(255, 0, 0, 0.6);
  }

  60% {
    outline: 2px solid rgba(255, 0, 0, 0.6);  
  }

  100% {
    outline: 2px solid rgba(255, 0, 0, 0);  
  }
}

.recordingFrame {
  outline: 2px solid rgba(255,0,0,0);
  outline-offset: -10px;
  animation: pulseOpacityOutline 3000ms infinite;
}
.recording {
  position: absolute;  
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border: 4px solid red;
  box-sizing: border-box;
  pointer-events: none;
  animation: pulseOpacity 3000ms infinite;
  opacity: 0;
  transition: all 300ms ease-in-out;
  pointer-events: none;
}
.mobile .transcription {
  position: absolute;
  background-color: rgba(255,255,255,1);
  width: calc(90% - 20px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 10px 10px 0;
  text-align: left;
  box-shadow: 0 0 13px rgba(0, 0, 0, .4);
}
.mobile .transcription ul {

}
.mobile .transcription ul li {

}

.progress-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
  transform: translate(-50%,-50%);
}

.progress {
  width: 100%;
  height: 100%;
  background: conic-gradient(rgba(255, 255, 255, .5) calc(var(--progress) * 1%), rgba(57, 127, 190, 0) 0%);
  border-radius: 50%;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 300ms ease-in-out;
}
.canvasSnapshotContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%; height: auto;
}
.canvasSnapshot {
  position: relative;
  width: 100%;
  height: auto;
  object-fit: contain;
  display: flex;
}
.imagePreviewContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}
.imagePreview {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
