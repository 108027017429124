<template>
  <div id="reset">
    <div class="loginWindow contentHoriz">
      <div class="logo">
        <img ref="primary_logo" alt="Truecast" src="@/assets/truecast_logo_full.svg" width="100%" height="auto">
      </div>

      <form>
        <div class="contentRow" style="margin-top: 40px;">
          <input v-model="myEmail" @keydown.enter="signIn" @keyup="isOkay($event)" @keydown="isOkay($event)"
            data-btnparent="nicknameBtn" id="myEmail" class="inputFields" type="text" value="" autocorrect="off"
            autocapitalize="off" size="15" autocomplete="off" placeholder="USERNAME">
        </div>
        <div class="contentRow">
          <button id="cancelLogInBtn" class="primaryButton button" @click="cancelSignIn">
            Cancel
          </button>

          <button id="logInBtn" class="primaryButton button" type="submit" @click="resetPassword"
            :class="[logInBtn ? 'pass' : 'fail']">
            Reset
          </button>
        </div>
      </form>
      <div v-if="$parent.mobile">
        <h5 class="introText">
          Enter email address to send instructions<br>
          on how to reset your password.
        </h5>
      </div>
      <div v-else>
        <h4 class="introText">
          Enter email address to send instructions<br>
          on how to reset your password.
        </h4>
      </div>
    </div>

  </div>
</template>

<script>
import { __au} from '@/scripts/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';

export default {
  name: 'reset',
  props: {},
  data() {
    return {
      myEmail: '',
      myPassword: '',
      logInBtn: false,
    }
  },
  components: {},
  methods: {
    cancelSignIn(evt) {
      evt.preventDefault();
      this.$parent.btnClick(evt);
      let ___buttonTimer = setTimeout(() => {
        clearTimeout(___buttonTimer);
        location.reload();
      }, 100);
    },
    async resetPassword(evt) {
      if(this.myEmail < 8) return;
      this.$parent.btnClick(evt);
      let ___buttonTimer = setTimeout(() => {
        clearTimeout(___buttonTimer);
        sendPasswordResetEmail(__au, this.myEmail).then(() => {
          console.log("Password reset email sent!");
          // Email sent.
        }).catch((error) => {
          console.error("Error sending password reset email: ", error);
          // An error happened.
        });
      }, 100);
    },
    isOkay(evt) {
      if (evt !== undefined) {
        if (this.myEmail.length > 8) {
          this.logInBtn = true;
        } else {
          this.logInBtn = false;
        }
      }
    },
    setFocus() {
      if (document.querySelector('#myEmail')) document.querySelector('#myEmail').focus();
      this.$nextTick(() => {
        if (document.querySelector('#myEmail')) document.querySelector('#myEmail').focus();
      });
      let ___tempTimer = setTimeout(() => {
        clearTimeout(___tempTimer);
        if (document.querySelector('#myEmail')) document.querySelector('#myEmail').focus();
      }, 1000);
    },
    async init() {

    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setFocus();
    });
    this.init();

    //this.resetPassword();
  },
  beforeMount() {

  },
  beforeCreate() {

  },
  created() {

  },
  beforeDestroy() {

  },
  destroyed() {

  },
  directives: {},
  computed: {},
  watch: {},
}
</script>

<style scoped>
.loginWindow {
  top: 25%;
}
.logo {
  position: relative;
  top: 0;
  height: 145px;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
}

.logo img {
  width: 100%;
  height: 100%;
}

.inputFields {
  border: 2px solid var(--tc-blue);
  color: var(--tc-blue);
  box-sizing: border-box;
  border-radius: 20px;
  width: 300px;
  height: 40px;
  outline: none;
  font-size: 1.2em;
  text-transform: lowercase;
  text-align: center;
}

.inputFields::placeholder {
  color: var(--tc-bordergrey);
  opacity: 1;
  font-size: 1em;
}

.inputFields:placeholder-shown {
  font-size: 1em;
}

.primaryButton {
  position: relative;
  background-color: var(--tc-blue);
  font-family: 'bookType';
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  font-size: .8em;
  line-height: 38px;
  min-width: 140px;
  height: 40px;
  border-radius: 20px !important;
  /*margin: 5px;*/
  padding: 0 30px;
  outline: none;
  border: 2px solid var(--tc-blue);
  /*transition: opacity 200ms ease-in-out;*/
  cursor: pointer;
}
.introText {
  display: block;
  padding: 0;
  margin: 0;
  line-height: 140%;
  white-space: nowrap;
}
</style>
