<template>
  <div id="start" class="contentHoriz">
    <img ref="primary_logo" class="primaryLogo start" alt="Truecast" src="@/assets/truecast_logo_full_white.svg">
    <transition name="fade">
      <div v-if="shortCodeDisplay">
        <h1 class="shortCodeStart">{{ shortCode }}</h1>
        <div v-if="$parent.mobile">
          <h5 class="introText">
            Please provide this code to your call host
            <!--Hosting a session? <span class="loginText" @click="$parent.logIn">Touch here to log in</span>.-->
          </h5>
        </div>
        <div v-else>
          <h4 class="introText">
            Please provide this code to your call host
            <!--Hosting a session? <span class="loginText" @click="$parent.logIn">Click here to log in</span>.-->
          </h4>
        </div>
      </div>
    </transition>


  </div>
</template>

<script>
import { __db, __au, __sc } from '@/scripts/firebase';
import { getAuth, signInWithCustomToken, onAuthStateChanged } from 'firebase/auth';
import { ref,set,update,onDisconnect,onChildAdded } from 'firebase/database';

const auth = getAuth();
export default {
  name: 'start',
  props: {},
  data() {
    return {
      shortCodeDisplay: null,
      shortCode: null,
      scPath: null,
    }
  },
  components: {},
  methods: {
    async initUser() {
      update(this.scPath, { status: 'connected', ts: Math.floor(Date.now() / 1000) });

      onDisconnect(this.scPath).remove();

      this.unsubscribeChildAdded = onChildAdded(this.scPath, (data) => {
        //console.log('onChildAdded', data.key, data.val());
        if (data.key == 'usid' && data.val().length == 48) {
          clearTimeout(this.resetTimer);
          set(this.scPath, null).then(() => {
            this.$parent.usid = data.val();
            this.$parent.currentScreen = 'UserSpace'; //'NickName';
            console.log('start line 59');
            window.history.pushState('', 'Truecast Live', `${document.location.origin}${document.location.pathname}#/${data.val()}`);
          }).catch((error) => {
            console.log(error);
          });
        }
      });
    },
    async initCustomToken(data) {
      signInWithCustomToken(__au, data.fbt).then(() => {
        console.log('signInWithCustomToken > authorized')
        this.initUser();
      }).catch((error) => {
        console.log(error);
      });
    },
    async init() {
      fetch(`${this.$parent.__pt}csc`).then(response => response.json()).then(data => {
        //console.log(data);
        this.shortCode = data.sc;
        this.$parent.shortCode = this.shortCode;
        const shortCodeTimer = setTimeout(() => {
          clearTimeout(shortCodeTimer);
          this.shortCodeDisplay = this.shortCode;
        }, 1000);

        this.scPath = ref(__db, `${__sc}/${this.shortCode}`);
        __au.tenentId = 'truecast_shortcode';

        onAuthStateChanged(auth, (user) => {
          if (user) {
            this.initUser();
          } else {
            console.log('nope');
            this.initCustomToken(data);
          }
        });
      });
    },
    resetMe() {
      this.resetTimer = setTimeout(async () => {
        if(this.$parent.currentScreen == 'Start') {
          location.reload();
          this.resetMe();
        }
      }, 180000);

    }
  },
  mounted() {
    this.init();
    this.resetMe();
  },
  beforeMount() {
  },
  beforeCreate() {
  },
  created() {
  },
  beforeDestroy() {
    clearTimeout(this.resetTimer);
    //set(this.scPath, null );
    //this.$parent.logOut();
    this.$parent.shortCode = null;
    this.$parent.scPath = null;
    /*
    this.unsubscribeDisconnect();
    this.unsubscribeValue();
    this.unsubscribeChildAdded();
    this.unsubscribeChildChanged();
    this.unsubscribeChildRemoved();
    */
  },
  destroyed() {
    if (this.unsubscribeValue) this.unsubscribeValue();
    if (this.unsubscribeChildAdded) this.unsubscribeChildAdded();
    if (this.unsubscribeChildChanged) this.unsubscribeChildChanged();
    if (this.unsubscribeChildRemoved) this.unsubscribeChildRemoved();
  },
  directives: {},
  computed: {},
  watch: {},
}
</script>

<style>
#start {
  position: absolute;
  top: 25%;
  width: 100%;
  max-width: 400px;
  min-width: 280px;
  height: 100%;
  max-height: 320px;
  min-height: 280px;
}

.primaryLogo.start {
  display: block;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  height: auto !important;
  width: 180px !important;
  opacity: .55;
}

.shortCodeStart {
  display: block;
  top: 50%;
  width: 100%;
  color: var(--tc-gold);
  font-family: 'lightType';
  font-size: 75px;
  letter-spacing: 5px;
  text-align: center;
  line-height: 90px;
  padding: 0;
  margin: 50px 0 0 0;
}
.introText {
  display: block;
  padding: 0;
  margin: 0;
  line-height: 180%;
  color: rgb(255,255,255);
  white-space: nowrap;
  font-family: 'mediumType';
  font-size: 16px;
}
.mobile .introText {
  font-family: 'mediumType';
}
.mobile h5.introText {
  font-size: 14px;
}


.loginText {
  display:inline-block;
  cursor: pointer;
  color: rgb(255,255,255) !important;
  text-decoration: underline;
}


</style>
